import {RootState} from 'store'
import i18n from 'i18n'
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {
  deleteSalesAppNoticeApi,
  getSalesAppNoticeListApi,
  getUserNoticeListApi,
  postSalesAppNoticeApi,
  SaleAppNotice,
  SaleAppNoticeCreateRequest,
  updateSalesAppNoticeApi,
} from 'api/salesNoticeApi'
import {
  openFailureModalAction,
  openSuccessModalAction,
} from 'features/modal/alertSlice'

export const fetchSaleAppNotice = createAsyncThunk(
  'saleAppNoticeSlice/list',
  async (payload: PagingRequest, {rejectWithValue}) => {
    try {
      const response = await getSalesAppNoticeListApi(payload)
      return response.data
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const fetchUserNotice = createAsyncThunk(
  'saleAppNoticeSlice/user-list',
  async (payload: PagingRequest, {rejectWithValue}) => {
    try {
      const response = await getUserNoticeListApi(payload)
      return response.data
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const createSaleAppNotice = createAsyncThunk(
  'saleAppNoticeSlice/create',
  async (payload: SaleAppNoticeCreateRequest, {dispatch, rejectWithValue}) => {
    try {
      const response = await postSalesAppNoticeApi(payload)
      await dispatch(openSuccessModalAction(i18n.t('ISuccess')))
      return response
    } catch (err) {
      dispatch(openFailureModalAction(err.message))
      return rejectWithValue(err.message)
    }
  },
)

export const updateSaleAppNotice = createAsyncThunk(
  'saleAppNoticeSlice/update',
  async (
    payload: {data: SaleAppNoticeCreateRequest; noticeId: number},
    {dispatch, rejectWithValue},
  ) => {
    try {
      const response = await updateSalesAppNoticeApi(payload)
      await dispatch(openSuccessModalAction(i18n.t('ISuccess')))
      return response
    } catch (err) {
      dispatch(openFailureModalAction(err.message))
      return rejectWithValue(err.message)
    }
  },
)

export const deleteSaleAppNotice = createAsyncThunk(
  'saleAppNoticeSlice/delete',
  async (payload: number, {dispatch, rejectWithValue}) => {
    try {
      await deleteSalesAppNoticeApi(payload)
      await dispatch(openSuccessModalAction(i18n.t('ISuccess')))
      return await dispatch(
        fetchSaleAppNotice({
          paging: {page: 0, size: 10},
        }),
      ).unwrap()
    } catch (err) {
      dispatch(openFailureModalAction(err.message))
      return rejectWithValue(err.message)
    }
  },
)

export interface SaleAppNoticeState {
  loading: boolean
  pageIndex: number
  totalPages: number
  totalElements: number
  itemPerPage: number
  listNotice: SaleAppNotice[]
  error: any
}

export const initialState: SaleAppNoticeState = {
  loading: false,
  listNotice: [],
  error: null,
  pageIndex: 0,
  totalPages: 1,
  totalElements: 0,
  itemPerPage: 10,
}

export const patientSearchSlice = createSlice({
  name: 'saleAppNoticeSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSaleAppNotice.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(fetchSaleAppNotice.fulfilled, (state, action) => {
      const {
        totalPages,
        totalElements,
        pageable: {pageNumber},
        content: listNotice,
        size,
      } = action.payload
      state.loading = false
      state.error = null
      state.listNotice = listNotice
      state.totalPages = totalPages
      state.totalElements = totalElements
      state.pageIndex = pageNumber
      state.itemPerPage = size
    })
    builder.addCase(fetchSaleAppNotice.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
    builder.addCase(fetchUserNotice.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(fetchUserNotice.fulfilled, (state, action) => {
      const {
        totalPages,
        totalElements,
        pageable: {pageNumber},
        content: listNotice,
        size,
      } = action.payload
      state.loading = false
      state.error = null
      state.listNotice = listNotice
      state.totalPages = totalPages
      state.totalElements = totalElements
      state.pageIndex = pageNumber
      state.itemPerPage = size
    })
    builder.addCase(fetchUserNotice.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
  },
})

export const selectError = (state: RootState) => state.saleAppNotices.error
export const selectList = (state: RootState) => state.saleAppNotices.listNotice
export const selectLoading = (state: RootState) => state.saleAppNotices.loading
export const selectQuery = (state: RootState): PagingRequest => ({
  paging: {
    page: state.saleAppNotices.pageIndex,
    size: state.saleAppNotices.itemPerPage,
  },
})
export const selectPaging = (state: RootState) => ({
  page: state.saleAppNotices.pageIndex,
  size: state.saleAppNotices.itemPerPage,
})
export const selectPagingInfo = (state: RootState) => ({
  totalPages: state.saleAppNotices.totalPages,
  totalElements: state.saleAppNotices.totalElements,
})

export default patientSearchSlice.reducer
