import {makeStyles} from '@material-ui/core'
import {createStyles, Theme} from '@material-ui/core/styles'

export const styles = (theme: Theme) =>
  createStyles({
    navContainer: {
      marginBottom: 15,
      display: 'flex',
      justifyContent: 'center',
      gap: 5,
      flexWrap: 'wrap',
    },
    tableWrap: {
      backgroundColor: '#fff',
      borderRadius: 5,
      marginTop: 10,
      overflow: 'Hidden',
    },
    textField: {
      flexGrow: 1,
    },
    padding: {
      padding: theme.spacing(1),
    },
    smallPadding: {
      padding: theme.spacing(0.5),
    },
    dateContainer: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      flex: 1,
      '& h6': {
        margin: '0 10px',
      },
    },
    hover: {
      '&:hover': {
        backgroundColor: '#F8F8F8 !important',
      },
    },
    clientTitle: {
      fontWeight: 600,
      color: '#35495D',
      marginTop: 3,
    },

    pointRoot: {
      marginBottom: 30,
    },
    pointTitle: {
      width: '100%',
      fontSize: '12px',
      display: 'inline-flex',
    },
    pointCont: {
      width: '100%',
      display: 'inline-flex',
      fontWeight: 600,
      color: '#3EBBBD',
    },
    pointContainer: {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: 30,
      alignItems: 'center',
    },
    pointWrap: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    topContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginBottom: 14,
      marginTop: 10,
      gap: 7,
    },
    tabs: {
      display: 'flex',
      alignItems: 'center',
      fontFamily: 'Noto Sans KR',
      border: '1px solid #E0E0E0',
    },
    tab: {
      height: 38,
      fontWeight: 400,
      fontSize: 16,
      color: '#333333',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0 18px',
      cursor: 'pointer',
    },
    tabActive: {
      color: '#fff',
      background: '#00A29C',
      fontWeight: 700,
    },
    radioContainer: {
      flex: 1,
    },
    gridContainer: {
      display: 'flex',
      gap: 30,
    },
    titleItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      fontWeight: 700,
    },
    contentsTab: {
      display: 'flex',
      flexDirection: 'column',
    },
    marginTop10: {
      marginTop: 10,
    },
    inputTime: {
      width: 63,
      height: 42,
      border: '1px solid #e9ecef',
      backgroundColor: 'white',
      margin: '0 5px',
      paddingLeft: 5,
      paddingRight: 5,
    },
    botContainer: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: 30,
      marginBottom: 100,
      gap: 30,
    },
    errorText: {
      fontSize: 11,
      marginTop: 2,
      color: '#d03c35',
    },
    fileName: {
      position: 'relative',
      padding: ' 8px 20px',
      borderRadius: 5,
      fontSize: 12,
    },
    iconDelete: {
      position: 'absolute',
      right: 4,
      top: 2,
      width: 14,
      height: 14,
      color: '#fff',
      cursor: 'pointer',
      padding: 2,
      borderRadius: '50%',
      background: 'gray',
    },
    genalNoticeTab: {
      marginTop: 40,
    },
    pickDateContainer: {
      display: 'flex',
      gap: 30,
      margin: 0,
    },
    pickDate: {display: 'flex', alignItems: 'center', gap: 10},
    labelCheckbox: {
      color: '#5a6268',
      fontSize: '17px',
      paddingBottom: 3,
    },
    marginRight30: {
      marginRight: 30,
    },
    minWidth120: {minWidth: 120},
    buttonContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      marginTop: 43,
    },
    buttonWrap: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      flexWrap: 'wrap',
    },
    backButton: {
      width: 148,
    },
  })

const useStyles = makeStyles(styles)

export default useStyles
