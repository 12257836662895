import Checkbox from '@material-ui/core/Checkbox'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import ActionButton from 'components/atoms/Buttons/ActionButton'
import TableCell from 'components/Table/TableCell'
import useStyles from 'components/Table/useTableStyles'
import useTableToolbarStyles from 'components/Table/useTableToolbarStyles'
import {openDepartmentAddDialog} from 'features/modal/modalSlice'
import useConfirm from 'features/modal/useConfirm'
import useFailureModal from 'features/modal/useFailureModal'
import useSuccessModal from 'features/modal/useSuccessModal'
import useOrgDepartment from 'features/org/useOrgDepartment'
import {useAppDispatch} from 'hooks'
import React from 'react'
import {useTranslation} from 'react-i18next'

interface EnhancedTableToolbarProps {
  onDeleteClick: () => void
}

function EnhancedTableToolbar({onDeleteClick}: EnhancedTableToolbarProps) {
  const {t} = useTranslation()
  const classes = useTableToolbarStyles()
  const dispatch = useAppDispatch()

  const handleAddClick = () => dispatch(openDepartmentAddDialog())

  return (
    <div className={classes.root}>
      <div className={classes.actionContainer}>
        <ActionButton startIcon={<AddIcon />} onClick={handleAddClick}>
          {t('IAdd')}
        </ActionButton>
        <ActionButton startIcon={<DeleteIcon />} onClick={onDeleteClick}>
          {t('IDelete')}
        </ActionButton>
      </div>
    </div>
  )
}

interface EnhancedTableHeadProps {
  classes: ReturnType<typeof useStyles>
  numSelected: number
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
  rowCount: number
}

function EnhancedTableHead({
  classes,
  numSelected,
  onSelectAllClick,
  rowCount,
}: EnhancedTableHeadProps) {
  const {t} = useTranslation()
  return (
    <TableHead>
      <TableRow className={classes.tableHeader}>
        <TableCell padding='checkbox'>
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{'aria-label': 'select all desserts'}}
          />
        </TableCell>
        <TableCell align='center' padding='none'>
          No.
        </TableCell>
        <TableCell align='center' padding='none'>
          {t('IDepartmentName')}
        </TableCell>
      </TableRow>
    </TableHead>
  )
}

interface RowItemProps {
  isSelected: (id: number) => boolean
  index: number
  data: Department
  onRowClick: (id: number) => void
}

function RowItem({isSelected, index, data, onRowClick}: RowItemProps) {
  const isItemSelected = isSelected(data.id)
  const labelId = `enhanced-table-checkbox-${index}`
  const handleRowClick = () => onRowClick(data.id)

  return (
    <TableRow
      hover
      onClick={handleRowClick}
      role='checkbox'
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={data.id}
      selected={isItemSelected}
    >
      <TableCell padding='checkbox'>
        <Checkbox
          checked={isItemSelected}
          inputProps={{'aria-labelledby': labelId}}
        />
      </TableCell>
      <TableCell align='center'>{data.id}</TableCell>
      <TableCell align='center'>{data.name}</TableCell>
    </TableRow>
  )
}

function DepartmentTable() {
  const classes = useStyles()

  const {t} = useTranslation()

  const {departments, onRemove} = useOrgDepartment()
  const {onOpen: onFailureModalOpen} = useFailureModal()
  const {onOpen: onSuccessAlertOpen} = useSuccessModal()
  const {onOpen: onConfirmOpen} = useConfirm()

  const [selected, setSelected] = React.useState<number[]>([])

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = departments.map((item) => item.id)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleClick = (id: number) => {
    const selectedIndex = selected.indexOf(id)
    let newSelected: number[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      )
    }
    setSelected(newSelected)
  }

  const isSelected = (id: number) => selected.indexOf(id) !== -1

  const handleDelete = async () => {
    if (selected.length > 0) {
      const result = await onConfirmOpen({
        message: t('IDepartmentDeleteConfirm'),
      })

      if (!result.payload) return

      try {
        const result = await onRemove(selected)
        const removeResult = result.payload as PromiseRejectedResult[]

        if (removeResult.length > 0) {
          onFailureModalOpen(removeResult[0].reason.message)
        }
        if (removeResult.length === 0) {
          onSuccessAlertOpen(t('IProcessSuccess'))
        }
        setSelected([])
      } catch (err) {
        onFailureModalOpen(err.message)
      }
    }
    if (selected.length === 0) onFailureModalOpen(t('ISelectRequired'))
  }

  return (
    <TableContainer className={classes.container}>
      <EnhancedTableToolbar onDeleteClick={handleDelete} />
      <Table className={`${classes.table} ${classes.tableMini}`}>
        <EnhancedTableHead
          classes={classes}
          numSelected={selected.length}
          onSelectAllClick={handleSelectAllClick}
          rowCount={departments.length}
        />
        <TableBody>
          {departments.map((item, index) => (
            <RowItem
              data={item}
              index={index}
              isSelected={isSelected}
              onRowClick={handleClick}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default DepartmentTable
