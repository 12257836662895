import request from 'api/index'

export interface EegVersionRequest {
  type: string
}

// eslint-disable-next-line import/prefer-default-export
export const fetchOrgDepartmentApi = () =>
  request<ModelsResponse<Department>>({
    method: 'get',
    path: '/api/org/v1/department/list',
  })

export const fetchOrgEegVersionApi = (query: EegVersionRequest) =>
  request<ModelsResponse<DataVersion>>({
    method: 'get',
    path: '/api/org/user/v1/data/version',
    params: query,
  })

export const fetchInterestAreaApi = () =>
  request<ModelsResponse<string>>({
    method: 'get',
    path: '/api/org/v1/interest-area/list',
  })

export const fetchJoinRouteApi = () =>
  request<ModelsResponse<string>>({
    method: 'get',
    path: '/api/org/v1/join-route/list',
  })

interface SalesName {
  firstName: string
  lastName: string
  name: string
}
export const fetchSalesNameListApi = () =>
  request<ModelsResponse<SalesName>>({
    method: 'get',
    path: '/api/org/v1/sales/list',
  })

export interface OrgDetailInfoEditRequest {
  uid?: string | null
  orgName?: string | null
  firstUserName?: string | null
  lastUserName?: string | null
  fullUserName?: string | null
  password?: string | null
  countryCode?: string | null
  tel?: string | null
  industry?: Industry | null
  area?: string | null
  detailIndustry?: Industry | null
  address?: string | null
  businessNo?: string | null
  institutionNo?: string | null
  doctorCount?: string | null
  joinRoute?: string | null
  interestArea?: string | null
  property?: string[] | null
  authorityGroup?: string | null
  startDate?: string | null
  endDate?: string | null
}

export const updateOrgDetailInfoApi = (data: OrgDetailInfoEditRequest) =>
  request<CommonResponse>({
    method: 'post',
    path: '/api/org/v2/update',
    data,
  })
