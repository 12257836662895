/* eslint-disable import/prefer-default-export */
export const TAB = [
  {index: 1, name: 'IMaintenanceNotice'},
  {index: 2, name: 'IGeneralNotice'},
]

export const TYPE_NOTICE_OPTIONS = [
  {label: 'INoInterruption', value: 'zd'},
  {label: 'IInterruption', value: 'd'},
]

export const TYPE_STATUS_OPTIONS = [
  {label: 'IWait', value: 'wait'},
  {label: 'IProceed', value: 'proceed'},
  {label: 'IDone', value: 'end'},
]
