import CardWithSubTitle from 'components/atoms/CardWithSubTitle'
import CardWithTitle from 'components/atoms/CardWithTitle'
import MainRangeDatePicker from 'components/Calender/MainCalender'
import {format} from 'date-fns'
import useAuth from 'features/auth/useAuth'
import usePatient from 'features/patient/usePatient'
import {searchTreatmentMonthlyAllAction} from 'features/treatment/treatmentSearchSlice'
import useTreatmentSearch from 'features/treatment/useTreatmentSearch'
import {getQueryFromUrl, isDefined, stringFormat} from 'helpers/commonHelper'
import {
  dateFormatDash,
  dateFormatDay,
  dateToNoDashString,
  dateToPeriodString,
  getDefaultPeriodDate,
} from 'helpers/dateHelper'
import {useAppDispatch} from 'hooks'
import CommonLayout from 'layouts/Common'
import PatientActivityTable from 'pages/Home/PatientActivityTable'
import {PatientCard} from 'pages/Home/PatientCard'
import TreatmentTableMini from 'pages/Home/TreatmentTableMini'
import TherapyRecentReadDialog from 'components/V2Dialog/TherapyDialog/RecentRead'
import React, {useEffect, useState} from 'react'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css' // css import
import {useTranslation} from 'react-i18next'
import {useLocation} from 'react-router-dom'
import styled from 'styled-components'

const StyledHome = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;

  & .Top {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    @media only screen and (max-width: 1280px) {
      flex-direction: column;
    }
  }

  & .Flex1 {
    flex: 1;
    @media only screen and (max-width: 1280px) {
      flex: unset;
      width: 100%;
    }
  }

  & .calender-container {
    @media only screen and (max-width: 1280px) {
      width: 100%;
    }
  }

  & .Padding1 {
    padding: 8px;
  }

  & .RowButton {
    background-color: white;
    border-radius: 0;
    color: #868e96;
  }
  & .react-calendar {
    border: 0;
    @media only screen and (max-width: 1280px) {
      display: none;
    }
  }

  & .react-calendar__tile:disabled {
    background-color: white;
    color: #e9ecef;
  }
  & .react-calendar__month-view__days {
    // pointer-events: none;
  }
  & .react-calendar button {
    position: relative;
  }
  & .react-calendar__tile--now {
    background-color: #3ebbbd;
    border-radius: 7px;
    color: #e9ecef;
  }
  & .react-calendar__tile--active {
    background-color: #3ebbbd;
    border-radius: 7px;
    color: inherit;
  }
  & .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background-color: white;
    border-radius: 7px;
  }
  & .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background-color: white;
  }
  & .react-calendar__navigation button:disabled {
    background-color: white;
  }
  & .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: white;
  }
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    border-radius: 7px;
  }
  .dot {
    position: absolute;
    height: 5px;
    width: 5px;
    background-color: #0cc7c7;
    border-radius: 50%;
    display: flex;
    margin-top: 10px;
    bottom: 0;
    left: 23px;
  }
  .round {
    position: absolute;
    background-color: #5a6268;
    border-radius: 50%;
    display: flex;
    margin-left: 1px;
    width: 25px;
    height: 25px;
    top: 6px;
    left: 12px;
    opacity: 0.1;
  }
  abbr[title] {
    text-decoration: none;
  }
`

const Home = () => {
  const {t, i18n} = useTranslation()
  const dispatch = useAppDispatch()
  const {query, onSearchNew} = useTreatmentSearch()
  const {entity: patient, onFetch} = usePatient()
  const {user: currentUser} = useAuth()
  const [activeDate, setActiveDate] = useState<Date>(new Date())
  const [activeDateString, setActiveDateString] = useState<string>()
  const [month, setMonth] = useState<number>()
  const [year, setYear] = useState<number>()
  const [open, setOpen] = useState(false)
  const [date, setDate] = useState<Date>(new Date())
  const [selection, setSelection] = React.useState<PeriodDate>(
    getDefaultPeriodDate(),
  )
  const location = useLocation()

  const [period, setPeriod] = React.useState<PeriodString>(
    dateToPeriodString(selection),
  )

  const [dataPatient, setDataPatient] = React.useState<Patient | undefined>()

  const [mark, setMark] = useState<string[]>(['', '', ''])
  const [selectDate, setselectDate] = useState<string[]>()

  const handleDateChanged = (periodDate: PeriodDate) => {
    setDate(date)
    setSelection(periodDate)
    onSearchNew({
      pageNumber: 0,
      pageSize: 5,
      type: 'ALL',
      startDate: dateToPeriodString(periodDate).startDate,
      endDate: dateToPeriodString(periodDate).endDate,
    })
  }

  function getDatesStartToEnd({startDate, endDate}: PeriodDate): string[] {
    const start = format(startDate, dateFormatDash)
    const end = format(endDate, dateFormatDash)
    const regex = RegExp(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/)
    if (!(regex.test(start) && regex.test(end))) return []
    const result = []
    const curDate = new Date(start)
    while (curDate <= new Date(end)) {
      result.push(curDate.toISOString().split('T')[0])
      curDate.setDate(curDate.getDate() + 1)
    }
    return result
  }

  const handleRowClick = (uuid: string) => {
    onFetch(uuid)
  }
  const handleMonthlyAll = async (year: number, month: number) => {
    if (currentUser && month && year) {
      try {
        const response = await dispatch(
          searchTreatmentMonthlyAllAction({
            uid: currentUser.uid,
            searchMonth: month,
            searchYear: year,
          }),
        )
        setMark(response.payload as string[])
      } catch (error) {
        console.log(error)
      }
    }
  }

  useEffect(() => {
    const searchParams = getQueryFromUrl(location.search)
    onSearchNew({
      pageNumber: query.paging.page,
      pageSize: 5,
      type: 'ALL',
      startDate: period.startDate,
      endDate: period.endDate,
      ...searchParams,
    })
  }, [])

  useEffect(() => {
    setselectDate(getDatesStartToEnd(selection))
    setPeriod(dateToPeriodString(selection))
  }, [selection])

  useEffect(() => {
    setActiveDateString(dateToNoDashString(activeDate))
    setYear(Number(activeDateString?.substring(0, 4)))
    setMonth(Number(activeDateString?.substring(4, 6)))
  }, [activeDate, activeDateString])

  useEffect(() => {
    if (year && month) {
      handleMonthlyAll(year, month)
    }
  }, [year, month])

  // useEffect(() => {
  //   onSearch({
  //     ...query,
  //     paging: {
  //       page: query.paging.page,
  //       size: 5,
  //     },
  //     period: dateToPeriodString(prodSelection),
  //   })
  // }, [prodSelection])

  useEffect(() => {
    if (patient && patient.uuid) {
      setDataPatient(patient)
    }
  }, [patient])

  return (
    <CommonLayout>
      <TherapyRecentReadDialog />
      <StyledHome>
        <div className='Top' style={{position: 'relative'}}>
          <CardWithSubTitle
            subTitle={t('IDateSearch')}
            data-testid='test'
            className='calender-container'
          >
            <MainRangeDatePicker
              onOpen={open}
              onChangeValue={handleDateChanged}
              dateValue={selection}
              dateOpen={setOpen}
            />

            <Calendar
              locale={i18n.language === 'ko' ? 'ko' : 'en'}
              onChange={() => {
                setOpen(true)
              }}
              className='selectDate dateview'
              value={date}
              maxDate={new Date()}
              showWeekNumbers={false}
              formatDay={(locale, date) => format(date, dateFormatDay)}
              onActiveStartDateChange={({activeStartDate}) =>
                setActiveDate(activeStartDate)
              }
              tileContent={({date}) => {
                // if (isProd()) {
                //   return (
                //     <>
                //       {selectDate &&
                //         selectDate.find(
                //           (x) => x === format(date, dateFormatDash),
                //         ) && <div className='round' />}
                //     </>
                //   )
                // }
                return (
                  <>
                    {mark &&
                      mark.find((x) => x === format(date, dateFormatDash)) && (
                        <div className='dot' />
                      )}
                    {selectDate &&
                      selectDate.find(
                        (x) => x === format(date, dateFormatDash),
                      ) && <div className='round' />}
                  </>
                )
              }}
            />
          </CardWithSubTitle>
          <CardWithSubTitle
            subTitle={t('ITreatmentManagement')}
            className='Flex1'
          >
            <TreatmentTableMini onItemClick={handleRowClick} />
          </CardWithSubTitle>
        </div>
        {isDefined(dataPatient) &&
          (i18n.language === 'ko' || i18n.language === 'ja') && (
            <CardWithTitle
              title={stringFormat(
                t('IActivityTitle1', {
                  name:
                    !dataPatient?.lastName && !dataPatient?.firstName
                      ? 'No Name'
                      : `${dataPatient.lastName}${dataPatient.firstName}`,
                }),
              )}
            >
              <PatientCard {...dataPatient} />
            </CardWithTitle>
          )}
        {isDefined(dataPatient) &&
          i18n.language !== 'ko' &&
          i18n.language !== 'ja' && (
            <CardWithTitle
              title={stringFormat(
                t('IActivityTitle1', {
                  name:
                    !dataPatient?.lastName && !dataPatient?.firstName
                      ? 'No Name'
                      : `${dataPatient.firstName} ${dataPatient.lastName}`,
                }),
              )}
            >
              <PatientCard {...dataPatient} />
            </CardWithTitle>
          )}
        {isDefined(dataPatient) && (
          <CardWithTitle title={stringFormat(t('IHistory'))}>
            <PatientActivityTable patient={dataPatient} />
          </CardWithTitle>
        )}
      </StyledHome>
    </CommonLayout>
  )
}

export default Home
