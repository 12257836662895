import React, {MouseEventHandler, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useLocation} from 'react-router-dom'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import {finalizeEegMciApi, SearchedIndividualMCI} from 'api/analysisApi'
import {AnalysisReportType} from 'constants/AnalysisConstant'
import TableButton from 'components/atoms/Buttons/TableButton'
import {ClientName} from 'components/atoms/ClientName'
import Pagination from 'components/molcules/Pagination'
import TableCell from 'components/Table/TableCell'
import TableCellReport from 'components/Table/TableCellReport'
import TableReportSearch from 'components/Table/TableReportSearch'
import useToolbarStyles from 'components/Table/useTableToolbarStyles'
import useStyles from 'components/Table/useTableStyles'
import RouteConstant from 'constants/RouteConstant'
import useEegMciSearch from 'features/analysis/useEegMciSearch'
import useFailureModal from 'features/modal/useFailureModal'
import {getEyeStatus, statusToI18nString} from 'helpers/analysisHelper'
import {getQueryFromUrl, isDefined} from 'helpers/commonHelper'
import {
  dateToPeriodString,
  getDefaultPeriodDate,
  isoStringToDateAndTimeString,
} from 'helpers/dateHelper'
import openInNewTab from 'helpers/openInNewTab'

function TableToolbar() {
  const location = useLocation()
  const classes = useToolbarStyles()
  const {query, onSearch} = useEegMciSearch()
  const selection = getDefaultPeriodDate()

  // Init search value  from url if exist
  useEffect(() => {
    const initValue = getQueryFromUrl(location.search)
    onSearch({
      ...query,
      period: dateToPeriodString(selection),
      paging: {page: 0, size: 10},
      ...initValue,
    })
  }, [])

  return (
    <div className={classes.root}>
      <TableReportSearch
        reportType={AnalysisReportType.EEGMCI}
        isRoot={false}
        query={query}
        onSearch={onSearch}
      />
    </div>
  )
}

interface RenderTableRowProps {
  item: SearchedIndividualMCI
}

function RenderTableRow({item}: RenderTableRowProps) {
  const {t} = useTranslation()

  const {onOpen: handleFailureModalOpen} = useFailureModal()
  const {query, onSearch} = useEegMciSearch()

  const handleResultClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation()
    openInNewTab(`${RouteConstant.ANALYSIS_EEG_19_M.path}/${item.id}`)
  }

  const handleMainReportClick = async () => {
    const {id} = item
    try {
      await finalizeEegMciApi(id)
      onSearch({...query})
    } catch (err) {
      handleFailureModalOpen(err.message)
    }
  }

  return (
    <TableRow>
      <TableCell align='center'>{item.id}</TableCell>
      <TableCell align='center'>{item.individualId}</TableCell>
      <TableCell align='center'>{item.reAnalysisId}</TableCell>
      <TableCellReport
        align='center'
        reportId={item.id}
        isMainReport={
          isDefined(item.finalAnalysisYn) && item.finalAnalysisYn === 'Y'
        }
        onClick={handleMainReportClick}
      >
        {item.report}
      </TableCellReport>
      <TableCell align='center'>
        <ClientName
          firstName={item.firstName}
          lastName={item.lastName}
          birth={item.birth}
          gender={item.gender}
        />
      </TableCell>
      <TableCell align='center'>{getEyeStatus(item.eceo)}</TableCell>
      <TableCell align='center'>{item.fileName}</TableCell>
      <TableCell align='center'>
        {isoStringToDateAndTimeString(String(item.measureDate))}
      </TableCell>
      <TableCell align='center'>
        {t(statusToI18nString(item.progress as AnalysisStatus))}
      </TableCell>
      <TableCell align='center'>
        <TableButton
          onClick={handleResultClick}
          disabled={item.progress !== 'SUCCESS'}
        >
          {t('IRead')}
        </TableButton>
      </TableCell>
    </TableRow>
  )
}

function PageTable() {
  const {t} = useTranslation()
  const classes = useStyles()

  const {loading, query, paging, pagingInfo, items, onSearch} =
    useEegMciSearch()

  const emptyRows = items === null ? 5 : 5 - items.length

  const setPageIndex = (page: number) =>
    onSearch({
      ...query,
      paging: {
        page,
        size: paging.size,
      },
    })

  const onSizeChange = (
    event: React.ChangeEvent<{name?: string; value: unknown}>,
  ) => {
    const size = parseInt(event.target.value as string, 10)
    onSearch({
      ...query,
      paging: {
        page: 0,
        size,
      },
    })
  }

  return (
    <TableContainer className={classes.tableContainer}>
      <TableToolbar />
      <Table className={classes.table}>
        <TableHead>
          <TableRow className={classes.tableHeader}>
            <TableCell align='center' padding='none'>
              {t('IAMciIndex')}
            </TableCell>
            <TableCell align='center' padding='none'>
              {t('IEEGIndividualIndex')}
            </TableCell>
            <TableCell align='center' padding='none'>
              {t('IReAnalysisNumber_no')}
            </TableCell>
            <TableCell align='center' padding='none'>
              {t('IMainReport')}
            </TableCell>
            <TableCell align='center' padding='none'>
              {t('IPatientName')}
            </TableCell>
            <TableCell align='center' padding='none'>
              {t('IECEO')}
            </TableCell>
            <TableCell align='center' padding='none'>
              {t('IFileName')}
            </TableCell>
            <TableCell align='center' padding='none'>
              {t('IMeasureDate')}
            </TableCell>
            <TableCell align='center' padding='none'>
              {t('IProgress')}
            </TableCell>
            {/* <TableCell align='center' padding='none'> */}
            {/*  {t('IVersion')} */}
            {/* </TableCell> */}
            <TableCell align='center' padding='none'>
              {t('IAnalysisReport')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item) => (
            <RenderTableRow key={item.id} item={item} />
          ))}
          {emptyRows > 0 && (
            <TableRow
              style={{height: 43 * emptyRows, backgroundColor: '#F9F9FB'}}
            >
              <TableCell colSpan={10} />
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Pagination
        totalPageCount={pagingInfo.totalPages}
        currentPageIndex={query.paging.page ?? 0}
        itemCountPerPage={query.paging.size ?? 0}
        setCurrentPageIndex={setPageIndex}
        loading={loading}
        onItemCountPerPageChanged={onSizeChange}
      />
    </TableContainer>
  )
}

export default PageTable
