import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Grid, {GridProps} from '@material-ui/core/Grid'

const styles = {
  grid: {
    margin: '0 -15px',
    width: 'calc(100% + 30px)',
    // '&:before,&:after':{
    //   display: 'table',
    //   content: '" "',
    // },
    // '&:after':{
    //   clear: 'both',
    // }
  },
}

const useStyles = makeStyles(styles)

interface GridContainerProps extends GridProps {
  className?: string
}

export default function GridContainer(props: GridContainerProps) {
  const classes = useStyles()
  const {children, className, ...rest} = props
  return (
    <Grid container {...rest} className={`${classes.grid} ${className}`}>
      {children}
    </Grid>
  )
}
