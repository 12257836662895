import React, {useEffect} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import * as yup from 'yup'
import {yupResolver} from '@hookform/resolvers/yup'
import Button from '@material-ui/core/Button'
import CardContent from '@material-ui/core/CardContent'
import Dialog from '@material-ui/core/Dialog'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import {QnaCreateRequest} from 'api/qnaApi'
import Card from 'components/atoms/Card'
import {useBoldInputStyle} from 'components/common/useBoldInputStyle'
import useStyles from 'components/Dialog/User/Style'
import {
  closeQnaCreateDialog,
  selectQnaCreateDialogOpen,
} from 'features/modal/modalSlice'
import {createQnaAction, fetchQnaListAction} from 'features/qna/qnaSlice'
import {useAppDispatch} from 'hooks'
import SelectQnaType from 'pages/CommunityPages/QuestionPage/QuestionEditDialog/SelectQnaType'

export default function QuestionCreateDialog() {
  const {t} = useTranslation()
  const classes = useStyles()
  const dispatch = useAppDispatch()
  const qnaCreate = useSelector(selectQnaCreateDialogOpen)
  const inputClasses = useBoldInputStyle()

  const AddSchema = yup.object().shape({
    title: yup
      .string()
      .required(t('INameRequired'))
      .max(50, t('IValidationQnaTitle')),
    content: yup
      .string()
      .required(t('INameRequired'))
      .min(1)
      .max(600, t('IValidationQnaContent')),
    qnaType: yup.string().required(t('INameRequired')),
  })

  const {
    handleSubmit,
    formState: {errors},
    control,
    reset,
  } = useForm<QnaCreateRequest>({
    // @ts-ignore
    resolver: yupResolver(AddSchema),
    shouldFocusError: true,
  })
  const handleClose = () => {
    dispatch(closeQnaCreateDialog())
    reset()
  }
  const onSubmit = handleSubmit(async (data: QnaCreateRequest) => {
    // submit object에서 undefined인 값 삭제
    const submitOrgInfo = Object.fromEntries(
      Object.entries(data).filter(([v]) => v != null),
    )
    await dispatch(createQnaAction(submitOrgInfo))
    await dispatch(
      fetchQnaListAction({
        paging: {
          page: 0,
          size: 10,
        },
      }),
    )
    reset()
    handleClose()
  })

  useEffect(() => {
    reset()
  }, [])

  return (
    <div>
      <Dialog open={qnaCreate} maxWidth='md' onClose={handleClose}>
        {/* <form onSubmit={handleSubmit(onSubmit)}> */}
        <Card className={classes.cardWrap}>
          <div className={classes.root}>
            <div className={classes.longTitleContainer}>
              <div className={classes.containerTitle}>
                <div className={classes.closeButtonWrap}>
                  <IconButton
                    color='secondary'
                    aria-label='favorite'
                    className={classes.closeButton}
                    onClick={handleClose}
                  >
                    <CloseIcon className={classes.closeIcon} />
                  </IconButton>
                </div>
                <Typography variant='h6'>{t('IInquireCreate')}</Typography>
              </div>
            </div>
            <Divider className={classes.titleDivider} />
            <Typography className={classes.padding} />
            <Grid item xs={12} className={classes.scroll}>
              <CardContent className={classes.scroll}>
                <div className={classes.contentContainer}>
                  <div>
                    <div className={classes.inputContainer}>
                      <div className={inputClasses.labelWrapContainer}>
                        <p className={inputClasses.inputLabel}>
                          {t('IInquireType')}
                        </p>
                      </div>
                      <div style={{width: 750}}>
                        <Controller
                          name='qnaType'
                          control={control}
                          render={({field}) => (
                            <SelectQnaType
                              {...field}
                              error={!!errors.qnaType}
                            />
                          )}
                        />
                      </div>
                      {errors.qnaType !== null && (
                        <span className={classes.errorText}>
                          {t(errors.qnaType?.message ?? '')}
                        </span>
                      )}
                    </div>
                    <Grid item xs={12}>
                      <Typography className={classes.padding} />
                    </Grid>
                    <div className={classes.inputContainer}>
                      <div className={inputClasses.labelWrapContainer}>
                        <p className={inputClasses.inputLabel}>
                          {t('IInquireTitle')}
                        </p>
                      </div>
                    </div>
                    <div>
                      <Controller
                        name='title'
                        control={control}
                        rules={{required: false}}
                        render={({field}) => (
                          <input
                            id='title'
                            style={{width: '100%'}}
                            placeholder={t('ITitleRequired')}
                            className={inputClasses.input}
                            // error={!!errors.title}
                            {...field}
                          />
                        )}
                      />
                      {errors.title !== null && (
                        <span className={classes.errorText}>
                          {t(errors.title?.message ?? '')}
                        </span>
                      )}
                    </div>
                    <Grid item xs={12}>
                      <Typography className={classes.padding} />
                    </Grid>
                    <div className={inputClasses.labelWrapContainer}>
                      <p className={inputClasses.inputLabel}>
                        {t('IInquireContents')}
                      </p>
                    </div>
                    <div>
                      <Controller
                        name='content'
                        control={control}
                        rules={{required: false}}
                        render={({field}) => (
                          <textarea
                            style={{
                              width: '100%',
                              height: 250,
                              resize: 'none',
                            }}
                            rows={10}
                            placeholder={t('IInquireCreateContents')}
                            className={inputClasses.input}
                            {...field}
                          />
                        )}
                      />
                      {errors.content !== null && (
                        <span className={classes.errorText}>
                          {t(errors.content?.message ?? '')}
                        </span>
                      )}
                    </div>
                    <Grid item xs={12}>
                      <Typography className={classes.padding} />
                    </Grid>
                  </div>
                </div>
              </CardContent>
            </Grid>
            <div className={classes.buttonContainerBgColor}>
              <div className={classes.buttonContainer}>
                <Button
                  variant='contained'
                  disableElevation
                  onClick={() => {
                    dispatch(closeQnaCreateDialog())
                    reset()
                  }}
                >
                  {t('ICancel')}
                </Button>
                <Typography className={classes.padding} />
                <Button
                  variant='contained'
                  color='secondary'
                  disableElevation
                  className={classes.okButtonColor}
                  type='submit'
                  onClick={onSubmit}
                >
                  {t('IOk')}
                </Button>
              </div>
            </div>
          </div>
        </Card>
        {/* </form> */}
      </Dialog>
    </div>
  )
}
