import React, {useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import axios, {AxiosResponse} from 'axios'
import {makeStyles} from '@material-ui/core/styles'
import OldLogo from 'assets/img/isyncbrain_logo.png'
import {makeDateAndTimeFormat} from 'helpers/dateHelper'
import {Typography} from '@material-ui/core'
import {HtmlNoticesType} from 'components/molcules/SalesPages/Notification/NotificationDetail'
import {SaleAppNotice} from 'api/salesNoticeApi'
import {getS3Links} from 'api/storageApi'
import useFailureModal from 'features/modal/useFailureModal'

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#f4f4f7',
    transition: 'all 0.4s',
    padding: '0 15px',
    position: 'relative',
    margin: '100px 0',
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'auto',
    minHeight: 'calc(100vh - 200px)',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'auto',
    minWidth: 550,
    position: 'relative',
    padding: 0,
    margin: '0 auto',
    textAlign: 'center',
    zIndex: 0,

    '@media (max-width: 768px)': {
      minWidth: 'auto',
      position: 'unset',
      transform: 'none',
      margin: '80px auto',
    },
  },
  logo: {
    fontSize: 170,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    margin: 0,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    marginBottom: 20,
  },
  img: {
    width: 150,
    verticalAlign: 'middle',
    border: 0,
  },
  header: {
    marginBottom: 20,
  },
  title: {
    fontSize: 36,
    fontWeight: 'bold',
    color: '#8b8c8f',
    marginBottom: 20,
  },
  upper: {
    verticalAlign: 'top',
    fontSize: 23,
  },
  desc: {
    color: '#1d396b',
    fontSize: 36,
    lineHeight: 1.36,
    textAlign: 'center',
    fontWeight: 'bold',
  },
  hr: {
    backgroundColor: '#000',
    width: '80%',
    height: 3,
    textAlign: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 700,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    margin: 20,
    '& h2': {
      fontWeight: 'bold',
      color: '#5a6268',
      fontSize: 15,
      wordBreak: 'break-word',
    },

    '& h6': {
      fontSize: 15,
      fontWeight: 'normal',
      textAlign: 'start',
      margin: '5px 0',
      color: '#717376',
      wordBreak: 'break-word',
    },
  },
  noticeTitle: {
    textAlign: 'left',
    fontSize: 20,
    lineHeight: '1.5',
    wordWrap: 'break-word',
    whiteSpace: 'pre-wrap',
    marginBottom: 20,
    fontWeight: 700,
  },
  notice: {
    textAlign: 'left',
    fontSize: 20,
    lineHeight: '1.5',
    wordWrap: 'break-word',
    whiteSpace: 'pre-wrap',
    marginBottom: 20,
  },
  noticeContentElement: {
    display: 'flex',
    gap: 10,
    paddingBottom: 20,
  },
  noticeContentTitle: {
    fontWeight: 'bold',
    textAlign: 'right',
    margin: '5px 0',
  },
  noticeContentNotice: {
    whiteSpace: 'pre-line',
    lineHeight: '1.2',
  },
  link: {
    color: '#1890ff',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}))

export const HtmlNoticesElement = ({
  label,
  type,
  value,
  link,
}: HtmlNoticesType) => {
  const {t, i18n} = useTranslation()
  const classes = useStyles()

  if (!value || !value.length) {
    return null
  }

  if (!type)
    return (
      <div className={classes.noticeContentElement}>
        <Typography
          variant='h2'
          className={classes.noticeContentTitle}
          style={{minWidth: i18n.language === 'ko' ? 65 : 125}}
        >
          {t(label)} :
        </Typography>
        <Typography variant='h6' className={classes.noticeContentNotice}>
          {value}
        </Typography>
      </div>
    )

  if (type === 'link')
    return (
      <div className={classes.noticeContentElement}>
        <Typography
          variant='h2'
          className={classes.noticeContentTitle}
          style={{minWidth: i18n.language === 'ko' ? 65 : 125}}
        >
          {t(label)} :
        </Typography>
        <Typography variant='h6' className={classes.noticeContentNotice}>
          <a
            className={classes.link}
            href={link}
            target='_blank'
            rel='noopener noreferrer'
          >
            {value}
          </a>
        </Typography>
      </div>
    )

  if (type === 'date' && value.length)
    return (
      <div className={classes.noticeContentElement}>
        <Typography
          variant='h2'
          className={classes.noticeContentTitle}
          style={{minWidth: i18n.language === 'ko' ? 65 : 125}}
        >
          {t(label)} :
        </Typography>
        <div>
          <Typography variant='h6' className={classes.noticeContentNotice}>
            {value[0]}
          </Typography>
          <Typography variant='h6' className={classes.noticeContentNotice}>
            {value[1]}
          </Typography>
        </div>
      </div>
    )

  return null
}

export default function SystemMaintainPage() {
  const {t, i18n} = useTranslation()
  const lang = i18n.language
  const classes = useStyles()
  const url = getS3Links()
  const {onOpen} = useFailureModal()

  const [data, setData] = useState<SaleAppNotice | null>(null)

  const getTitle = useMemo(() => {
    if (!data || !data.translations) return '-'
    return data.translations.find((tran) => tran.lang === lang)?.title ?? ''
  }, [data, lang])

  const getContents = useMemo(() => {
    if (!data || !data.translations) return ''
    return data.translations.find((item) => item.lang === lang)?.content ?? ''
  }, [data, lang])

  const getTimeStart = useMemo(
    () =>
      data && data.attribute?.periodStartTime
        ? makeDateAndTimeFormat(new Date(data.attribute.periodStartTime))
        : '-',
    [data, lang],
  )

  const getTimeEnd = useMemo(
    () =>
      data && data.attribute?.periodEndTime
        ? makeDateAndTimeFormat(new Date(data.attribute.periodEndTime))
        : '-',
    [data, lang],
  )

  const noticeContent: HtmlNoticesType[] = useMemo(
    () => [
      {label: 'ITitle', value: getTitle},
      {label: 'IContents', value: getContents},
      {
        label: 'IInspectionTime',
        value: [`Start: ${getTimeStart}`, `End: ${getTimeEnd}`],
        type: 'date',
      },
      {
        label: 'IAttachFile',
        value: data?.links[0]?.name,
        type: 'link',
        link: data?.links[0]?.link,
      } as HtmlNoticesType,
    ],
    [data, lang],
  )

  useEffect(() => {
    if (url) {
      axios
        .get<SaleAppNotice>(url)
        .then((response: AxiosResponse<SaleAppNotice>) => {
          setData(response.data)
        })
        .catch((error: any) => {
          console.error('Error:', error)
          onOpen(error.message)
        })
    }
  }, [url])

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.logo}>
          <img className={classes.img} src={OldLogo} alt='' />
        </div>
        <div className={classes.header}>
          <p className={classes.title}>iSyncBrain®-C/M</p>
          <div className={classes.desc}>{t('ISystemGuide')}</div>
        </div>
        <hr className={classes.hr} />
        <div className={classes.content}>
          {noticeContent.map((item, index) => (
            <HtmlNoticesElement key={index} {...item} />
          ))}
        </div>
      </div>
    </div>
  )
}
