import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {useHistory, useLocation} from 'react-router-dom'
import {useAppDispatch} from 'hooks'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import Chip from '@material-ui/core/Chip'
import Grid from '@material-ui/core/Grid'
import {createStyles, makeStyles} from '@material-ui/core/styles'
import CreateIcon from '@material-ui/icons/Create'
import DeleteIcon from '@material-ui/icons/Delete'
import ActionButton from 'components/atoms/Buttons/ActionButton'
import Pagination from 'components/molcules/Pagination'
import useAuth from 'features/auth/useAuth'
import useConfirm from 'features/modal/useConfirm'
import {openQnaCreateDialog, openQnaReadDialog} from 'features/modal/modalSlice'
import {
  deleteQnaFlowAction,
  fetchQnaListAction,
  selectQna,
} from 'features/qna/qnaSlice'
import QuestionCreateDialog from 'pages/CommunityPages/QuestionPage/QuestionCreateDialog'
import QuestionEditDialog from 'pages/CommunityPages/QuestionPage/QuestionEditDialog'
import QuestionReadDialog from 'pages/CommunityPages/QuestionPage/QuestionReadDialog'
import useSuccessModal from 'features/modal/useSuccessModal'
import useFailureModal from 'features/modal/useFailureModal'
import {NoticeListRequest} from 'api/noticeApi'
import {getQueryFromUrl} from 'helpers/commonHelper'
import {makeDateAndTimeFormat} from 'helpers/dateHelper'
import useTableStyles from 'components/Table/useTableStyles'

function checkAuthority(Authority: string): boolean {
  if (Authority === 'ROLE_COMPANY_SALES' || Authority === 'ROLE_COMPANY_CS') {
    return true
  }
  return false
}

function titleLimit(text: string): string {
  let title = ''
  if (text.length > 20) {
    title = text.substring(0, 20)
    return `${title}...`
  }
  return text
}

function makeChip(data: number) {
  const chipLabel = `Total ${data}`
  return chipLabel
}

const useTableRowStyles = makeStyles(() =>
  createStyles({
    hover: {
      '&:hover': {
        backgroundColor: '#F8F8F8 !important',
        cursor: 'pointer',
      },
    },
    chipTextColor: {
      color: '#BCBDBF',
    },
    headerSize: {
      minWidth: 250,
      maxWidth: 250,
    },
    textColor: {
      color: '#FF5D5D',
      fontSize: 10,
      fontWeight: 'bolder',
      width: 40,
      border: '1px solid #FD7E7E',
      marginLeft: 10,
    },
  }),
)

interface QnaTableHeadProps {
  classes: ReturnType<typeof useTableStyles>
  numSelected: number
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
  rowCount: number
}

function QnaTableHead({
  classes,
  onSelectAllClick,
  numSelected,
  rowCount,
}: QnaTableHeadProps) {
  const {t} = useTranslation()
  const {user: currentUser} = useAuth()

  return (
    <TableHead>
      <TableRow className={classes.tableHeader}>
        {checkAuthority(currentUser?.authority ?? '') && (
          <TableCell padding='checkbox'>
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{'aria-label': 'select all desserts'}}
            />
          </TableCell>
        )}

        <TableCell align='center'>No.</TableCell>
        <TableCell align='center'>{t('ITitle')}</TableCell>
        <TableCell align='center'>{t('IContents')}</TableCell>
        <TableCell align='center'>{t('ICreateUser')}</TableCell>
        <TableCell align='center'>{t('IQnaAnswer')}</TableCell>
        <TableCell align='center'>{t('ICreateDate')}</TableCell>
      </TableRow>
    </TableHead>
  )
}

interface QnaTableRowProps {
  isSelected: (id: number) => boolean
  authority: string
  onClick: (event: React.MouseEvent<unknown>, chartNo: number) => void
  dataList: QnaContent[]
}

const QnaTableRow = ({
  isSelected,
  onClick,
  dataList,
  authority,
}: QnaTableRowProps) => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const [selectedId, setSelectedId] = React.useState(0)

  const classes = useTableRowStyles()

  const rowsPerPage = 5
  const emptyRows = rowsPerPage - dataList.length

  function checkNewMark(answer: string, confirmedYn: string): boolean {
    if (answer === 'Completed' && confirmedYn === 'N') {
      return true
    }
    return false
  }

  return (
    <TableBody>
      {dataList.map((qna) => {
        const labelId = `enhanced-table-checkbox-${qna.id ?? 0}`

        return (
          <TableRow
            key={qna.id}
            hover
            classes={{hover: classes.hover}}
            aria-checked={isSelected(qna.id)}
            role='checkbox'
            selected={isSelected(qna.id ?? 0)}
          >
            {checkAuthority(authority) && (
              <TableCell padding='checkbox'>
                <Checkbox
                  checked={isSelected(qna.id)}
                  inputProps={{'aria-labelledby': labelId}}
                  onClick={(event) => onClick(event, qna.id)}
                />
              </TableCell>
            )}
            <TableCell
              onClick={() => {
                dispatch(openQnaReadDialog())
                setSelectedId(qna.id)
              }}
            >
              <Typography align='center'>{qna.id}</Typography>
            </TableCell>
            <TableCell
              onClick={() => {
                dispatch(openQnaReadDialog())
                setSelectedId(qna.id)
              }}
              align='center'
              className={classes.headerSize}
            >
              <div>
                {titleLimit(qna.title)}
                {checkNewMark(qna.answer, qna.confirmedYn) && (
                  <Chip
                    variant='outlined'
                    className={classes.textColor}
                    size='small'
                    label='NEW'
                  />
                )}
              </div>
            </TableCell>
            <TableCell
              onClick={() => {
                dispatch(openQnaReadDialog())
                setSelectedId(qna.id)
              }}
              align='center'
              className={classes.headerSize}
            >
              {titleLimit(qna.content)}
            </TableCell>
            <TableCell
              onClick={() => {
                dispatch(openQnaReadDialog())
                setSelectedId(qna.id)
              }}
              align='center'
            >
              {t('IGetFullName', {
                firstName: qna.firstName ?? '',
                lastName: qna.lastName ?? '',
              }).trim()}
            </TableCell>
            {qna.answer === 'Completed' && (
              <TableCell
                align='center'
                onClick={() => {
                  dispatch(openQnaReadDialog())
                  setSelectedId(qna.id)
                }}
              >
                <Chip
                  variant='outlined'
                  color='primary'
                  size='small'
                  label={t('IComplete')}
                />
              </TableCell>
            )}
            {qna.answer === '-' && (
              <TableCell
                align='center'
                onClick={() => {
                  dispatch(openQnaReadDialog())
                  setSelectedId(qna.id)
                }}
              >
                <Chip
                  variant='outlined'
                  size='small'
                  label={t('IWaiting')}
                  className={classes.chipTextColor}
                />
              </TableCell>
            )}
            <TableCell
              align='center'
              onClick={() => {
                dispatch(openQnaReadDialog())
                setSelectedId(qna.id)
              }}
            >
              {makeDateAndTimeFormat(new Date(qna.dateTime) ?? new Date())}
            </TableCell>
          </TableRow>
        )
      })}
      {emptyRows > 0 && (
        <TableRow style={{height: 43 * emptyRows, backgroundColor: '#F9F9FB'}}>
          <TableCell colSpan={12} />
        </TableRow>
      )}
      <QuestionReadDialog id={selectedId} />
      <QuestionEditDialog id={selectedId} />
    </TableBody>
  )
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: 10,
    marginBottom: 30,
  },
  navContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 5,
    flexWrap: 'wrap',
    '& div ': {
      display: 'flex',
      flexDirection: 'row',
      gap: 5,
      flexWrap: 'wrap',
    },
  },
  button: {
    height: 55,
    minWidth: 110,
    maxWidth: 110,
  },
  tableWrap: {
    backgroundColor: '#fff',
    marginTop: 10,
    overflow: 'Hidden',
  },
  tableHeader: {
    height: 50,
    backgroundColor: '#e4e7eb',
  },
  headerSize: {
    minWidth: 250,
    maxWidth: 250,
  },
  headerNameSize: {
    minWidth: 100,
    maxWidth: 100,
  },
}))

export default function QuestionTable() {
  const history = useHistory()
  const location = useLocation()
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const classes = useStyles()
  const qna = useSelector(selectQna)
  const {user: currentUser} = useAuth()
  const tableClasses = useTableStyles()
  const {onOpen: onSuccessModalOpen} = useSuccessModal()
  const {onOpen: onFailureModalOpen} = useFailureModal()
  const {onOpen: onConfirmOpen} = useConfirm()

  const qnaList = qna.qnaList ?? []
  const [chip, setChip] = React.useState('0')
  const [selected, setSelected] = React.useState<number[]>([])

  // Refactor fetch data function dispatch fetchQnaListAction
  const fetchData = (payload: NoticeListRequest) => {
    try {
      const params = new URLSearchParams(
        Object.entries(payload).map(([key, value]) => [
          key,
          typeof value === 'object' ? JSON.stringify(value) : value,
        ]),
      )
      history.replace({pathname: location.pathname, search: params.toString()})
    } catch (error) {
      console.error('Update URLSearchParams failed', error)
    }
    return dispatch(fetchQnaListAction(payload))
  }

  const isSelected = (qnaListId: number) => selected.indexOf(qnaListId) !== -1
  const setPageIndex = (page: number) => {
    fetchData({
      paging: {
        page,
        size: qna.itemPerPage ?? 10,
      },
    })
  }
  const handleAddButtonClick = () => {
    dispatch(openQnaCreateDialog())
  }
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = qnaList.map((n) => n.id)
      setSelected(newSelected)
      return
    }
    setSelected([])
  }

  const handleClick = (event: React.MouseEvent<unknown>, chartNo: number) => {
    const selectedIndex = selected.indexOf(chartNo)
    let newSelected: number[] = []
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, chartNo)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      )
    }
    setSelected(newSelected)
  }

  const handleDelete = async () => {
    if (selected.length > 0) {
      const confirmation = await onConfirmOpen({
        message: t('IInquiryDeleteSelect'),
      })
      if (!confirmation.payload) return
      try {
        const response = await dispatch(
          deleteQnaFlowAction({
            list: selected,
            uid: currentUser?.uid ?? '',
          }),
        )
        if (response) {
          onSuccessModalOpen(t('IDeleteSuccess'))
          fetchData({
            paging: {
              page: 0,
              size: qna.itemPerPage ?? 10,
            },
          })
          setSelected([])
        }
      } catch (err) {
        onFailureModalOpen(err.message)
      }
    }
  }

  useEffect(() => {
    setChip(makeChip(qna.totalElements))
  }, [qna])

  /** Set default value for search query if it is stored in url */
  useEffect(() => {
    const initParams = getQueryFromUrl(location.search)
    fetchData({
      paging: {
        page: 0,
        size: 10,
      },
      ...initParams,
    })
  }, [])

  return (
    <div className={classes.root}>
      <div className={classes.navContainer}>
        <Chip size='small' label={chip} />
        <div>
          {checkAuthority(currentUser?.authority ?? '') && (
            <ActionButton
              color='primary'
              variant='contained'
              onClick={handleDelete}
              disableElevation
              startIcon={<DeleteIcon />}
            >
              {t('IDelete')}
            </ActionButton>
          )}
          {!checkAuthority(currentUser?.authority ?? '') && (
            <ActionButton
              color='primary'
              variant='contained'
              onClick={handleAddButtonClick}
              disableElevation
              startIcon={<CreateIcon />}
            >
              {t('ICreate')}
            </ActionButton>
          )}
        </div>
      </div>

      <Grid item xs={12} className={classes.tableWrap}>
        <TableContainer>
          <Table
            aria-labelledby='tableTitle'
            aria-label='enhanced table'
            size='small'
            className={tableClasses.table}
          >
            <QnaTableHead
              classes={tableClasses}
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={qnaList.length}
            />

            <QnaTableRow
              isSelected={isSelected}
              onClick={handleClick}
              dataList={qna.qnaList ?? []}
              authority={currentUser?.authority ?? ''}
            />
          </Table>
        </TableContainer>
      </Grid>
      <Pagination
        totalPageCount={qna.totalPages}
        currentPageIndex={qna.pageIndex}
        itemCountPerPage={qna.itemPerPage}
        setCurrentPageIndex={setPageIndex}
        loading={qna.loading}
        onItemCountPerPageChanged={(event) => {
          fetchData({
            paging: {
              page: 0,
              size: parseInt(event.target.value as string, 10),
            },
          })
        }}
      />

      <QuestionCreateDialog />
    </div>
  )
}
