import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {useAppDispatch} from 'hooks'
import Button from '@material-ui/core/Button'
import CardContent from '@material-ui/core/CardContent'
import Dialog from '@material-ui/core/Dialog'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import Card from 'components/atoms/Card'
import useAuth from 'features/auth/useAuth'
import useConfirm from 'features/modal/useConfirm'
import {
  closeQnaReadDialog,
  openQnaEditDialog,
  selectQnaReadDialogOpen,
} from 'features/modal/modalSlice'
import {
  deleteQnaAction,
  fetchQnaDetailInfoAction,
  fetchQnaListAction,
  selectQna,
} from 'features/qna/qnaSlice'
import useStyles from 'components/Dialog/User/Style'

interface Props {
  id: number
}

export default function QuestionReadDialog(prop: Props) {
  const {t} = useTranslation()
  const {id} = prop
  const classes = useStyles()
  const dispatch = useAppDispatch()
  const qnaRead = useSelector(selectQnaReadDialogOpen)
  const qnaData = useSelector(selectQna)
  const {user: currentUser} = useAuth()
  const {onOpen: onConfirmOpen} = useConfirm()

  function checkAuthority(Authority: string): boolean {
    if (Authority === 'ROLE_COMPANY_SALES' || Authority === 'ROLE_COMPANY_CS') {
      return true
    }
    return false
  }

  const handleClose = () => dispatch(closeQnaReadDialog())

  const handleDelete = async () => {
    const confirmation = await onConfirmOpen({
      message: t('IInquiryDeleteSelect'),
    })
    if (!confirmation.payload) return
    const result = await dispatch(deleteQnaAction(id))
    if (result.payload === 'SUCCESS') {
      dispatch(fetchQnaListAction({paging: {page: 0, size: 10}}))
      dispatch(closeQnaReadDialog())
    }
  }

  useEffect(() => {
    if (id && qnaRead) {
      dispatch(fetchQnaDetailInfoAction(id))
    }
  }, [qnaRead, id])

  return (
    <div>
      <Dialog open={qnaRead} maxWidth='md' onClose={handleClose}>
        <Card className={classes.cardWrap}>
          <Grid className={classes.root} style={{width: 880}}>
            <Grid item xs={12}>
              <div
                className={classes.longTitleContainer}
                style={{backgroundColor: '#f9f9fb'}}
              >
                <div className={classes.containerTitle}>
                  <div className={classes.closeButtonWrap}>
                    <IconButton
                      color='secondary'
                      aria-label='favorite'
                      className={classes.closeButton}
                      onClick={handleClose}
                    >
                      <CloseIcon className={classes.closeIcon} />
                    </IconButton>
                  </div>
                  <Typography variant='h6' className={classes.title}>
                    {t('IInquireRead')}
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item xs={12}>
              <CardContent
                className={classes.scroll}
                style={{backgroundColor: '#f9f9fb'}}
              >
                <div className={classes.infoWrap}>
                  <Typography
                    style={{fontSize: 15, fontWeight: 600, color: '#4b4b4b'}}
                  >
                    {t('IInquireTitle')}
                  </Typography>
                  <div
                    className={classes.contentContainer}
                    style={{minWidth: 500}}
                  >
                    <span className={classes.bullet}> </span>
                    <Typography
                      className={classes.subContent}
                      style={{width: '90%', wordBreak: 'break-word'}}
                    >
                      {qnaData.qnaData?.title ?? t('ITitleEmpty')}
                    </Typography>
                  </div>
                  <Divider className={classes.titleDivider} />
                </div>
                <Typography className={classes.padding} />
                <div className={classes.infoWrap}>
                  <Typography
                    style={{fontSize: 15, fontWeight: 600, color: '#4b4b4b'}}
                  >
                    {t('IInquireType')}
                  </Typography>
                  <div className={classes.contentContainer}>
                    <span className={classes.bullet}> </span>
                    <Typography className={classes.subContent}>
                      {qnaData.qnaData?.qnaType ?? ''}
                    </Typography>
                  </div>
                  <Divider className={classes.titleDivider} />
                </div>
                <Typography className={classes.padding} />
                <div className={classes.infoWrap}>
                  <Typography
                    style={{fontSize: 15, fontWeight: 600, color: '#4b4b4b'}}
                  >
                    {t('IInquireContents')}
                  </Typography>
                  <div
                    className={classes.contentContainer}
                    style={{height: 250, alignContent: 'flex-start'}}
                  >
                    <span className={classes.bullet}> </span>
                    <Typography
                      className={classes.questionContent}
                      style={{wordBreak: 'break-word'}}
                    >
                      {qnaData.qnaData?.content ?? t('IContentsEmpty')}
                    </Typography>
                  </div>
                </div>
                <Typography className={classes.padding} />
                <div className={classes.infoWrap}>
                  <Typography
                    style={{fontSize: 15, fontWeight: 600, color: '#4b4b4b'}}
                  >
                    {t('IQnaAnswerContents')}
                  </Typography>
                  <div
                    className={classes.contentContainer}
                    style={{height: 250, alignContent: 'flex-start'}}
                  >
                    <span className={classes.bullet}> </span>
                    {qnaData.qnaData?.answer === '' && (
                      <Typography
                        className={classes.subContent}
                        style={{wordBreak: 'break-word'}}
                      >
                        {t('IQnaAnswerEmpty')}
                      </Typography>
                    )}
                    {qnaData.qnaData?.answer !== '' && (
                      <Typography className={classes.subContent}>
                        {qnaData.qnaData?.answer ?? t('IAnswerEmpty')}
                      </Typography>
                    )}
                  </div>
                </div>
              </CardContent>
            </Grid>
          </Grid>
          <div style={{display: 'flex', justifyContent: 'center'}}>
            {!checkAuthority(currentUser?.authority ?? '') && (
              <div style={{padding: '20px 0', display: 'flex', gap: 10}}>
                <Button
                  variant='contained'
                  disableElevation
                  onClick={() => {
                    dispatch(closeQnaReadDialog())
                  }}
                  style={{width: 200, height: 45, borderRadius: 8}}
                >
                  {t('IOk')}
                </Button>
                {qnaData.qnaData?.confirmedYn === 'N' && (
                  <Button
                    variant='contained'
                    color='primary'
                    disableElevation
                    style={{width: 200, height: 45, borderRadius: 8}}
                    onClick={() => {
                      dispatch(closeQnaReadDialog())
                      dispatch(openQnaEditDialog())
                    }}
                    className={classes.okButtonColor}
                  >
                    {t('IEdit')}
                  </Button>
                )}
                {qnaData.qnaData?.confirmedYn === 'N' && (
                  <Button
                    variant='contained'
                    color='secondary'
                    disableElevation
                    style={{width: 200, height: 45, borderRadius: 8}}
                    onClick={() => {
                      handleDelete()
                    }}
                    className={classes.okButtonColor}
                  >
                    {t('IDelete')}
                  </Button>
                )}
              </div>
            )}
            {checkAuthority(currentUser?.authority ?? '') && (
              <div style={{padding: '20px 0', display: 'flex', gap: 10}}>
                <Button
                  variant='contained'
                  disableElevation
                  style={{width: 200, height: 45, borderRadius: 8}}
                  onClick={() => {
                    dispatch(closeQnaReadDialog())
                  }}
                >
                  {t('IOk')}
                </Button>
                <Button
                  variant='contained'
                  color='primary'
                  style={{width: 200, height: 45, borderRadius: 8}}
                  disableElevation
                  onClick={() => {
                    dispatch(closeQnaReadDialog())
                    dispatch(openQnaEditDialog())
                  }}
                  className={classes.okButtonColor}
                >
                  {t('IQnaAnswerCreateAndUpdate')}
                </Button>
              </div>
            )}
          </div>
        </Card>
      </Dialog>
    </div>
  )
}
