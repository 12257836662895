import React, {useEffect, useState} from 'react'
import {TypeScreenGraph} from 'api/analysisApi'
import {WaveGraphPropertySourceImpl} from 'lib/GraphSource'
import RawDataEdfGraphV1 from 'components/molcules/Report/RawData/RawDataEdfGraphV1'
import {HorizontalImageGraphV1} from 'components/molcules/Report/RawData/HorizontalImageGraphV1'
import styled from 'styled-components'

export const GraphContainer = styled.div`
  margin: 0;
  overflow-x: scroll;
  min-height: 608px; // ch h 32 * 19
`
export interface WaveGraphRawProps {
  url?: string
  usingCache?: boolean
}

export interface WaveGraphProps {
  raw: WaveGraphRawProps
  image?: string
  yAxisImage?: string
  propertyGuide: WaveGraphPropertySourceImpl
  screen: TypeScreenGraph
}
const WaveGraph = ({raw, image, propertyGuide, screen}: WaveGraphProps) => {
  const [containerWidthForGraph, setContainerWidthForGraph] = useState(0)

  useEffect(() => {
    const handleResizeEdfContainer = () => {
      const containerWidth = window.innerWidth - 210
      setContainerWidthForGraph(containerWidth)
    }

    // Add an event listener for window resize
    window.addEventListener('resize', handleResizeEdfContainer)

    // Call the handleResize function initially to set the initial width
    handleResizeEdfContainer()

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResizeEdfContainer)
    }
  }, [])

  const {url: rawUrl, usingCache} = raw

  return (
    <GraphContainer>
      {rawUrl !== undefined ? (
        <RawDataEdfGraphV1
          edfUrl={rawUrl}
          width={containerWidthForGraph}
          xAxisImg={propertyGuide.xAxisImage}
          usingCache={usingCache}
          propertyGuide={propertyGuide}
          screen={screen}
        />
      ) : (
        <HorizontalImageGraphV1
          width={containerWidthForGraph}
          xAxisImg={image ?? ''}
          yAxisImg={propertyGuide.yAxisImage}
        />
      )}
    </GraphContainer>
  )
}

export default WaveGraph
