import {CompanyRoles} from 'Authority'
import RouteConstant from 'constants/RouteConstant'
import CommonLayout from 'layouts/Common'
import SalesUsageInquiryPage from 'pages/SalesPages//SalesUsageInquiry'
import SalesAccountPage from 'pages/SalesPages/SalesAccountPage'
import SalesUserLogPage from 'pages/SalesPages/SalesUserLogPage'
import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import RouteIf from 'routes/RouteIf'
import RegistrationPage from 'pages/CustomerManagement/InvoiceList/index'
import InquiryPage from 'pages/CustomerManagement/Inquiry/index'
import InvoiceSetting from 'pages/CustomerManagement/InvoiceSetting'
import InvoiceView from 'pages/CustomerManagement/InvoiceView'
import MemberRegistrationPage from 'pages/CustomerManagement/MemberRegistration'
import MemberEditPage from 'pages/CustomerManagement/MemberEdit'
import SalesAppNoticePage from 'pages/SalesPages/SalesAppNoticePage'
import SalesAppNoticeCreatePage from 'pages/SalesPages/SalesAppNoticePage/CreateNoticePage'
import SalesPageManagementPage from './SalesPageManagement'
import SalesPaymentManagementPage from './SalesPaymentManagement'

export default function SalesRoute() {
  return (
    <CommonLayout>
      <Switch>
        <RouteIf
          requiredRoles={CompanyRoles}
          path={RouteConstant.SALES_ACCOUNTS.path}
          exact
          component={SalesAccountPage}
        />
        <RouteIf
          requiredRoles={CompanyRoles}
          path={RouteConstant.SALES_PURCHASE.path}
          exact
          component={SalesPaymentManagementPage}
        />
        <RouteIf
          requiredRoles={CompanyRoles}
          path={RouteConstant.SALES_STATISTICS.path}
          exact
          component={SalesUserLogPage}
        />
        <RouteIf
          requiredRoles={CompanyRoles}
          path={RouteConstant.SALES_USAGE_INQUIRY.path}
          exact
          component={SalesUsageInquiryPage}
        />
        <RouteIf
          requiredRoles={CompanyRoles}
          path={RouteConstant.SALES_PAGE_MANAGEMENT.path}
          exact
          component={SalesPageManagementPage}
        />
        <RouteIf
          requiredRoles={CompanyRoles}
          path={RouteConstant.SALES_APP_NOTICE.path}
          exact
          component={SalesAppNoticePage}
        />
        <RouteIf
          requiredRoles={CompanyRoles}
          path={RouteConstant.SALES_APP_NOTICE_DETAIL.path}
          exact
          component={SalesAppNoticeCreatePage}
        />
        <RouteIf
          requiredRoles={CompanyRoles}
          path={RouteConstant.SALES_APP_NOTICE_CREATE.path}
          exact
          component={SalesAppNoticeCreatePage}
        />
        <RouteIf
          requiredRoles={CompanyRoles}
          path={RouteConstant.SALES_APP_NOTICE_UPDATE.path}
          exact
          component={SalesAppNoticeCreatePage}
        />
        <Route
          path={RouteConstant.INVOICES_LIST.path}
          component={RegistrationPage}
        />
        <Route
          path={RouteConstant.MEMBER_EDIT.path}
          component={MemberEditPage}
        />
        <Route
          path={RouteConstant.MEMBER_REGISTRATION.path}
          component={MemberRegistrationPage}
        />
        <Route
          path={RouteConstant.CUSTOMER_INQUIRY.path}
          component={InquiryPage}
        />
        <Route path={RouteConstant.INVOICE_VIEW.path} component={InvoiceView} />
        <Route
          path={RouteConstant.INVOICE_SETTING.path}
          component={InvoiceSetting}
        />
        <Route path='*'>
          <Redirect to={RouteConstant.ERROR_NOT_FOUND.path} />
        </Route>
      </Switch>
    </CommonLayout>
  )
}
