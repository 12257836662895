import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {navigate} from 'AppHistory'
import {Divider} from '@material-ui/core'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import NotificationsIcon from '@material-ui/icons/Notifications'
import {makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import Menu from '@material-ui/icons/Menu'
import SettingsIcon from '@material-ui/icons/Settings'
import TranslateIcon from '@material-ui/icons/Translate'
import Button from 'components/CustomButtons/Button'
import useNoticeConfirm from 'features/modal/useNoticeConfirm'
import useAuth from 'features/auth/useAuth'
import NotificationDetail from 'components/molcules/SalesPages/Notification/NotificationDetail'
import {setLanguage} from 'api/storageApi'
import useNotificationModal from 'features/modal/useNotificationModal'
import {getNoticeListPath} from 'constants/RouteConstant'
import styles from 'assets/tss/material-dashboard-pro-react/components/adminNavbarStyle'

const useStyles = makeStyles(styles)

interface AdminNavbarProps {
  // color?: 'primary' | 'info' | 'success' | 'warning' | 'danger'
  brandText: string
  menuText: string
  // miniActive: boolean
  handleDrawerToggle: VoidFunction
  // sidebarMinimize: VoidFunction
}

type Locale = 'ko' | 'en'

function TranslationSelect() {
  const {i18n} = useTranslation()
  const classes = useStyles()
  const [currentLocale, setCurrentLocale] = useState<Locale>('ko')
  const [translationOpen, setTranslationOpen] = React.useState(false)

  const handleClickAway = () => {
    setTranslationOpen(false)
  }

  const handleTranslationClick = () => {
    setTranslationOpen((prev) => !prev)
  }

  const handleKoreanClick = async () => {
    await i18n.changeLanguage('ko' as Locale)
    setLanguage('ko')
    handleClickAway()
  }

  const handleEnglishClick = async () => {
    await i18n.changeLanguage('en' as Locale)
    setLanguage('en')
    handleClickAway()
  }

  useEffect(() => {
    setCurrentLocale(i18n.language as Locale)
  }, [i18n.language])

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className={classes.translationClick}>
        <Button
          className={classes.translationWrap}
          onClick={handleTranslationClick}
          disableElevation
        >
          <TranslateIcon />
          {currentLocale === 'ko' && <Typography>한국어</Typography>}
          {currentLocale === 'en' && <Typography>English</Typography>}

          <ArrowDropDownIcon />
        </Button>
        {translationOpen ? (
          <div className={classes.dropdown}>
            <Typography
              className={classes.translationText}
              onClick={handleKoreanClick}
            >
              한국어
            </Typography>
            <Divider className={classes.dividerPadding} />
            <Typography
              className={classes.translationText}
              onClick={handleEnglishClick}
            >
              English
            </Typography>
          </div>
        ) : null}
      </div>
    </ClickAwayListener>
  )
}

function Notification() {
  const classes = useStyles()
  const {data, onFetch} = useNotificationModal()
  const [notificationOpen, setNotificationOpen] = React.useState(false)
  const isUpdating = React.useMemo(
    () =>
      data?.attribute?.status === 'wait' ||
      data?.attribute?.status === 'proceed',
    [data],
  )
  const handleClickAway = () => {
    setNotificationOpen(false)
  }

  const handleNoticeClick = () => {
    if (isUpdating) {
      setNotificationOpen(true)
    } else {
      navigate(getNoticeListPath())
    }
  }

  useEffect(() => {
    onFetch()
  }, [])

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className={classes.translationClick}>
        <IconButton
          className={isUpdating ? classes.red : classes.white}
          aria-label='notification'
          onClick={() => handleNoticeClick()}
        >
          <NotificationsIcon />
        </IconButton>
        {notificationOpen ? (
          <div className={classes.popupDetailNotification}>
            <NotificationDetail onClose={handleClickAway} />
          </div>
        ) : null}
      </div>
    </ClickAwayListener>
  )
}

function SettingSelect() {
  const {t} = useTranslation()
  const classes = useStyles()
  const {onLogout} = useAuth()
  const {onOpen: onNoticeDialogOpen} = useNoticeConfirm()
  const [settingOpen, setSettingOpen] = React.useState(false)
  const handleClickAway = () => {
    setSettingOpen(false)
  }
  const handleTranslationClick = () => {
    setSettingOpen((prev) => !prev)
  }

  const logout = () => {
    onNoticeDialogOpen({
      title: t('ILogout'),
      message: t('ILogoutMessage'),
    }).then((result) => {
      if (result.payload) {
        onLogout()
      }
    })
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className={classes.translationClick}>
        <IconButton
          className={classes.appResponsive}
          aria-label='delete'
          onClick={handleTranslationClick}
        >
          <SettingsIcon />
        </IconButton>
        {settingOpen ? (
          <div className={classes.dropdown}>
            {/* <NavLink to='/auth/selectAccount'>
              <Typography className={classes.translationText}>
                {t('ISelectAccount')}
              </Typography>
            </NavLink>
            <Divider className={classes.dividerPadding} /> */}
            <Typography
              className={classes.translationText}
              onClick={() => logout()}
            >
              {t('ILogout')}
            </Typography>
          </div>
        ) : null}
      </div>
    </ClickAwayListener>
  )
}

export default function Navbar(props: AdminNavbarProps) {
  const classes = useStyles()
  const {
    brandText,
    menuText,
    handleDrawerToggle,
    // color,
    // miniActive,
    // sidebarMinimize,
  } = props

  return (
    <div>
      <div className={classes.navItemContainer}>
        <Hidden mdUp implementation='css'>
          <IconButton
            className={classes.appResponsive}
            aria-label='delete'
            onClick={handleDrawerToggle}
          >
            <Menu />
          </IconButton>
        </Hidden>
        <div className={classes.navItemWrap}>
          <Notification />
          <TranslationSelect />
          <SettingSelect />
        </div>
      </div>

      <Typography className={classes.navBarTitle}>{brandText}</Typography>
      <Typography className={classes.navBarSubtitle}>
        {menuText} / {brandText}
      </Typography>
    </div>
  )
}
