import React, {useEffect, useMemo, useState} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {Typography} from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import {makeStyles} from '@material-ui/core/styles'
import ReorderIcon from '@material-ui/icons/Reorder'
import ActionButton from 'components/atoms/Buttons/ActionButton'
import CardWithTitle from 'components/atoms/CardWithTitle'
import {
  TYPE_NOTICE_OPTIONS,
  TYPE_STATUS_OPTIONS,
} from 'pages/SalesPages/SalesAppNoticePage/constants'
import {getNoticeListPath} from 'constants/RouteConstant'
import {makeDateAndTimeFormat} from 'helpers/dateHelper'
import {getUserNoticeDetailApi, SaleAppNotice} from 'api/salesNoticeApi'
import NoticeReadStyle from 'pages/CommunityPages/NoticePage/NoticeReadPage/Style'

const useStyles = makeStyles(NoticeReadStyle)

interface Params {
  id: string
}

export default function NoticeReadPage() {
  const {t, i18n} = useTranslation()
  const lang = i18n.language

  const classes = useStyles()
  const {id: noticeId} = useParams<Params>()
  const history = useHistory()
  const [data, setData] = useState<SaleAppNotice | null>(null)

  const getTypeTitle = useMemo(() => {
    if (!data) return ''
    if (data.type === 'general') return t('IGeneralNotice')

    return `${t('IMaintenanceShortNotice')} (${t(
      TYPE_NOTICE_OPTIONS.find((item) => item.value === data.attribute.type)
        ?.label ?? '',
    )}) `
  }, [data, lang])

  const getPeriodTime = useMemo(() => {
    if (!data || !data.attribute) return ''
    if (data.attribute.periodStartTime && data.attribute.periodEndTime) {
      const startAt = new Date(data.attribute.periodStartTime)
      const endAt = new Date(data.attribute.periodEndTime)
      return `${makeDateAndTimeFormat(startAt)} ~ ${makeDateAndTimeFormat(
        endAt,
      )}`
    }
    return ''
  }, [data])

  const getStatuses = useMemo(() => {
    return t(
      TYPE_STATUS_OPTIONS.find((item) => item.value === data?.attribute?.status)
        ?.label ?? '',
    )
  }, [data, lang])

  const getTitle = useMemo(() => {
    if (!data || !data.translations) return ''
    return data.translations.find((item) => item.lang === lang)?.title ?? ''
  }, [data, lang])

  const getContents = useMemo(() => {
    if (!data || !data.translations) return ''
    return data.translations.find((item) => item.lang === lang)?.content ?? ''
  }, [data, lang])

  useEffect(() => {
    const fetchDataDetailNotice = async () => {
      if (Number(noticeId)) {
        const data = await getUserNoticeDetailApi(Number(noticeId))
        if (data.data) setData(data.data)
      }
    }
    if (noticeId) fetchDataDetailNotice()
  }, [noticeId])

  return (
    <CardWithTitle title={t('INotice')}>
      <div className={classes.gridContainer}>
        <Grid item xs={12} className={classes.contentWrap}>
          <Grid item xs={12} sm={12} className={classes.titleCenter}>
            <Typography variant='h6'>
              {t('IDivision')} : {getTypeTitle}
            </Typography>
          </Grid>
          <Typography className={classes.padding} />

          <Grid item xs={12} sm={12} className={classes.flexGap30}>
            <Grid item xs={8} sm={8} className={classes.flexColumn}>
              <Typography className={classes.titleCenter}>
                {t('ITitle')}
              </Typography>
              <div className={classes.titleTextField}>
                {getTitle ?? t('INoticeEmpty')}
              </div>
            </Grid>
            <Grid item xs={4} sm={4} className={classes.flexColumn}>
              <Typography className={classes.titleCenter}>
                {t('ICreateDate')}
              </Typography>
              <div className={classes.titleTextField}>
                {data?.createdAt
                  ? makeDateAndTimeFormat(new Date(data.createdAt))
                  : ''}
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Typography className={classes.padding} />
        <div>
          <Grid item xs={12} sm={1}>
            <Typography className={classes.titleSize}>
              {t('IContents')}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            className={classes.contentContainer}
            style={{whiteSpace: 'pre-wrap'}}
          >
            <div className={classes.contentTextField}>
              {getContents ?? t('IContentsEmpty')}
            </div>
          </Grid>
        </div>
        <Typography className={classes.padding} />
        {data?.links[0]?.link && (
          <Typography>
            {t('IAppNoticeAttachedFileButton')} :{' '}
            <span>
              <a
                className={classes.link}
                href={data?.links[0]?.link}
                target='_blank'
                rel='noopener noreferrer'
              >
                {data?.links[0]?.name}
              </a>
            </span>
          </Typography>
        )}
        <Typography className={classes.padding} />

        <Typography>
          {t('IPeriod')} : {getPeriodTime}
        </Typography>
        <Typography className={classes.padding} />

        <Typography>
          {t('IStatus')} : {getStatuses}
        </Typography>
        <Typography className={classes.buttonContainer}>
          <ActionButton
            startIcon={<ReorderIcon />}
            color='secondary'
            onClick={() => history.push(getNoticeListPath())}
          >
            {t('IReadList')}
          </ActionButton>
        </Typography>
      </div>
    </CardWithTitle>
  )
}
