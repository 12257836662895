import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {getPublicFile} from 'helpers/envHelper'
import {makeStyles} from '@material-ui/core'
import HealthCenterReportStyle from 'pages/HealthCenter/Report/Style'
import WrapElement from 'pages/HealthCenter/Report/Elements/WrapElement'
import PercentBarCardV2 from 'pages/HealthCenter/Report/Elements/PercentBarCardV2'
import Analysis from 'pages/HealthCenter/Report/Elements/Analysis'
import PercentBarCardV3 from 'pages/HealthCenter/Report/BrainFunctionalConnectivity/Elements/PercentBarCardV3'
import PercentBarCardV4 from 'pages/HealthCenter/Report/BrainFunctionalConnectivity/Elements/PercentBarCardV4'

const useStyles = makeStyles(HealthCenterReportStyle)
const getHealthReportReportImage = getPublicFile('images/health_center_report')
export interface BrainFunctionalConnectivityProps {
  partOneImg: string
  partTwoImg: string
  partThreeImg: string
  brainConnectivityTotalScore: number
  brainConnectivityBetweenLobesScore: {
    vertical: {
      left: number
      right: number
    }
    horizontal: number
  } | null
  brainConnectivityByLobeScore: {
    frontal: {
      left: number
      right: number
    }
    pariocci: {
      left: number
      right: number
    }
  } | null
}

const BrainFunctionalConnectivity = ({
  partOneImg,
  partTwoImg,
  partThreeImg,
  brainConnectivityTotalScore,
  brainConnectivityByLobeScore,
  brainConnectivityBetweenLobesScore,
}: BrainFunctionalConnectivityProps) => {
  const {t} = useTranslation()
  const classes = useStyles()

  const getNumber = (value: number | string) => {
    if (!Number.isNaN(Number(value))) return Math.floor(Number(value))
    return 0
  }

  const getSeverity = (value = 0) => {
    if (value < 30) {
      return t('ILow')
    }
    if (value > 70) {
      return t('IHigh')
    }
    return t('ICommon')
  }
  const getText = (value = 0, key: string) => {
    if (value < 30) {
      return t(`IBot${key}`, {
        value: getNumber(value),
      })
    }
    if (value > 70) {
      return t(`ITop${key}`, {
        value: getNumber(value),
      })
    }
    return t(`IMid${key}`, {
      value: getNumber(value),
    })
  }

  const getTextV2 = (left = 0, right = 0, key = 'Title') => {
    if (left < 30 && right < 30) {
      return `IAnalysisResultsDes${key}Case3`
    }
    if (left > 70 && right > 70) {
      return `IAnalysisResultsDes${key}Case2`
    }
    if (left > 70 && right < 70) {
      return `IAnalysisResultsDes${key}Case4`
    }
    if (left < 70 && right > 70) {
      return `IAnalysisResultsDes${key}Case5`
    }
    return `IAnalysisResultsDes${key}Case1`
  }

  const getTextV3 = (left = 0, right = 0, key = 'Title') => {
    const average = Math.abs(left - right)

    /// ///  Difference ≤ 10 /// ///
    if (average <= 10) {
      // 좌 OR 우 > 70
      if (left > 70 || right > 70) {
        return `IAnalysisResultsDes${key}Case2`
      }

      // 좌 OR 우 < 30
      if (left < 30 || right < 30) {
        return `IAnalysisResultsDes${key}Case3`
      }
      // 하기 조건 이외
      return `IAnalysisResultsDes${key}Case1`
    }

    /// /// Difference > 10 /// ///
    // 좌 > 우
    if (left > right) {
      // 좌 and 우 > 70
      if (left > 70 && right > 70) {
        return `IAnalysisResultsDes${key}Case2`
      }
      // 하기 조건 이외
      return `IAnalysisResultsDes${key}Case4` // 왼쪽이 크다
    }

    // 좌 < 우
    if (left < right) {
      //  좌 and 우 > 70
      if (left > 70 && right > 70) {
        return `IAnalysisResultsDes${key}Case2`
      }
      // 하기 조건 이외
      return `IAnalysisResultsDes${key}Case5` // 오른쪽이 크다
    }

    // 좌 = 우
    return ''
  }

  const textPart1Title = useMemo(() => {
    return getText(
      brainConnectivityTotalScore,
      'OverallRecoveryAnalysisResultsTitle',
    )
  }, [brainConnectivityTotalScore])

  const textPart1Text = useMemo(() => {
    return getText(
      brainConnectivityTotalScore,
      'OverallRecoveryAnalysisResultsText',
    )
  }, [brainConnectivityTotalScore])

  const textPart2Title1 = useMemo(() => {
    const key = getTextV3(
      getNumber(brainConnectivityBetweenLobesScore?.vertical?.left ?? 0),
      getNumber(brainConnectivityBetweenLobesScore?.vertical?.right ?? 0),
    )
    return t(key, {
      left: getNumber(brainConnectivityBetweenLobesScore?.vertical?.left ?? 0),
      right: getNumber(
        brainConnectivityBetweenLobesScore?.vertical?.right ?? 0,
      ),
    })
  }, [brainConnectivityBetweenLobesScore?.vertical])

  const textPart2Text1 = useMemo(() => {
    const key = getTextV3(
      getNumber(brainConnectivityBetweenLobesScore?.vertical?.left ?? 0),
      getNumber(brainConnectivityBetweenLobesScore?.vertical?.right ?? 0),
      'Text',
    )
    return t(key, {
      left: getNumber(brainConnectivityBetweenLobesScore?.vertical?.left ?? 0),
      right: getNumber(
        brainConnectivityBetweenLobesScore?.vertical?.right ?? 0,
      ),
    })
  }, [brainConnectivityBetweenLobesScore?.vertical])

  const textPart2Title2 = useMemo(() => {
    return getText(
      brainConnectivityBetweenLobesScore?.horizontal ?? 0,
      'LeftRightBrainAnalysisResultsTitle',
    )
  }, [brainConnectivityBetweenLobesScore?.horizontal])

  const textPart2Text2 = useMemo(() => {
    return getText(
      brainConnectivityBetweenLobesScore?.horizontal ?? 0,
      'LeftRightBrainAnalysisResultsText',
    )
  }, [brainConnectivityBetweenLobesScore?.horizontal])

  const textPart3Title1 = useMemo(() => {
    return getText(
      brainConnectivityByLobeScore?.frontal?.left ?? 0,
      'LeftFrontTitle',
    )
  }, [brainConnectivityByLobeScore?.frontal?.left])
  const textPart3Text1 = useMemo(() => {
    return getText(
      brainConnectivityByLobeScore?.frontal?.left ?? 0,
      'LeftFrontText',
    )
  }, [brainConnectivityByLobeScore?.frontal?.left])

  const textPart3Title2 = useMemo(() => {
    return getText(
      brainConnectivityByLobeScore?.frontal?.right ?? 0,
      'RightFrontTitle',
    )
  }, [brainConnectivityByLobeScore?.frontal?.right])
  const textPart3Text2 = useMemo(() => {
    return getText(
      brainConnectivityByLobeScore?.frontal?.right ?? 0,
      'RightFrontText',
    )
  }, [brainConnectivityByLobeScore?.frontal?.right])

  const textPart3Title3 = useMemo(() => {
    return getText(
      brainConnectivityByLobeScore?.pariocci?.left ?? 0,
      'LeftRearTitle',
    )
  }, [brainConnectivityByLobeScore?.pariocci?.left])
  const textPart3Text3 = useMemo(() => {
    return getText(
      brainConnectivityByLobeScore?.pariocci?.left ?? 0,
      'LeftRearText',
    )
  }, [brainConnectivityByLobeScore?.pariocci?.left])

  const textPart3Title4 = useMemo(() => {
    return getText(
      brainConnectivityByLobeScore?.pariocci?.right ?? 0,
      'RightRearTitle',
    )
  }, [brainConnectivityByLobeScore?.pariocci?.right])
  const textPart3Text4 = useMemo(() => {
    return getText(
      brainConnectivityByLobeScore?.pariocci?.right ?? 0,
      'RightRearText',
    )
  }, [brainConnectivityByLobeScore?.pariocci?.right])
  const showIndex = true

  return (
    <div className={classes.brainBasic}>
      <div className={classes.brainConnectivityTitle}>
        {t('IBrainConnectivityTitlePart1')}
        <span>{t('IBrainConnectivityTitlePart2')}</span>
        {t('IBrainConnectivityTitlePart3')}
      </div>
      <div className={classes.brainConnectivityDes}>
        <p>{t('IBrainConnectivityDesPart1')}</p>
        <p>
          <p>{t('IBrainConnectivityDesPart2')}</p>
        </p>
        <p>
          <p>{t('IBrainConnectivityDesPart3')}</p>
        </p>
        <p>
          <p>{t('IBrainConnectivityDesPart4')}</p>
        </p>
      </div>
      <div className={classes.brainConnectivityDes} style={{marginBottom: 60}}>
        <p>{t('IBrainConnectivityDes1')}</p>
        <div>
          <ul>
            <li>{t('IBrainConnectivityDesLi1')}</li>
            <li>{t('IBrainConnectivityDesLi2')}</li>
            <li>{t('IBrainConnectivityDesLi3')}</li>
          </ul>
        </div>
        <p>{t('IBrainConnectivityDes2')}</p>
      </div>
      <WrapElement number={1} text={t('IBrainConnectivityAnalysisResult')}>
        <div className={classes.contentCard}>
          <div className={classes.cardLeftContent}>
            <img
              className={classes.cardLeftImg}
              src={partOneImg}
              alt='Contour'
            />
            <div style={{width: '100%'}}>
              <div style={{height: 24}} />
              {/* <PercentChangeBarCard
                headerName={t('IBrainConnectivityOverallRecovery')}
                backgroundColor={formatColorLeft(brainConnectivityTotalScore)}
                textRound={formatTextRoundLeft(brainConnectivityTotalScore)}
                percentage={brainConnectivityTotalScore ?? 0}
                index={5}
                top={t('ILowness')}
                middle={t('ICommonlyHeart')}
                bottom={t('IHeight')}
                showHz={false}
              /> */}
              <PercentBarCardV2
                index={5}
                headerName={t('IBrainConnectivityOverallRecovery')}
                percentage={getNumber(brainConnectivityTotalScore ?? 0)}
                top={t('ILowness')}
                middle={t('ICommonlyHeart')}
                bottom={t('IHeight')}
                showIndex={showIndex}
              />
            </div>
          </div>
          <div className={classes.cardRightContent}>
            <p className={classes.textBold}>
              {t('IBrainConnectivityOverallRecoveryTitle')}
            </p>
            <Analysis>
              <div className={classes.connectivityAnalysis}>
                <p className={classes.textBold}>{textPart1Title}</p>
                <p className={classes.textP}>{textPart1Text}</p>
                <ul>
                  <li>{textPart2Title1}</li>
                  <li>
                    {t('IBrainConnectivityOverallRecoveryLi2', {
                      horizontal: getNumber(
                        brainConnectivityBetweenLobesScore?.horizontal ?? 0,
                      ),
                      severity: getSeverity(
                        brainConnectivityBetweenLobesScore?.horizontal ?? 0,
                      ),
                    })}
                  </li>
                  <li>
                    {t('IBrainConnectivityOverallRecoveryLi3', {
                      left: getNumber(
                        brainConnectivityByLobeScore?.frontal?.left ?? 0,
                      ),
                      severity: getSeverity(
                        brainConnectivityByLobeScore?.frontal?.left ?? 0,
                      ),
                    })}
                  </li>
                  <li>
                    {t('IBrainConnectivityOverallRecoveryLi4', {
                      right: getNumber(
                        brainConnectivityByLobeScore?.frontal?.right ?? 0,
                      ),
                      severity: getSeverity(
                        brainConnectivityByLobeScore?.frontal?.right ?? 0,
                      ),
                    })}
                  </li>
                  <li>
                    {t('IBrainConnectivityOverallRecoveryLi5', {
                      left: getNumber(
                        brainConnectivityByLobeScore?.pariocci?.left ?? 0,
                      ),
                      severity: getSeverity(
                        brainConnectivityByLobeScore?.pariocci?.left ?? 0,
                      ),
                    })}
                  </li>
                  <li>
                    {t('IBrainConnectivityOverallRecoveryLi6', {
                      right: getNumber(
                        brainConnectivityByLobeScore?.pariocci?.right ?? 0,
                      ),
                      severity: getSeverity(
                        brainConnectivityByLobeScore?.pariocci?.right ?? 0,
                      ),
                    })}
                  </li>
                </ul>
              </div>
            </Analysis>
          </div>
        </div>
      </WrapElement>

      <WrapElement number={2} text={t('IBrainConnectivityBetweenBrain')}>
        <div>
          <div className={classes.brainConnectivityBrainRegionTabs2}>
            <div
              className={`${classes.cardLeftContent} ${classes.cardLeftContentAdditional}`}
            >
              <img
                className={classes.cardLeftImg}
                src={getHealthReportReportImage('brainRegions.webp')}
                alt='Contour'
                style={{width: '285px'}}
              />
            </div>
            <div className={classes.percentBarCardV3}>
              <PercentBarCardV3
                index={1}
                headerName={t('ILeftBrain')}
                percentage={getNumber(
                  brainConnectivityBetweenLobesScore?.vertical?.left ?? 0,
                )}
                top={t('ILowness')}
                middle={t('ICommonlyHeart')}
                bottom={t('IHeight')}
              />
              <PercentBarCardV3
                index={2}
                headerName={t('IRightBrain')}
                percentage={getNumber(
                  brainConnectivityBetweenLobesScore?.vertical?.right ?? 0,
                )}
                top={t('ILowness')}
                middle={t('ICommonlyHeart')}
                bottom={t('IHeight')}
              />
              <PercentBarCardV3
                index={3}
                headerName={t('ILeftRightBrain')}
                percentage={getNumber(
                  brainConnectivityBetweenLobesScore?.horizontal ?? 0,
                )}
                top={t('ILowness')}
                middle={t('ICommonlyHeart')}
                bottom={t('IHeight')}
              />
            </div>
          </div>
          <div
            className={`${classes.cardRightContent} ${classes.cardRightContentAdditional}`}
          >
            <div>
              <p className={classes.textBold}>
                {t('IBrainConnectivityAnalysisResultsTitle1')}
              </p>
              <br />
              <p className={classes.textBlack}>
                {t('IBrainConnectivityAnalysisResultsTitle2')}
              </p>
            </div>
            <Analysis>
              <div className={classes.connectivityBrain}>
                <p className={classes.textBold}>{textPart2Title1}</p>
                <p className={classes.textP} style={{marginBottom: 16}}>
                  {textPart2Text1}
                </p>

                <p className={classes.textBold}>{textPart2Title2}</p>
                <p className={classes.textP}>{textPart2Text2}</p>
              </div>
            </Analysis>
          </div>
        </div>
      </WrapElement>
      <WrapElement number={3} text={t('IBrainConnectivityBrainRegion')}>
        <div>
          <div className={classes.brainConnectivityBrainRegionTabs3}>
            <div
              className={`${classes.cardLeftContent} ${classes.cardLeftContentAdditionalV1}`}
            >
              <img
                className={classes.cardLeftImg}
                src={getHealthReportReportImage('fourDivisionsBase.webp')}
                alt='Contour'
                style={{width: '285px'}}
              />
            </div>
            <div className={classes.percentBarCar}>
              <PercentBarCardV4
                index={1}
                headerName={t('ILeftFront')}
                percentage={getNumber(
                  brainConnectivityByLobeScore?.frontal?.left ?? 0,
                )}
                top={t('ILowness')}
                middle={t('ICommonlyHeart')}
                bottom={t('IHeight')}
              />
              <PercentBarCardV4
                index={3}
                headerName={t('IRightFront')}
                percentage={getNumber(
                  brainConnectivityByLobeScore?.frontal?.right ?? 0,
                )}
                top={t('ILowness')}
                middle={t('ICommonlyHeart')}
                bottom={t('IHeight')}
              />
              <PercentBarCardV4
                index={2}
                headerName={t('ILeftRear')}
                percentage={getNumber(
                  brainConnectivityByLobeScore?.pariocci?.left ?? 0,
                )}
                top={t('ILowness')}
                middle={t('ICommonlyHeart')}
                bottom={t('IHeight')}
              />

              <PercentBarCardV4
                index={4}
                top={t('ILowness')}
                headerName={t('IRightRear')}
                percentage={getNumber(
                  brainConnectivityByLobeScore?.pariocci?.right ?? 0,
                )}
                middle={t('ICommonlyHeart')}
                bottom={t('IHeight')}
              />
            </div>
          </div>

          <div
            className={`${classes.cardRightContent} ${classes.cardRightContentAdditional}`}
          >
            <div>
              <p className={classes.textBold}>
                {t('IBrainConnectivityBrainRegionTitle')}
              </p>
              <br />
              <p className={classes.textBlack}>
                {t('IBrainConnectivityAnalysisResultsTitle2')}
              </p>
            </div>

            <Analysis>
              <div className={classes.connectivityBrain}>
                <p className={classes.textBold}>{textPart3Title1}</p>
                <p className={classes.textP} style={{marginBottom: 16}}>
                  {textPart3Text1}
                </p>

                <p className={classes.textBold}>{textPart3Title2}</p>
                <p className={classes.textP} style={{marginBottom: 16}}>
                  {textPart3Text2}
                </p>

                <p className={classes.textBold}>{textPart3Title3}</p>
                <p className={classes.textP} style={{marginBottom: 16}}>
                  {textPart3Text3}
                </p>

                <p className={classes.textBold}>{textPart3Title4}</p>
                <p className={classes.textP}>{textPart3Text4}</p>
              </div>
            </Analysis>
          </div>
        </div>
      </WrapElement>
    </div>
  )
}

export default BrainFunctionalConnectivity
