import React, {useEffect, useRef, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {Controller, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import * as yup from 'yup'
import {SchemaOf} from 'yup'
import {yupResolver} from '@hookform/resolvers/yup'
import {makeStyles} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import {Button} from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import ReorderIcon from '@material-ui/icons/Reorder'
import ActionButton from 'components/atoms/Buttons/ActionButton'
import GridContainer from 'components/Grid/GridContainer'
import {useAppDispatch} from 'hooks'
import {
  createSaleAppNotice,
  deleteSaleAppNotice,
  updateSaleAppNotice,
} from 'features/sales/saleAppNoticeSlice'
import useNoticeConfirm from 'features/modal/useNoticeConfirm'
import {
  SaleAppNotice,
  SaleAppNoticeCreateRequest,
  SaleAppNoticeLink,
  uploadFileSaleAppNoticeApi,
} from 'api/salesNoticeApi'
import RouteConstant from 'constants/RouteConstant'
import {getFileType} from 'helpers/fileHelper'
import {useBoldInputStyle} from 'components/common/useBoldInputStyle'
import {styles} from 'components/molcules/SalesPages/SalesAppNoticePage/SaleAppNoticePageStyle'

const useStyles = makeStyles(styles)

export interface SalesAppGeneralNotice {
  koTitle: string
  koContent: string
  enTitle: string
  enContent: string
}

export default function CreateGeneralNotice({
  initialData,
  noticeId,
}: {
  initialData?: SaleAppNotice
  noticeId: number
}) {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const history = useHistory()
  const classes = useStyles()
  const inputClasses = useBoldInputStyle()
  const {onOpen: onNoticeDialogOpen} = useNoticeConfirm()

  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const [fileName, setFileName] = useState<string[] | null>(null)
  const [files, setFiles] = useState<FileList | null>(null)
  const [isChangeFile, setIsChangeFile] = useState<boolean>(true)
  const [disabled, setDisabled] = useState<boolean>(false)

  const SaleAppNoticeCreateSchema: SchemaOf<SalesAppGeneralNotice> = yup
    .object()
    .shape({
      koTitle: yup
        .string()
        .required(t('IRequiredTitle'))
        .max(150, t('IRequiredMaxlength', {max: 150})),
      koContent: yup
        .string()
        .required(t('IRequiredContent'))
        .max(3000, t('IRequiredMaxlength', {max: 3000})),
      enTitle: yup
        .string()
        .required(t('IRequiredTitle'))
        .max(150, t('IRequiredMaxlength', {max: 150})),
      enContent: yup
        .string()
        .required(t('IRequiredContent'))
        .max(3000, t('IRequiredMaxlength', {max: 3000})),
    })
    .defined()
  const {
    control,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm<SalesAppGeneralNotice>({
    resolver: yupResolver(SaleAppNoticeCreateSchema),
  })

  const uploadFilesSequentially = async (
    fileList: FileList,
  ): Promise<SaleAppNoticeLink[]> => {
    const filesArray: File[] = Array.from(fileList)
    return Promise.all(
      filesArray.map((file: File, index: number) =>
        uploadFileSaleAppNoticeApi(file).then(
          (resFile) =>
            ({
              link: resFile?.data ?? '',
              name: file.name,
              order: index,
              type: getFileType(file),
            } as SaleAppNoticeLink),
        ),
      ),
    )
  }

  const onSubmit = async (data: SalesAppGeneralNotice) => {
    let links: SaleAppNoticeLink[] = []
    if (files && isChangeFile) {
      links = await uploadFilesSequentially(files)
    }
    const payload: SaleAppNoticeCreateRequest = {
      type: 'general',
      display: false,
      links: !isChangeFile ? initialData?.links ?? [] : links,
      attribute: null,
      translations: [
        {
          content: data.koContent,
          lang: 'ko',
          title: data.koTitle,
        },
        {
          content: data.enContent,
          lang: 'en',
          title: data.enTitle,
        },
      ],
    }
    // Call API create notice
    if (noticeId) {
      const res = await dispatch(
        updateSaleAppNotice({data: payload, noticeId}),
      ).unwrap()
      if (res.success) history.push(RouteConstant.SALES_APP_NOTICE.path)
    } else {
      const res = await dispatch(createSaleAppNotice(payload)).unwrap()
      if (res.success) history.push(RouteConstant.SALES_APP_NOTICE.path)
    }
  }

  const getFile = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {files} = event.target
    if (files && files.length > 0) {
      setFileName(Array.from(files).map((fileName) => fileName.name))
      setFiles(files)
      setIsChangeFile(true)
    }
  }

  const handleDelete = () => {
    onNoticeDialogOpen({
      title: t('INoticeTitle'),
      message: t('IDeleteConfirmMessage'),
    }).then(async (result) => {
      if (result.payload) await dispatch(deleteSaleAppNotice(noticeId))
      history.push(RouteConstant.SALES_APP_NOTICE.path)
    })
  }

  useEffect(() => {
    if (initialData) {
      const {translations, links} = initialData
      setValue('koTitle', translations[0].title)
      setValue('koContent', translations[0].content)
      setValue('enTitle', translations[1].title)
      setValue('enContent', translations[1].content)
      setFileName(links.map((item) => item.name))
      if (links.length) {
        setIsChangeFile(false)
      }
      setDisabled(true)
    }
  }, [initialData])

  return (
    <div className={classes.genalNoticeTab}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.contentsTab}>
          <GridContainer
            item
            className={`${classes.gridContainer} ${classes.marginTop10}`}
          >
            <Grid item xs={2} className={classes.titleItem}>
              {t('ITitleKo')} :
            </Grid>
            <Grid item lg={8} xs={9}>
              <Controller
                name='koTitle'
                control={control}
                render={({field}) => (
                  <input
                    disabled={disabled}
                    className={inputClasses.input}
                    {...field}
                    style={{width: '100%'}}
                  />
                )}
              />
            </Grid>
          </GridContainer>

          <GridContainer item className={classes.gridContainer}>
            <Grid item xs={2} />
            <Grid item lg={8} xs={9}>
              <p className={classes.errorText}>
                {errors.koTitle && errors.koTitle?.message}
              </p>
            </Grid>
          </GridContainer>
          <GridContainer
            item
            className={`${classes.gridContainer} ${classes.marginTop10}`}
          >
            <Grid item xs={2} className={classes.titleItem}>
              {t('IContentKo')} : :
            </Grid>
            <Grid item lg={8} xs={9}>
              <Controller
                name='koContent'
                control={control}
                render={({field}) => (
                  <textarea
                    disabled={disabled}
                    {...field}
                    rows={10}
                    className={inputClasses.textarea}
                  />
                )}
              />
            </Grid>
          </GridContainer>
          <GridContainer item className={classes.gridContainer}>
            <Grid item xs={2} />
            <Grid item lg={8} xs={9}>
              <p className={classes.errorText}>
                {errors.koContent && errors.koContent?.message}
              </p>
            </Grid>
          </GridContainer>

          <GridContainer
            item
            className={`${classes.gridContainer} ${classes.marginTop10}`}
          >
            <Grid item xs={2} className={classes.titleItem}>
              {t('ITitleEn')} :
            </Grid>
            <Grid item lg={8} xs={9}>
              <Controller
                name='enTitle'
                control={control}
                render={({field}) => (
                  <input
                    disabled={disabled}
                    className={inputClasses.input}
                    {...field}
                    style={{width: '100%'}}
                  />
                )}
              />
            </Grid>
          </GridContainer>
          <GridContainer item className={classes.gridContainer}>
            <Grid item xs={2} />
            <Grid item lg={8} xs={9}>
              <p className={classes.errorText}>
                {errors.enTitle && errors.enTitle?.message}
              </p>
            </Grid>
          </GridContainer>

          <GridContainer
            item
            className={`${classes.gridContainer} ${classes.marginTop10}`}
          >
            <Grid item xs={2} className={classes.titleItem}>
              {t('IContentEn')} :
            </Grid>
            <Grid item lg={8} xs={9}>
              <Controller
                name='enContent'
                control={control}
                render={({field}) => (
                  <textarea
                    disabled={disabled}
                    {...field}
                    rows={10}
                    className={inputClasses.textarea}
                  />
                )}
              />
            </Grid>
          </GridContainer>
          <GridContainer item className={classes.gridContainer}>
            <Grid item xs={2} />
            <Grid item lg={8} xs={9}>
              <p className={classes.errorText}>
                {errors.enContent && errors.enContent?.message}
              </p>
            </Grid>
          </GridContainer>

          <GridContainer
            item
            className={`${classes.gridContainer} ${classes.marginTop10}`}
          >
            <Grid item xs={2} className={classes.titleItem}>
              {t('IAppNoticeAttachedFileButton')} :
            </Grid>
            <Grid item lg={8} xs={9}>
              <Button
                variant='contained'
                disableElevation
                color='default'
                size='small'
                className={classes.minWidth120}
                onClick={getFile}
                disabled={disabled}
              >
                {t('IAttachFile')}
              </Button>
              {fileName && (
                <span className={classes.fileName}>{fileName.join(', ')}</span>
              )}
              <input
                type='file'
                ref={fileInputRef}
                onChange={handleFileChange}
                style={{display: 'none'}}
                accept='.png, .jpg, .jpeg, .pdf'
              />
            </Grid>
          </GridContainer>
        </div>
        <div className={classes.botContainer}>
          <Grid item xs={9}>
            <div className={classes.buttonContainer}>
              <ActionButton
                startIcon={<ReorderIcon />}
                color='secondary'
                onClick={() =>
                  history.push(RouteConstant.SALES_APP_NOTICE.path)
                }
              >
                {t('IReadList')}
              </ActionButton>
              {!disabled ? (
                <div className={classes.buttonWrap}>
                  <ActionButton
                    color='default'
                    className={classes.backButton}
                    onClick={() =>
                      history.push(RouteConstant.SALES_APP_NOTICE.path)
                    }
                  >
                    {t('ICancel')}
                  </ActionButton>
                  <Typography className={classes.padding} />
                  <ActionButton
                    color='primary'
                    className={classes.backButton}
                    type='submit'
                  >
                    {t('IOk')}
                  </ActionButton>
                </div>
              ) : (
                <div className={classes.buttonWrap}>
                  <Typography className={classes.padding} />
                  <ActionButton
                    color='primary'
                    className={classes.backButton}
                    onClick={() => setDisabled(false)}
                  >
                    {t('IEdit')}
                  </ActionButton>
                  <Typography className={classes.padding} />
                  <ActionButton
                    color='default'
                    className={classes.backButton}
                    onClick={handleDelete}
                  >
                    {t('IDelete')}
                  </ActionButton>
                </div>
              )}
            </div>
          </Grid>
        </div>
      </form>
    </div>
  )
}
