/* eslint-disable*/
import CryptoES from 'crypto-es'
import React from 'react'

export function getAESDecryptValue(
  plainText: string,
  cipherText: string,
  secretKey: string,
): string {
  if (!plainText || !cipherText || !secretKey) {
    return ''
  }
  const retrivedCiphertext = cipherText.replace(/_/g, '/').replace(/-/g, '+')

  // find iv
  const plainDecText = CryptoES.enc.Base64.parse(
    plainText.replace(/_/g, '/').replace(/-/g, '+'),
  )
  const ivs = plainDecText.clone()
  ivs.sigBytes = 16
  ivs.clamp()
  ivs.words.splice(16, 32)

  const base64Dec = CryptoES.enc.Base64.parse(retrivedCiphertext)
  const key = CryptoES.enc.Utf8.parse(secretKey)
  const decrypted = CryptoES.AES.decrypt(
    {
      ciphertext: base64Dec,
    },
    key,
    {
      iv: ivs,
    },
  )
  return decrypted.toString(CryptoES.enc.Utf8)
}

export function stringToHex(str: string) {
  let res = ''
  for (let i = 0; i < str.length; i++) {
    res = res + ('k' + str.charCodeAt(i).toString(16))
  }
  return res
}

export function text2Binary(string: string) {
  return string
    .split('')
    .map(function (char) {
      return char.charCodeAt(0).toString(2)
    })
    .join(' ')
}

function hexToString(str: string) {
  let strarr = str.split('k')
  let res = ''
  for (let i = 0; i < strarr.length; i++) {
    if (strarr[i] && parseInt(strarr[i], 16)) {
      res = res + String.fromCharCode(parseInt(strarr[i], 16))
    }
  }
  return res
}

export const getBinary = (number: number) => {
  let done = false
  let resultInverted = []
  let acc = number
  while (!done) {
    let reminder = acc % 2
    if (acc === 1) {
      done = true
    }
    acc = Math.floor(acc / 2)
    resultInverted.push(reminder)
  }
  return Number(resultInverted.reverse().join(''))
}

export const stringToArrayBuffer = (
  text: string,
  encoding = 'UTF-8',
): Promise<ArrayBuffer> => {
  return new Promise<ArrayBuffer>((resolve, reject) => {
    const blob = new Blob([text], {type: `text/plain;charset=${encoding}`})
    const reader = new FileReader()
    reader.onload = (evt) => {
      if (evt.target) {
        resolve(evt.target.result as ArrayBuffer)
      } else {
        reject(new Error('Could not convert string to array!'))
      }
    }
    reader.readAsArrayBuffer(blob)
  })
}

export function createBinaryString(nMask: number) {
  // nMask must be between -2147483648 and 2147483647
  if (nMask > 2 ** 31 - 1)
    throw "number too large. number shouldn't be > 2**31-1" //added
  if (nMask < -1 * 2 ** 31)
    throw "number too far negative, number shouldn't be < -(2**31)" //added
  for (
    var nFlag = 0, nShifted = nMask, sMask = '';
    nFlag < 32;
    nFlag++, sMask += String(nShifted >>> 31), nShifted <<= 1
  );
  sMask = sMask.replace(/\B(?=(.{8})+(?!.))/g, ' ') // added
  return sMask
}

export function encodeStringToBase64(text: string) {
  const wordArray = CryptoES.enc.Utf8.parse(text)
  const encoded = CryptoES.enc.Base64.stringify(wordArray)
  return encoded
}

export function decodeStringFromBase64(text: string) {
  const parseWord = CryptoES.enc.Base64.parse(text)
  const decoded = parseWord.toString(CryptoES.enc.Utf8)
  return decoded
}
