import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import {makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CardWithTitle from 'components/atoms/CardWithTitle'
import CreateMaintenanceNotice from 'components/molcules/SalesPages/SalesAppNoticePage/CreateMaintenanceNotice'
import CreateGeneralNotice from 'components/molcules/SalesPages/SalesAppNoticePage/CreateGeneralNotice'
import {getSalesAppNoticeDetailApi, SaleAppNotice} from 'api/salesNoticeApi'
import {TAB} from 'pages/SalesPages/SalesAppNoticePage/constants'
import {styles} from 'components/molcules/SalesPages/SalesAppNoticePage/SaleAppNoticePageStyle'

const useStyles = makeStyles(styles)

export default function SalesAppNoticePage() {
  const {t} = useTranslation()
  const {id: noticeId} = useParams<{id: string}>()
  const classes = useStyles()
  const [tabActive, setTabActive] = useState<number>(1)
  const [maintenanceData, setMaintenanceData] = useState<
    SaleAppNotice | undefined
  >()
  const [generalData, setGeneralData] = useState<SaleAppNotice | undefined>()

  useEffect(() => {
    const fetchDataDetailNotice = async () => {
      if (Number(noticeId)) {
        const data = await getSalesAppNoticeDetailApi(Number(noticeId))
        if (data.success && data.data.type === 'maintenance') {
          setMaintenanceData(data.data)
          setTabActive(1)
        } else if (data.success && data.data.type === 'general') {
          setGeneralData(data.data)
          setTabActive(2)
        }
      }
    }
    if (noticeId) fetchDataDetailNotice()
  }, [noticeId])

  return (
    <div>
      <CardWithTitle title={t('IAppNotice')}>
        <Grid className={classes.navContainer} xs={12}>
          <Grid xs={9} className={classes.tabs}>
            {TAB.map((tab) => (
              <Grid
                className={`${classes.tab} ${
                  tabActive === tab.index && classes.tabActive
                }`}
                onClick={() => {
                  if (!noticeId) setTabActive(tab.index)
                }}
                aria-hidden='true'
                key={tab.index}
                xs={6}
              >
                {t(tab.name)}
              </Grid>
            ))}
          </Grid>
        </Grid>
        <div className={classes.contentsTab}>
          {tabActive === 1 && (
            <CreateMaintenanceNotice
              initialData={maintenanceData}
              noticeId={parseInt(noticeId, 10)}
            />
          )}
          {tabActive === 2 && (
            <CreateGeneralNotice
              initialData={generalData}
              noticeId={parseInt(noticeId, 10)}
            />
          )}
        </div>
      </CardWithTitle>
      <Typography className={classes.padding} />
    </div>
  )
}
