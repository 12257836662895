import React, {useEffect, useMemo} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {useAppDispatch} from 'hooks'
import * as yup from 'yup'
import {yupResolver} from '@hookform/resolvers/yup'
import useAuth from 'features/auth/useAuth'
import Button from '@material-ui/core/Button'
import CardContent from '@material-ui/core/CardContent'
import Dialog from '@material-ui/core/Dialog'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import {QnaEditRequest} from 'api/qnaApi'
import Card from 'components/atoms/Card'
import {useBoldInputStyle} from 'components/common/useBoldInputStyle'
import {
  closeQnaEditDialog,
  selectQnaEditDialogOpen,
} from 'features/modal/modalSlice'
import useNoticeConfirm from 'features/modal/useNoticeConfirm'
import {
  addQnaAnswerAction,
  fetchQnaDetailInfoAction,
  fetchQnaListAction,
  selectQna,
  updateQnaAction,
} from 'features/qna/qnaSlice'
import SelectQnaType from 'pages/CommunityPages/QuestionPage/QuestionEditDialog/SelectQnaType'
import useStyles from 'components/Dialog/User/Style'

interface Props {
  id: number
}

function checkAuthority(Authority: string): boolean {
  if (Authority === 'ROLE_COMPANY_SALES' || Authority === 'ROLE_COMPANY_CS') {
    return true
  }
  return false
}

export default function QuestionEditDialog(prop: Props) {
  const {onOpen: onNoticeDialogOpen} = useNoticeConfirm()
  const {t} = useTranslation()
  const {id} = prop
  const classes = useStyles()
  const dispatch = useAppDispatch()
  const qnaEdit = useSelector(selectQnaEditDialogOpen)
  const qnaData = useSelector(selectQna)
  const inputClasses = useBoldInputStyle()
  const {user: currentUser} = useAuth()

  const AddSchema = yup.object().shape({
    title: yup.string().min(1).max(50, t('IValidationQnaTitle')),
    content: yup
      .string()
      .required()
      .min(1)
      .max(600, t('IValidationQnaContent')),
  })

  const {
    handleSubmit,
    formState: {errors},
    control,
    reset,
    setValue,
  } = useForm<QnaEditRequest>({
    resolver: yupResolver(AddSchema),
  })

  const isAdmin = useMemo(
    () => checkAuthority(currentUser?.authority ?? ''),
    [currentUser?.authority],
  )

  const handleClose = () => {
    dispatch(closeQnaEditDialog())
  }

  const onSubmit = async (data: QnaEditRequest) => {
    data.id = id
    const submitOrgInfo = Object.fromEntries(
      Object.entries(data).filter(([v]) => v != null),
    )
    if (!data) handleClose()
    let res: any
    if (!isAdmin) {
      res = await dispatch(updateQnaAction(submitOrgInfo))
    } else {
      res = await dispatch(addQnaAnswerAction({id, content: data.content}))
    }
    await dispatch(
      fetchQnaListAction({
        paging: {
          page: 0,
          size: 10,
        },
      }),
    )
    if (!res?.error) {
      handleClose()
      reset()
    }
  }

  useEffect(() => {
    if (id && qnaEdit) {
      dispatch(fetchQnaDetailInfoAction(id))
    }
  }, [id, qnaEdit])

  useEffect(() => {
    setValue('title', qnaData.qnaData?.title)
    setValue('qnaType', qnaData.qnaData?.qnaType)
    setValue(
      'content',
      isAdmin ? qnaData?.qnaData?.answer : qnaData?.qnaData?.content,
    )
  }, [qnaData, isAdmin])

  return (
    <div>
      <Dialog open={qnaEdit} maxWidth='md' onClose={handleClose}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card className={classes.cardWrap}>
            <div className={classes.root}>
              {/* 상단 타이틀 컨테이너  */}
              <div className={classes.longTitleContainer}>
                <div className={classes.containerTitle}>
                  <div className={classes.closeButtonWrap}>
                    <IconButton
                      color='secondary'
                      aria-label='favorite'
                      className={classes.closeButton}
                      onClick={() => {
                        onNoticeDialogOpen({
                          title: '',
                          message: t('ICancleConfirm'),
                        }).then((result) => {
                          if (result.payload) {
                            handleClose()
                          }
                        })
                      }}
                    >
                      <CloseIcon className={classes.closeIcon} />
                    </IconButton>
                  </div>
                  {!isAdmin && (
                    <Typography variant='h6' className={classes.title}>
                      {t('IInquireUpdate')}
                    </Typography>
                  )}
                  {isAdmin && (
                    <Typography variant='h6' className={classes.title}>
                      {t('IInquireAnswer')}
                    </Typography>
                  )}
                </div>
              </div>
              <Divider className={classes.titleDivider} />
              <Typography className={classes.padding} />
              <Grid item xs={12} className={classes.scroll}>
                <CardContent className={classes.scroll}>
                  <div className={classes.contentContainer}>
                    <div>
                      <div className={classes.inputContainer}>
                        <div className={inputClasses.labelWrapContainer}>
                          <p className={inputClasses.inputLabel}>
                            {t('IInquireType')}
                          </p>
                        </div>
                        <div style={{width: 750}}>
                          <Controller
                            name='qnaType'
                            control={control}
                            render={({field}) => (
                              <SelectQnaType readOnly={isAdmin} {...field} />
                            )}
                          />
                        </div>
                      </div>

                      <Grid item xs={12}>
                        <Typography className={classes.padding} />
                      </Grid>

                      <div className={classes.inputContainer}>
                        <div className={inputClasses.labelWrapContainer}>
                          <p className={inputClasses.inputLabel}>
                            {t('IInquireTitle')}
                          </p>
                        </div>
                        <div>
                          <Controller
                            name='title'
                            control={control}
                            rules={{required: false}}
                            render={({field}) => (
                              <input
                                id='title'
                                style={{width: '100%'}}
                                defaultValue={qnaData.qnaData?.title ?? ''}
                                disabled={isAdmin}
                                className={inputClasses.input}
                                {...field}
                              />
                            )}
                          />
                          {!isAdmin && (
                            <div className={classes.errorText}>
                              {errors.title && t(errors.title.message ?? '')}
                            </div>
                          )}
                        </div>
                      </div>
                      <Grid item xs={12}>
                        <Typography className={classes.padding} />
                      </Grid>

                      {isAdmin && (
                        <div className={classes.inputContainer}>
                          <div className={inputClasses.labelWrapContainer}>
                            <p className={inputClasses.inputLabel}>
                              {t('IInquireContents')}
                            </p>
                          </div>
                          <div>
                            <textarea
                              style={{
                                width: '100%',
                                height: 250,
                                resize: 'none',
                              }}
                              rows={10}
                              defaultValue={qnaData.qnaData?.content ?? ''}
                              disabled={isAdmin}
                              className={inputClasses.input}
                            />
                          </div>
                        </div>
                      )}

                      <div className={classes.inputContainer}>
                        <div className={inputClasses.labelWrapContainer}>
                          <p className={inputClasses.inputLabel}>
                            {isAdmin
                              ? t('IQnaAnswerContents')
                              : t('IInquireContents')}
                          </p>
                        </div>
                        <div>
                          <Controller
                            name='content'
                            control={control}
                            render={({field}) => (
                              <textarea
                                style={{
                                  width: '100%',
                                  height: 250,
                                  resize: 'none',
                                }}
                                rows={10}
                                defaultValue={
                                  (isAdmin
                                    ? qnaData?.qnaData?.answer
                                    : qnaData?.qnaData?.content) ?? ''
                                }
                                className={inputClasses.input}
                                {...field}
                              />
                            )}
                          />
                          <div className={classes.errorText}>
                            {errors.content && t(errors.content.message ?? '')}
                          </div>
                        </div>
                      </div>
                      <Grid item xs={12}>
                        <Typography className={classes.padding} />
                      </Grid>

                      {!isAdmin && (
                        <div className={classes.inputContainer}>
                          <div className={inputClasses.labelWrapContainer}>
                            <p className={inputClasses.inputLabel}>
                              {t('IQnaAnswerContents')}
                            </p>
                          </div>
                          <div>
                            <textarea
                              style={{
                                width: '100%',
                                height: 250,
                                resize: 'none',
                              }}
                              rows={10}
                              defaultValue={qnaData.qnaData?.answer ?? ''}
                              disabled={!isAdmin}
                              className={inputClasses.input}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </CardContent>
              </Grid>
              <div className={classes.buttonContainerBgColor}>
                {!isAdmin && (
                  <div className={classes.buttonContainer}>
                    <Button variant='contained' disableElevation type='submit'>
                      {t('IOk')}
                    </Button>
                    {qnaData.qnaData?.confirmedYn === 'N' && (
                      <Typography className={classes.padding} />
                    )}
                    {qnaData.qnaData?.confirmedYn === 'N' && (
                      <Button
                        variant='contained'
                        color='secondary'
                        disableElevation
                        onClick={handleClose}
                        className={classes.okButtonColor}
                      >
                        {t('ICancel')}
                      </Button>
                    )}
                    {qnaData.qnaData?.confirmedYn === 'N' && (
                      <Typography className={classes.padding} />
                    )}
                  </div>
                )}
                {isAdmin && (
                  <div className={classes.buttonContainer}>
                    <Button
                      variant='contained'
                      disableElevation
                      onClick={() => {
                        onNoticeDialogOpen({
                          title: '',
                          message: t('ICancleConfirm'),
                        }).then((result) => {
                          if (result.payload) {
                            handleClose()
                          }
                        })
                      }}
                    >
                      {t('ICancel')}
                    </Button>
                    <Typography className={classes.padding} />
                    <Button
                      variant='contained'
                      color='primary'
                      disableElevation
                      type='submit'
                      className={classes.okButtonColor}
                    >
                      {t('IOk')}
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </Card>
        </form>
      </Dialog>
    </div>
  )
}
