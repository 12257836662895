// 400
export const BadRequestErrorConstant = {
  EXPIRATION_DATE: {
    code: '100001',
    message: 'INoticeExpirationDate',
  },
  REQUIRE_DATE_RANGE: {
    code: '100100',
    message: 'INoticeRequireDateRange',
  },
  INVALID_ORDER_DATE_RANGE: {
    code: '100101',
    message: 'INoticeInvalidOrderDateRange',
  },
  INVALID_PASSWORD: {
    code: '201000',
    message: 'INoticeInvalidPassword',
  },
  UNCHANGED_PASSWORD: {
    code: '201001',
    message: 'INoticeUnChangedPassword',
  },
  REQUIRED_PASSWORD: {
    code: '201002',
    message: 'INoticeRequiredPassword',
  },
  NOT_IN_RECOVERABLE_STATE: {
    code: '300100',
    message: 'INoticeNotInRecoverableState',
  },
  FORMAT_EXCEPTION: {
    code: '300101',
    message: 'INoticeFormatException',
  },
  TOKEN_DECODE_FAIL: {
    code: '400102',
    message: 'INoticeAbnormalApproach',
  },
  MISMATCH_AUTHENTICATION: {
    code: '400301',
    message: 'INoticeMismatchAuthentication',
  },
  ALREADY_CONFIRMED: {
    code: '400300',
    message: 'INoticeAlreadyConfirmed',
  },
  AUTHENTICATION_IS_NOT_COMPLETED_YET: {
    code: '400302',
    message: 'INoticeAuthenticationIsNotCompletedYet',
  },
  MISMATCH_USER: {
    code: '400303',
    message: 'INoticeMismatchUser',
  },
  PACKAGE_EXCEPTIONS: {
    code: '400304',
    message: 'INoticePackageExceptions',
  },
  INVALID_CREDIT_NUMBERS: {
    code: '410000',
    message: 'INoticeInvalidCreditNumbers',
  },
  INVALID_CREDIT_EXPIRATION_PERIOD: {
    code: '410001',
    message: 'INoticeInvalidCreditExpirationPeriod',
  },
  INVALID_CREDIT_CVC: {
    code: '410002',
    message: 'INoticeInvalidCreditCVC',
  },
  CAN_NOT_DELETE_PRIMARY_CARD: {
    code: '410003',
    message: 'INoticeCanNotDeletePrimaryCard',
  },
  NO_REGISTERED_CREDIT: {
    code: '410101',
    message: 'INoticeNoRegisteredCredit',
  },
  NO_REGISTERED_PACKAGES: {
    code: '410102',
    message: 'INoticeNoRegisteredPackages',
  },
  HAS_UNPAID_PACKAGES: {
    code: '410103',
    message: 'INoticeHasUnpaidPackages',
  },
  NOT_ENABLED_AUTO_BILLING_ORG: {
    code: '420000',
    message: 'INoticeNotEnabledAutoBillingOrg',
  },
}

// 401
export const AuthErrorConstant = {
  ACCESS_TOKEN_EXPIRED: {
    code: '400101',
    message: 'INoticeAccessTokenExpired',
  },
  NOT_AUTHORIZED: {
    code: '400200',
    message: 'INoticeNotAuthorized',
  },
}

// 404
export const NotFoundErrorConstant = {
  COMMON_NOT_FOUND: {
    code: '100000',
    message: 'INoticeNotFound',
  },
  KEY_NOT_FOUND: {
    code: '400205',
    message: 'INoticeKeyNotFound',
  },
  NOT_FOUND_ID: {
    code: '202000',
    message: 'IUserEmpty',
  },
  NOT_FOUND_ORGANIZATION: {
    code: '202001',
    message: 'INoticeOrganizationEmpty',
  },
  NO_CUSTOMIZE_PACKAGE: {
    code: '400305',
    message: 'INoticeNoCustomizePackage',
  },
  NO_CUSTOMIZE_PRODUCT: {
    code: '400306',
    message: 'INoticeNoCustomizeProduct',
  },
}

// 409
export const ConflictErrorConstant = {
  ALREADY_EXISTS_EMAIL: {
    code: '200001',
    message: 'INoticeAlreadyExistsEmail',
  },
  CONTAINS_ALREADY_PAYMENT_PACKAGES: {
    code: '410104',
    message: 'INoticeContainsAlreadyPaymentPackages',
  },
  ALREADY_ISSUED_TOSS_BILLING_KEY: {
    code: '420001',
    message: 'INoticeAlreadyIssuedTossBillingKey',
  },
}

// 503-1
export const MaintainErrorConstant = {
  SYSTEM_MAINTAIN: {
    code: '503-1',
  },
}
