import React from 'react'
import {
  AnalysisResultItem,
  IcaAnalysisResultIndicator,
  TypeScreenGraph,
  TypeGraph,
} from 'api/analysisApi'
import ReportHeader from 'components/Report/ReportHeader'
import RenderRawDataGraph from 'components/molcules/Report/RawData/RenderRawDataGraph'

interface RawDataProps {
  readonly indicator: IcaAnalysisResultIndicator
  readonly items: AnalysisResultItem[]
  readonly type: TypeGraph
  readonly screen: TypeScreenGraph
}

function IcaRawDataV1({indicator, items, type, screen}: RawDataProps) {
  const [item] = items

  return (
    <div>
      <ReportHeader
        index={indicator.codeSort}
        title={indicator.codeName}
        description={indicator.titleDescription}
      />
      <RenderRawDataGraph data={item} type={type} screen={screen} />
    </div>
  )
}

export default IcaRawDataV1
