import differenceInMonths from 'date-fns/differenceInMonths'
import {format, set} from 'date-fns'
import intervalToDuration from 'date-fns/intervalToDuration'
import isValid from 'date-fns/isValid'
import parse from 'date-fns/parse'
import parseISO from 'date-fns/parseISO'
import subDays from 'date-fns/subDays'
import subMonths from 'date-fns/subMonths'
import subWeeks from 'date-fns/subWeeks'
import {isBlank, isDate, zeroPad} from 'helpers/commonHelper'
import {Either, Left, Right} from 'purify-ts/Either'
import {ko, enUS} from 'date-fns/locale'
import {Locale} from 'components/atoms/TranslationSelect'

export const dateFormatForFile = 'yyyyMMdd_hh_mm_ss'
export const dateTimeFormat = 'yyyy-MM-dd hh:mm:ss'
export const dateFormatDash = 'yyyy-MM-dd'
export const dateFormatNoDash = 'yyyyMMdd'
export const dateFormatDay = 'dd'
export const dateFormatMonth = 'yyyy-MM'

export const dateToFileFormat = (date: Date) => format(date, dateFormatForFile)
export const dateToDashString = (date: Date) => format(date, dateFormatDash)
export const dateToNoDashString = (date: Date) => format(date, dateFormatNoDash)
export const dateToDateAndTimeString = (date: Date) =>
  format(date, dateTimeFormat)
export const dateToDashMonthString = (date: Date) =>
  format(date, dateFormatMonth)

export function toLocalDate(date: Date): Date {
  const utcDate = new Date(date)
  const utcHours = utcDate.getHours()
  const offset = utcDate.getTimezoneOffset() / 60
  return new Date(utcDate.setHours(utcHours - offset))
}

export const toUtcDate = (date: Date) => {
  const localDate = new Date(date)
  const localHours = localDate.getHours()
  const offset = localDate.getTimezoneOffset() / 60
  return new Date(localDate.setHours(localHours + offset))
}

export function fromIsoDateOrStringToLocalDate(date: Date | string): Date {
  const utcDate = isDate(date) ? new Date(date) : parseISO(date)
  return toLocalDate(utcDate)
}

export function fromLocalDateOrStringToUtcDate(date: Date | string): Date {
  const localDate = isDate(date) ? new Date(date) : parseISO(date)
  return toUtcDate(localDate)
}

type ParsedDate = {
  years: number
  months: number
  days: number
  hours: number
  minutes: number
  seconds: number
  milliseconds: number
}

export function toParsedDate(date: Date): ParsedDate {
  const years = date.getFullYear()
  const months = date.getMonth() + 1
  const days = date.getDate()
  const hours = date.getHours()
  const minutes = date.getMinutes()
  const seconds = date.getSeconds()
  const milliseconds = date.getMilliseconds()
  return {years, months, days, hours, minutes, seconds, milliseconds}
}

export function makeDateAndTimeFormat(date: Date): string {
  const {years, months, days, hours, minutes, seconds} = toParsedDate(date)

  return `${years}-${zeroPad(months)}-${zeroPad(days)} ${zeroPad(
    hours,
  )}:${zeroPad(minutes)}:${zeroPad(seconds)}`
}

export function makeDateFormat(date: Date): string {
  const {years, months, days} = toParsedDate(date)
  return `${years}-${zeroPad(months)}-${zeroPad(days)}`
}

export const isoStringToDateString = (dateString: string) => {
  const date = parseISO(dateString)
  const localDate = toLocalDate(date)
  return makeDateFormat(localDate)
}

export const isoStringToDateAndTimeString = (dateString: string) => {
  const date = parseISO(dateString)
  const localDate = toLocalDate(date)
  return makeDateAndTimeFormat(localDate)
}

export const localStringToUtcString = (dateString: string) => {
  const date = parseISO(dateString)
  const utcDate = toUtcDate(date)
  return makeDateAndTimeFormat(utcDate)
}

export const localDateToUtcString = (date: Date) => {
  const utcDate = toUtcDate(date)
  return makeDateAndTimeFormat(utcDate)
}

export const isoStringToDateAndTimeDate = (date: Date) => {
  const localDate = toLocalDate(date)
  return makeDateAndTimeFormat(localDate)
}

export const isoStringToDate = (date: Date) => {
  const localDate = toLocalDate(date)
  return localDate
}

export const dateToPeriodString = (period: PeriodDate): PeriodString => {
  const {startDate, endDate} = period
  const localStartDate = set(startDate, {
    hours: 0,
    minutes: 0,
    seconds: 0,
  })
  const localEndDate = set(endDate, {
    hours: 23,
    minutes: 59,
    seconds: 59,
  })
  return {
    startDate: localDateToUtcString(localStartDate),
    endDate: localDateToUtcString(localEndDate),
  }
}

export const startDateCompareEndDate = (
  startDate: Date,
  endDate: Date,
): boolean => {
  const startDateSeconds = startDate.getTime()
  const endDateSeconds = endDate.getTime()

  if (startDateSeconds >= endDateSeconds) return false

  return true
}

export const dateLocalToString = (date: PeriodDate): PeriodString => {
  const {startDate, endDate} = date
  const localStartDate = set(startDate, {
    hours: 0,
    minutes: 0,
  })

  const localEndDate = set(endDate, {
    hours: 23,
    minutes: 59,
  })
  return {
    startDate: localDateToUtcString(localStartDate),
    endDate: localDateToUtcString(localEndDate),
  }
}

export const selectDayToPeriodString = (day: Date): PeriodString => {
  const localStartDate = set(day, {
    hours: 0,
    minutes: 0,
    seconds: 0,
  })
  const localEndDate = set(day, {
    hours: 23,
    minutes: 59,
    seconds: 59,
  })
  return {
    startDate: localDateToUtcString(localStartDate),
    endDate: localDateToUtcString(localEndDate),
  }
}

export const utcDateToLocalDateString = (period: PeriodDate): PeriodString => {
  return {
    startDate: isoStringToDateAndTimeDate(period.startDate),
    endDate: isoStringToDateAndTimeDate(period.endDate),
  }
}

export const parseDate = (
  dateString: Nullable<string>,
  dateFormat: string,
): Either<Error, Date> => {
  if (isBlank(dateString)) {
    return Left(new Error('유효하지 않은 문자열'))
  }
  try {
    return Right(parse(dateString, dateFormat, new Date()))
  } catch (err) {
    return Left(err)
  }
}

export const dashStringToDate = (dateString: Nullable<string>) =>
  parseDate(dateString, dateFormatDash)

const getAgeUsingDateFns = (birthDate: Date, newDate: Date, division = 12) =>
  Number((differenceInMonths(newDate, birthDate) / division).toFixed(1))

// 개월 단위로 표시하거나, 연 단위로 표시하기 위해 division을 사용한다.
// 생일과 계산하려는 날짜의 거리를 달로 가져온 후 division으로 나눈다.
// 기본으로 연 단위로 표시하기 위해 12로 나눈다.
export const getAge = (
  birthDateString: Nullable<string>,
  newDateString: Nullable<string>,
  division = 12,
) => {
  const birthDate = dashStringToDate(birthDateString).orDefault(new Date())
  const newDate = dashStringToDate(newDateString).orDefault(new Date())
  return getAgeUsingDateFns(birthDate, newDate, division)
}

export const getAgeToday = (
  birthDateString: Nullable<string>,
  division = 12,
) => {
  const birthDate = dashStringToDate(birthDateString).orDefault(new Date())
  return getAgeUsingDateFns(birthDate, new Date(), division)
}

// yyyy-mm-dd 형태로 바꾸는 함수
export function formatDate(formatDate: Date) {
  const inputDate = new Date(formatDate)

  let month = `${inputDate.getMonth() + 1}`
  let day = `${inputDate.getDate()}`
  const year = inputDate.getFullYear()

  if (month.length < 2) month = `0${month}`
  if (day.length < 2) day = `0${day}`
  return [year, month, day].join('-')
}

export const oneYearAgo = (date: Date) => subDays(date, 363)
export const oneMonthAgo = (date: Date) => subMonths(date, 1)
export const oneDayAgo = (date: Date) => subDays(date, 1)
export const currentMonth = (date: Date) =>
  new Date(date.getFullYear(), date.getMonth(), 1)
export const oneWeekAgo = (date: Date) => subWeeks(date, 1)

export const oneYearAgoFromNow = () => oneYearAgo(new Date())

export const getDefaultPeriodDate = (): PeriodDate => {
  const endDate = new Date()
  const startDate = oneYearAgo(endDate)
  return {
    startDate,
    endDate,
  }
}

export const getDefaultPeriodDateOneDay = (): PeriodDate => {
  const endDate = new Date()
  const startDate = oneDayAgo(endDate)
  return {
    startDate,
    endDate,
  }
}
export const getWeekDefaultPeriodDate = (): PeriodDate => {
  const endDate = new Date()
  const startDate = oneWeekAgo(endDate)
  return {
    startDate,
    endDate,
  }
}

export const getMonthDefaultPeriodDate = (): PeriodDate => {
  const endDate = new Date()
  const startDate = currentMonth(endDate)
  return {
    startDate,
    endDate,
  }
}

export const getDefaultPeriodString = (): PeriodString => {
  const endDate = new Date()
  const startDate = oneYearAgo(endDate)
  return {
    startDate: dateToDashString(startDate),
    endDate: dateToDashString(endDate),
  }
}
export const getDistance = (start: Date, end: Date) =>
  end.getTime() - start.getTime()
export const isValidDate = (date: Date) => isValid(date)
export const isEqualsDate = (date1: Date, date2: Date) => {
  if (date1.getFullYear() !== date2.getFullYear()) return false
  if (date1.getMonth() !== date2.getMonth()) return false
  if (date1.getDate() !== date2.getDate()) return false

  return true
}

export const getDateForFile = (): string => {
  const current = new Date()
  return `${format(current, 'yyyy_MM_dd_hhmmss')}.xlsx`
}

export const periodDateToString = (period: PeriodDate): PeriodString => {
  const {startDate, endDate} = period
  return {
    startDate: dateToDashString(startDate),
    endDate: dateToDashString(endDate),
  }
}

export const periodStringToDate = (period: PeriodString): PeriodDate => {
  const {startDate, endDate} = period
  return {
    startDate: parseDate(startDate, dateFormatDash).orDefault(
      subDays(new Date(), 364),
    ),
    endDate: parseDate(endDate, dateFormatDash).orDefault(new Date()),
  }
}

export function makePrevDate(date: string): string {
  const splitted = date.split('-')
  const month = Number(splitted[1]) - 1

  if (month === 0) {
    const year = Number(splitted[0]) - 1
    return `${year}-12`
  }

  if (month < 10) {
    return `${splitted[0]}-0${month}`
  }

  return `${splitted[0]}-${month}`
}

export function makeNextDate(date: string): string {
  // 예외처리 해야할 것
  // 1. 달이 12를 넘지 못하게 해야한다.
  // 2. 가장 최근의 년도가 넘어가면 가장 최근의 것보다 넘어가지 못하게 구현해야한다.
  const splitted = date.split('-')
  const month = Number(splitted[1]) + 1
  const year = Number(splitted[0]) + 1
  const currentDate = getDateForFile().split('_')

  if (
    year > Number(currentDate[0]) &&
    Number(splitted[1]) >= Number(currentDate[1])
  ) {
    return `${currentDate[0]}-${currentDate[1]}`
  }

  if (splitted[0] === currentDate[0]) {
    if (month < 10) {
      return `${splitted[0]}-0${month}`
    }
    return `${splitted[0]}-${month}`
  }

  if (month === 13 && splitted[0] < currentDate[0]) {
    return `${year}-01`
  }

  if (month < 10) {
    return `${splitted[0]}-0${month}`
  }

  return `${splitted[0]}-${month}`
}

export function nowYearDate(): string {
  const current = new Date()
  return format(current, 'yyyy-MM')
}

/**
 * 시작, 종료 날자를 넘기면 나이를 계산한다.
 *
 * @param start 시작 날짜
 * @param end 종료 날짜
 * @param isMonthRequired 달을 포함한 나이를 계산할 지 여부
 * @return number 나이.
 */
export function parseAgeFromDate(
  start: Date,
  end: Date,
  isMonthRequired = false,
): number {
  const {years = 0, months = 0} = intervalToDuration({start, end})

  if (isMonthRequired) {
    const monthFloat = Number.parseFloat(((months ?? 0) / 12).toFixed(1))
    return years + monthFloat
  }

  return years
}

export function parseAgeFromStrings(startString = '', endString = ''): number {
  const start = parseISO(startString)
  const end = parseISO(endString)

  // @ts-ignore
  if (start === 'Invalid Date') return -1
  // @ts-ignore
  if (end === 'Invalid Date') return -1

  return parseAgeFromDate(start, end)
}

export const makeExpires = (day: number) => {
  const currentDay = new Date().getDate()
  const expireDay = new Date()
  expireDay.setDate(currentDay + day)
  return expireDay
}

// CYM: getting nominative month name with i18n
export const makeFullMonthName = (date: Date, currentLocale: string) => {
  let locale = ko
  if (currentLocale === 'en') locale = enUS

  return format(date, 'MMMM', {locale})
}

export const formatDateFromString = (dateString: string) => {
  if (!dateString || dateString.length !== 8) return dateString
  const year = dateString.substring(0, 4)
  const month = dateString.substring(4, 6)
  const day = dateString.substring(6, 8)

  return `${year}${month}${day}`
}

export function formatDateInvoice(date: Date) {
  const dataDate = {
    month: (date.getMonth() + 1).toString(),
    day: date.getDate().toString(),
    year: date.getFullYear().toString(),
  }
  if (Number(dataDate.month) < 10) {
    dataDate.month = `0${dataDate.month}`
  }
  if (Number(dataDate.day) < 10) {
    dataDate.day = `0${dataDate.day}`
  }
  return `${dataDate.day}/${dataDate.month}/${dataDate.year}`
}

export function formatDateGetApi(date: Date) {
  const dataDate = {
    month: (date.getMonth() + 1).toString(),
    year: date.getFullYear().toString(),
  }
  if (Number(dataDate.month) < 10) {
    dataDate.month = `0${dataDate.month}`
  }
  return `${dataDate.year}${dataDate.month}`
}

export function getMonthYear(date: Date, lang: Locale): string {
  const dataDate = {
    month: (date.getMonth() + 1).toString(),
    year: date.getFullYear().toString(),
  }
  const monthAbbreviations = [
    'Jan', // January
    'Feb', // February
    'Mar', // March
    'Apr', // April
    'May', // May
    'Jun', // June
    'Jul', // July
    'Aug', // August
    'Sep', // September
    'Oct', // October
    'Nov', // November
    'Dec', // December
  ]

  if (lang === 'ko') return `${dataDate.month}월`
  return `${monthAbbreviations[date.getMonth()]}`
}

export function combineDateAndTime(date: Date, hours: number, minutes: number) {
  const combinedDate = new Date(date)
  if (hours || minutes) combinedDate.setHours(hours ?? 0, minutes ?? 0, 0, 0)
  return combinedDate
}
