export const Gender = {
  MALE: 'M',
  FEMALE: 'F',
  UNKNOWN: 'U',
} as const

export type GenderKey = keyof typeof Gender
export type GenderValue = ValueOf<typeof Gender>

export const toGenderString = (str: GenderValue) => {
  switch (str) {
    case Gender.MALE:
      return 'IGenderMale'
    case Gender.FEMALE:
      return 'IGenderFemale'
    default:
      return 'IUnknown'
  }
}

export const toGenderShortString = (str: GenderValue) => {
  switch (str) {
    case Gender.MALE:
      return 'IGenderMaleShort'
    case Gender.FEMALE:
      return 'IGenderFemaleShort'
    default:
      return ''
  }
}

export const GenderSelectItems = [
  {label: 'IGenderMale', value: Gender.MALE},
  {label: 'IGenderFemale', value: Gender.FEMALE},
]

export const Hand = {
  LEFT: 'LEFT',
  RIGHT: 'RIGHT',
  BOTH: 'BOTH',
  UNKNOWN: 'UNKNOWN',
} as const

export const ReportHand = {
  LEFT: 'L',
  RIGHT: 'R',
  BOTH: 'B',
  UNKNOWN: 'U',
} as const

export type HandKey = keyof typeof Hand
export type HandValue = ValueOf<typeof Hand>
export type HandReportValue = ValueOf<typeof ReportHand>

export const HandSelectItems = [
  {label: 'IHandednessRight', value: Hand.RIGHT},
  {label: 'IHandednessLeft', value: Hand.LEFT},
  {label: 'IHandednessBoth', value: Hand.BOTH},
  {label: 'IDoNotKnow', value: Hand.UNKNOWN},
]

export const toHandString = (str: HandValue) => {
  switch (str) {
    case Hand.RIGHT:
      return 'IHandednessRight'
    case Hand.LEFT:
      return 'IHandednessLeft'
    case Hand.BOTH:
      return 'IHandednessBoth'
    default:
      return 'IDoNotKnow'
  }
}

export const toReportHandString = (str: HandReportValue) => {
  switch (str) {
    case ReportHand.RIGHT:
      return 'IHandednessRight'
    case ReportHand.LEFT:
      return 'IHandednessLeft'
    case ReportHand.BOTH:
      return 'IHandednessBoth'
    case ReportHand.UNKNOWN:
      return 'IDoNotKnow'
    default:
      return 'IDoNotKnow'
  }
}

export type EmailAuthenticationType = 'J' | 'R'

export const TYPE_WHITE_LIST = ['SPECIAL_GC']
export const STATUS_ENABLE_ITEM_HEALTH_CENTER = [
  'PARTIALLY_COMPLETED',
  'SUCCESS',
]

// Validate constants
export const REGEX_PHONE =
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/

export const REGEX_EMAIL =
  /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i
