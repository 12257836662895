import {Theme} from '@material-ui/core'
import {createStyles} from '@material-ui/core/styles'

const NoticeReadStyle = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      margin: 15,
    },
    gridContainer: {
      // marginBottom: 30,
      display: 'flex',
      flexDirection: 'column',
      margin: 25,
    },
    containerTitle: {
      padding: 10,
      fontWeight: 600,
      color: '#35495D',
      marginLeft: 30,
      marginBottom: 10,
      marginTop: 10,
    },
    contentContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      width: '100%',
      '& > h6': {
        fontSize: '1.0rem',
      },
    },
    contentTitleWrap: {
      alignItems: 'center',
      flexWrap: 'wrap',
      flex: 1,
      '& > h6': {
        fontSize: '1.0rem',
      },
    },
    inputRoot: {
      '&$disabled': {
        color: 'red',
      },
    },

    titleSize: {
      fontSize: '1.0rem',
      whiteSpace: 'pre-wrap',
    },
    titleCenter: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      color: '#000',
      '& > h6': {
        fontSize: '1.0rem',
      },
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      marginTop: 43,
    },
    buttonWrap: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      flexWrap: 'wrap',
    },
    padding: {
      padding: theme.spacing(1),
    },
    rootText: {
      '&$disabled': {
        color: 'white',
      },
    },
    disabled: {},
    background: {
      color: 'primary',
      '& span': {
        background: '#E0E0E0',
      },
    },
    dateTextField: {
      width: 270,
      height: 47,
      marginTop: 10,
      display: 'flex',
      padding: 10,
      backgroundColor: '#fafafa',
      alignItems: 'center',
      border: '1px solid #e2e4e7',
      borderRadius: theme.shape.borderRadius,
      color: '#333333',
      justifyContent: 'space-between',
    },
    contentTextField: {
      width: '100%',
      height: 292,
      overflow: 'scroll',
      marginTop: 10,
      display: 'flex',
      padding: 10,
      backgroundColor: '#fafafa',
      border: '1px solid #e2e4e7',
      borderRadius: theme.shape.borderRadius,
      color: '#333333',
      justifyContent: 'space-between',
    },
    dateIcon: {
      color: '#5c6168',
    },
    textField: {
      width: '100%',
      height: 47,
      marginTop: 10,
      display: 'flex',
      padding: 10,
      backgroundColor: '#fafafa',
      alignItems: 'center',
      border: '1px solid #e2e4e7',
      borderRadius: theme.shape.borderRadius,
      color: '#333333',
    },
    titleTextField: {
      width: '100%',
      minWidth: 120,
      height: 47,
      marginTop: 10,
      display: 'flex',
      padding: 10,
      backgroundColor: '#fafafa',
      alignItems: 'center',
      border: '1px solid #e2e4e7',
      borderRadius: theme.shape.borderRadius,
      color: '#333333',
    },
    container: {
      width: '100%',
    },
    hidden: {
      visibility: 'hidden',
    },
    backButton: {
      width: 148,
    },
    contentWrap: {
      display: 'flex',
      flexDirection: 'row',
      flex: 1,
      flexWrap: 'wrap',
    },
    flexGap30: {
      display: 'flex',
      gap: 30,
    },
    flexColumn: {
      display: 'flex',
      flexDirection: 'column',
    },
    link: {
      color: '#1890ff',
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    titleShort: {
      cursor: 'pointer',
      maxWidth: 400,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  })

export default NoticeReadStyle
