import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import {Colors} from '../../common/useBaseStyle'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      whiteSpace: 'pre-wrap',
      maxWidth: 1800,
      gap: 40,
      '& .Warning': {
        borderTop: '1px solid',
        borderTopColor: '#bdbdbd',
        color: '#bdbdbd',
      },
    },
    hrv: {
      maxWidth: 1550,
      '& .ReportTitle': {margin: '20px 0'},
    },
    hrvOpinion: {
      margin: '0 35px',
      '& .Opinion': {
        fontWeight: 'bold',
        fontSize: 15,
        margin: '50px 0 10px',
        position: 'relative',
        '& .Label': {
          padding: '3px 20px',
          backgroundColor: '#0bb7b6',
          color: 'white',
          fontWeight: 'bold',
          borderRadius: 30,
          zIndex: 10,
          position: 'relative',
        },
        '& .Divider': {
          position: 'absolute',
          top: 12,
          border: '1px solid #0bb7b6',
          width: '100%',
          margin: 0,
        },
      },
      '& .Balance': {
        fontSize: 15,
        margin: '20px 15px',
        color: '#3eb9bc',
        lineHeight: 1.5,
        whiteSpace: 'pre-wrap',
      },
    },
    hrvAriability: {
      display: 'flex',
      gap: 30,
      [theme.breakpoints.down('md')]: {
        flexWrap: 'wrap',
      },
      '& .ContourImage': {
        flex: 1,
        height: 'auto',
        width: '45%',
        maxWidth: 500,
        objectFit: 'scale-down',
      },
    },
    Divider: {
      height: 2,
      backgroundColor: '#0bb7b6',
      maxWidth: '100%',
      margin: '0 35px 0 45px',
    },
    Divider2: {
      height: 2,
      backgroundColor: '#0bb7b6',
      maxWidth: '100%',
      margin: '0 0 0 5px',
    },
    description: {
      fontSize: 16,
      fontWeight: 500,
      color: '#4d5d6b',
      lineHeight: 1.5,
      marginLeft: 35,
    },
    hrvAutoNervous: {
      display: 'flex',
      gap: 30,
      width: '100%',
      margin: '0 35px',
      [theme.breakpoints.down('md')]: {
        flexWrap: 'wrap',
      },
      '& .CircularChartImage': {
        // flex: 1,
        height: 'auto',
        width: '40%',
        marginTop: 20,
        // objectFit: 'scale-down',
      },
      '& .TableImage': {
        // flex: 2,
        width: '52%',
        height: 'auto',
        objectFit: 'scale-down',
      },
      '& .AutoNervousTitle': {
        fontWeight: 'bold',
        fontSize: 20,
        color: '#4d5d6b',
      },
      '& .About': {
        fontSize: 17,
        color: '#707070',
        marginBottom: 15,
      },
      '& .Sympathetic': {
        color: '#0bb7b6',
        fontSize: 15,
        padding: 3,
        '& .Label': {
          padding: '1px 3px',
          backgroundColor: '#0bb7b6',
          color: 'white',
          fontWeight: 'bold',
        },
        '& .Label-append': {
          fontWeight: 'bold',
        },
      },
      '& .Parasympathetic': {
        color: '#008ba3',
        fontSize: 15,
        padding: 3,
        '& .Label': {
          padding: '1px 3px',
          backgroundColor: '#008ba3',
          color: 'white',
          fontWeight: 'bold',
        },
        '& .Label-append': {
          fontWeight: 'bold',
        },
      },
      '& .Analysis': {
        display: 'flex',
        flexWrap: 'wrap',
        gap: 30,
      },
    },
    hrvStress: {
      maxWidth: 1200,
      '& .Wrapper': {
        position: 'relative',
        '& .PhaseChart': {
          width: '100%',
          height: 'auto',
        },
        '& .PhaseChart-Label': {
          color: 'white',
          fontWeight: 'bold',
          fontSize: 14,
          position: 'absolute',
          top: '44%',
          transform: 'translate(-50%, -50%)',
        },
      },
      '& .TableHeader': {
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: '#0bb7b6',
        color: 'white',
        padding: '10px 20px',
        fontSize: 15,
        fontWeight: 600,
      },
      '& .TableCell': {
        display: 'flex',
        flexDirection: 'row',
        padding: '10px 20px',
        alignItems: 'center',
        color: '#4d5d6b',
        '& .title': {
          fontSize: 18,
          width: '30%',
          fontWeight: 600,
        },
        '& .content': {
          fontSize: 16,
          width: '70%',
        },
      },
    },
    Table: {
      '& .TableHeader': {
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: '#0bb7b6',
        color: 'white',
        padding: '10px 20px',
        fontSize: 18,
        fontWeight: 600,
        '& .title': {
          width: '30%',
        },
        '& .content': {
          width: '70%',
        },
      },
      '& .TableCell': {
        display: 'flex',
        flexDirection: 'row',
        padding: '25px 20px',
        alignItems: 'center',
        color: '#4d5d6b',
        '& .title': {
          fontSize: 18,
          width: '30%',
          fontWeight: 600,
        },
        '& .content': {
          fontSize: 16,
          width: '70%',
        },
      },
    },

    hrvSentimental: {
      maxWidth: 1200,
      marginBottom: 50,
      '& .ReportTitle': {
        marginBottom: 40,
      },
      '& .TableTitle': {
        color: '#4d5d6b',
        fontSize: 20,
        fontWeight: 600,
        marginTop: 20,
      },
      '& .Status': {
        display: 'grid',
        gridTemplateColumns: 'minmax(20%, 250px) 1fr',
        gridTemplateRows: '1fr 1fr',
        gridColumnGap: 30,
        gridRowGap: 50,

        '& .StatusIcon': {
          position: 'relative',
          justifySelf: 'center',
          alignSelf: 'center',
          textAlign: 'center',
          '& .StatusIcon-image': {
            width: '100%',
            height: 'auto',
            maxWidth: 160,
          },

          '& .StatusIcon-label': {
            position: 'absolute',
            width: 160,
            bottom: 4,
            color: '#008ba3',
            fontWeight: 'bold',
            fontSize: 15,
            // left: '50%',
            transform: 'translate(0%, -39%)',
            '&.Depress': {color: '#008ba3'},
            '&.Anxiety': {color: '#ffa600'},
          },
        },
        '& .Status-text': {
          fontSize: 18,

          '& .Title': {
            color: '#008ba3',
            fontWeight: 'bold',
            marginBottom: 27,
          },
          '& .Level': {
            fontSize: 17,
            fontWeight: 'bold',
            marginBottom: 15,
          },
          '& .Reference': {
            fontSize: 16,
            color: '#707070',
          },
        },
      },
    },
    eeg: {
      '& .ReportTitle': {marginBottom: 25},
    },
    brain3D: {
      '& .Image-wrapper': {
        textAlign: 'center',

        '& .Image': {
          width: '100%',
          height: 'auto',
          maxWidth: 800,
          objectFit: 'contain',
          marginBottom: 25,
        },
      },
      '& .Reference': {
        textAlign: 'left',

        '& .Title': {
          color: '#404040',
          fontWeight: 'bold',
          fontSize: 18,
          marginBottom: 15,
        },
        '& .Desc': {
          fontSize: 16,
          whiteSpace: 'pre-wrap',
        },
      },
    },
    brainOpinion: {
      marginTop: 50,
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      columnGap: '30px',

      '& .Detail': {
        '& .Opinion': {
          textAlign: 'left',
          fontSize: 18,
          marginBottom: 20,
        },
        '& .Image': {
          width: '100%',
          height: 'auto',
          objectFit: 'contain',
          maxWidth: 470,
          marginBottom: 30,
        },
        '& .Reference': {
          '& .Title': {
            fontWeight: 'bold',
            fontSize: 20,
            marginBottom: 10,
            color: '#adadad',
          },
          '& .Description': {
            fontSize: 16,
            color: '#a8a6a6',
          },
        },
      },
      '& .Contour': {
        alignSelf: 'center',
        justifySelf: 'center',

        '& .Image': {
          width: '100%',
          height: 'auto',
          objectFit: 'contain',
          maxWidth: 470,
        },
      },
    },
    padding: {
      padding: theme.spacing(1),
    },
    mci: {
      maxWidth: 1300,
      whiteSpace: 'pre-wrap',
      '& .titleDescription': {
        fontWeight: 300,
        padding: '8px 5px 0 10px',
        display: 'flex',
        fontSize: 17,
        lineHeight: 1.5,
      },
      '& .contentTitle': {
        fontWeight: 'bold',
        fontSize: 18,
        padding: '20px 5px 0 10px',
        color: '#707070',
      },
      '& .contentDescription': {
        fontWeight: 300,
        fontSize: 18,
        padding: '10px 5px 100px 10px',
        display: 'flex',
        color: '#707070',
      },
      '& .guideTable': {
        display: 'flex',
        width: '100%',
        minWidth: 1000,
        padding: '24px 0',
        '& .guideCell': {
          width: '100%',
          display: 'grid',
          gridTemplateColumns: '13% 15% 55% 15%',
          alignItems: 'center',
          paddingLeft: 20,
          gap: 10,
          '& .round': {
            width: 80,
            height: 80,
            borderRadius: '50%',
            color: '#ffffff',
            position: 'relative',
            fontWeight: 'bold',
            fontSize: 16,
            display: 'flex',
            alignItems: 'center',
            margin: '0 20px',
          },
          '& .guideRoundTitle': {
            fontWeight: 300,
            display: 'flex',
            fontSize: 19,
            lineHeight: 1.5,
            flexDirection: 'column',
            width: 150,
            paddingButton: 10,
            '& .guideContentPercent': {
              fontSize: 29,
              color: '#15B785',
              fontWeight: 800,
            },
          },
          '& .guideContent': {
            fontWeight: 300,
            display: 'flex',
            flexDirection: 'column',
            fontSize: 16,
            lineHeight: 1.5,
            '& .guideContentBold': {
              fontWeight: 'bold',
              marginBottom: 10,
            },
          },
          '& .guideButton': {
            width: 150,
            height: 80,
            borderRadius: 10,
            color: '#ffffff',
            textAlign: 'center',
            lineHeight: 1.4,
            position: 'relative',
            fontWeight: 'bold',
            fontSize: 16,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
        },
      },
    },
    childValueGraph: {
      display: 'flex',
      gap: 20,

      '& .Child-Graph': {
        flex: 6,
        '& img': {
          width: '100%',
          height: 'auto',
        },
      },
      '& .Child-info': {
        flex: 6,
        '& .Range': {
          height: 36,
          '& span': {
            lineHeight: '18px',
          },
          display: 'flex',
          width: '65%',
          alignItems: 'center',
          color: '#FFF',
          fontSize: 15,
          marginLeft: 0,
          '& .ScoreValue': {
            color: '#FFF',
            fontWeight: 'bold',
            fontSize: 20,
            marginLeft: 2,
          },
        },
        '& .Score': {
          // flex: 5,
          lineHeight: '18px',
          color: '#FFF',
          fontSize: 15,
          float: 'right',
          '& .ScoreValue': {
            borderRadius: '20px',
            padding: '4px 24px 8px 24px',
            color: '#00a29c',
            backgroundColor: '#FFF',
            fontWeight: 'bold',
            fontSize: 20,
            marginLeft: 14,
          },
          '& .ScoreNullValue': {
            borderRadius: '20px',
            padding: '4px 30px 8px 30px',
            color: '#e0e0e0',
            backgroundColor: '#FFF',
            fontWeight: 'bold',
            fontSize: 20,
            marginLeft: 14,
          },
        },
        '& .scoreWrapText': {
          textWrap: 'nowrap',
        },
        '& .Title': {
          color: '#404040',
          fontWeight: 'bold',
          fontSize: 18,
          marginBottom: 15,
        },
        '& .Desc': {
          fontSize: 16,
          whiteSpace: 'pre-wrap',
        },
      },
      '& .Child-info-alert': {
        color: '#767676',
        fontSize: 14,
        marginTop: 10,
        paddingBottom: 40,
      },
      '& .RefDisease': {
        marginTop: 20,
        '& .RefDiseaseTitle': {
          color: '#00a29c',
          fontSize: 16,
          fontWeight: 'bold',
        },
        '& .RefDiseaseDesc': {
          color: Colors.black,
          fontSize: 16,
        },
      },
    },
    childValueGraphFull: {
      display: 'block',
      gap: 20,

      '& .Child-Graph': {
        marginTop: 40,
        marginBottom: 40,
        '& .Score-Layout': {
          width: 315,
          height: 'auto',
          textAlign: 'center',
          marginLeft: 'auto',
          marginRight: 'auto',
          '& .Bottom-Score': {
            marginTop: 20,
            '& .Current-Label': {
              fontSize: 15,
              color: '#727272',
              fontWeight: 500,
            },
            '& .Current-Score': {
              textAlign: 'center',
              paddingBottom: 10,
              '& .Current-Score-Value': {
                fontSize: 36,
              },
              '& .Current-Score-Unit': {
                marginLeft: 5,
                fontSize: 16,
              },
            },
          },
          '& .Bottom-Avg': {
            marginTop: 20,
            '& .Red-Label': {
              color: '#f55856 !important',
            },
            '& .Avg-Label': {
              fontSize: 16,
              fontWeight: 'bold',
              color: '#20a2dc',
            },
            '& .Avg-Score': {
              textAlign: 'center',
              paddingBottom: 10,
              '& .Avg-Score-Value': {
                fontSize: 60,
                fontWeight: 'bold',
                color: '#20a2dc',
              },
              '& .Avg-Score-Unit': {
                marginLeft: 5,
                fontSize: 20,
                color: '#20a2dc',
                fontWeight: 500,
              },
            },
          },
        },
        '& img': {
          width: '100%',
          height: 'auto',
        },
      },
      '& .Child-Graph-Desc': {},
      '& .Child-info': {
        '& .Range': {
          color: '#FFF',
          fontSize: 18,
          '& .RangeValue': {
            marginLeft: 20,
            fontWeight: 'bold',
            fontSize: 20,
          },
        },
        '& .Score': {
          color: '#FFF',
          float: 'right',
          fontSize: 16,
          '& .ScoreValue': {
            borderRadius: '20px',
            paddingTop: 2,
            paddingBottom: 4,
            color: '#00a29c',
            backgroundColor: '#FFF',
            fontWeight: 'bold',
            fontSize: 20,
            marginLeft: 14,
          },
          '& .ScoreNullValue': {
            borderRadius: '20px',
            paddingTop: 2,
            paddingBottom: 4,
            color: '#e0e0e0',
            backgroundColor: '#FFF',
            fontWeight: 'bold',
            fontSize: 20,
            marginLeft: 14,
          },
        },
        '& .Title': {
          color: '#404040',
          fontWeight: 'bold',
          fontSize: 18,
          marginBottom: 15,
        },
        '& .Desc': {
          fontSize: 16,
          whiteSpace: 'pre-wrap',
        },
      },
      '& .Child-info-alert': {
        color: '#767676',
        fontSize: 14,
        marginTop: 10,
        marginBottom: 60,
      },
      '& .RefDisease': {
        marginTop: 20,
        '& .RefDiseaseTitle': {
          color: '#00a29c',
          fontSize: 16,
          fontWeight: 'bold',
        },
        '& .RefDiseaseDesc': {
          color: Colors.black,
          fontSize: 16,
        },
      },
    },
    childValueGraphHalf: {
      display: 'block',
      gap: 20,
      '& .Child-Graph-Box': {
        display: 'flex',
        '& .Child-Graph': {
          flex: 10,
          marginTop: 30,
          marginBottom: 30,
          '& img': {
            width: '100%',
            height: 'auto',
          },
        },
        '& .Child-Graph-Desc': {
          flex: 5,
          marginLeft: 60,
          marginTop: 30,
          marginBottom: 30,
          '& .Top-Desc': {
            fontSize: 16,
          },
          '& .Bottom-Score': {
            marginTop: 20,
            '& .Current-Label': {
              fontSize: 16,
              fontWeight: 'bold',
            },
            '& .Current-Score': {
              textAlign: 'end',
              paddingBottom: 10,
              '& .Current-Score-Value': {
                fontSize: 36,
              },
              '& .Current-Score-Unit': {
                marginLeft: 5,
                fontSize: 16,
              },
            },
          },
          '& .Bottom-Avg': {
            marginTop: 20,
            '& .Red-Label': {
              color: '#f55856 !important',
            },
            '& .Avg-Label': {
              fontSize: 16,
              fontWeight: 'bold',
              color: '#20a2dc',
            },
            '& .Avg-Score': {
              textAlign: 'end',
              paddingBottom: 10,
              '& .Avg-Score-Value': {
                fontSize: 62,
                fontWeight: 'bold',
                color: '#20a2dc',
              },
              '& .Avg-Score-Unit': {
                marginLeft: 5,
                fontSize: 24,
                fontWeight: 'bold',
                color: '#20a2dc',
              },
            },
          },
        },
      },
      '& .Child-info': {
        '& .Range': {
          color: '#FFF',
          fontSize: 18,
          '& .RangeValue': {
            marginLeft: 20,
            fontWeight: 'bold',
            fontSize: 20,
          },
        },
        '& .Score': {
          color: '#FFF',
          float: 'right',
          fontSize: 16,
          '& .ScoreValue': {
            borderRadius: '20px',
            paddingTop: 2,
            paddingBottom: 4,
            color: '#00a29c',
            backgroundColor: '#FFF',
            fontWeight: 'bold',
            fontSize: 20,
            marginLeft: 14,
          },
          '& .ScoreNullValue': {
            borderRadius: '20px',
            paddingTop: 2,
            paddingBottom: 4,
            color: '#e0e0e0',
            backgroundColor: '#FFF',
            fontWeight: 'bold',
            fontSize: 20,
            marginLeft: 14,
          },
        },
        '& .Title': {
          color: '#404040',
          fontWeight: 'bold',
          fontSize: 18,
          marginBottom: 15,
        },
        '& .Desc': {
          fontSize: 16,
          whiteSpace: 'pre-wrap',
        },
      },
      '& .Child-info-alert': {
        color: '#767676',
        fontSize: 14,
        marginTop: 10,
        marginBottom: 20,
      },
      '& .RefDisease': {
        marginTop: 20,
        '& .RefDiseaseTitle': {
          color: '#00a29c',
          fontSize: 16,
          fontWeight: 'bold',
        },
        '& .RefDiseaseDesc': {
          color: Colors.black,
          fontSize: 16,
        },
      },
    },
  }),
)

export default useStyles
