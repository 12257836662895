import React, {MouseEventHandler, useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useLocation} from 'react-router-dom'
import {
  Checkbox,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import TableButton from 'components/atoms/Buttons/TableButton'
import {ClientName} from 'components/atoms/ClientName'
import Pagination from 'components/molcules/Pagination'
import TableCell from 'components/Table/TableCell'
import TableReportSearch from 'components/Table/TableReportSearch'
import useStyles from 'components/Table/useTableStyles'
import useToolbarStyles from 'components/Table/useTableToolbarStyles'
import ActionButton from 'components/atoms/Buttons/ActionButton'
import useFailureModal from 'features/modal/useFailureModal'
import useNoticeConfirm from 'features/modal/useNoticeConfirm'
import useSuccessModal from 'features/modal/useSuccessModal'
import useHrvSummary from 'features/analysis/useHrvSummary'
import {
  SearchedIndividualSummary,
  executeHrvSummaryAnalysisApi,
} from 'api/analysisApi'
import {AnalysisReportType} from 'constants/AnalysisConstant'
import {getHrvSummaryReportPath} from 'constants/RouteConstant'
import {getTextHRVPremium, statusToI18nString} from 'helpers/analysisHelper'
import {
  dateToPeriodString,
  getDefaultPeriodDate,
  isoStringToDateAndTimeString,
} from 'helpers/dateHelper'
import openInNewTab from 'helpers/openInNewTab'
import {getQueryFromUrl} from 'helpers/commonHelper'

const isUpdatable = (searched: SearchedIndividualSummary) =>
  searched.progress === 'SUCCESS' &&
  searched.reportType === 'P1' &&
  searched.additionalAnalysis === null

interface TableToolBarProps {
  selectedItems: SearchedIndividualSummary[]
  refreshSelect: () => void
}

function TableToolbar({selectedItems, refreshSelect}: TableToolBarProps) {
  const location = useLocation()
  const classes = useToolbarStyles()
  const {t} = useTranslation()
  const {query, search, onSearch} = useHrvSummary()
  const [selection] = React.useState<PeriodDate>(getDefaultPeriodDate())
  const {onOpen: onSuccessModalOpen} = useSuccessModal()
  const {onOpen: onFailureModalOpen} = useFailureModal()
  const {onOpen: onNoticeDialogOpen} = useNoticeConfirm()
  /** Set default value for search query if it is stored in url */
  useEffect(() => {
    const initQuery = getQueryFromUrl(location.search)
    onSearch({
      ...query,
      search: {searchName: 'NAME', searchValue: ''},
      period: dateToPeriodString(selection),
      paging: {
        page: 0,
        size: 10,
      },
      ...initQuery,
    })
  }, [])

  const summaryRequest = async () => {
    const response = await executeHrvSummaryAnalysisApi({
      individualRequestIds: selectedItems.map((item) => item.id),
      type: 'HRV_PREMIUM_SUMMARY',
    })

    if (response.isError) {
      onFailureModalOpen(response.error)
    } else {
      onSuccessModalOpen(t('IProcessSuccess'))
      onSearch(query)
      refreshSelect()
    }
  }

  const handleSummaryClick = async () => {
    if (selectedItems.length === 0) {
      onFailureModalOpen(t('ISelectRequired'))
      return
    }
    onNoticeDialogOpen({
      title: t('INoticeTitle'),
      message: t('INoticeConfirmDesc'),
    }).then((result) => {
      if (result.payload) {
        summaryRequest()
      }
    })
  }

  return (
    <div className={classes.root}>
      <TableReportSearch
        reportType={AnalysisReportType.HRVSummary}
        isRoot={false}
        query={query}
        search={search}
        onSearch={onSearch}
      />

      <div className={classes.containerOptional}>
        <ActionButton onClick={handleSummaryClick}>
          {t('IPremiumSummary')}
        </ActionButton>
      </div>
    </div>
  )
}

interface RenderTableRowProps {
  item: SearchedIndividualSummary
  onClick: (item: SearchedIndividualSummary) => void
  isSelected: (item: SearchedIndividualSummary) => boolean
}

function RenderTableRow({item, onClick, isSelected}: RenderTableRowProps) {
  const {t} = useTranslation()

  const handleResultClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation()
    openInNewTab(getHrvSummaryReportPath(item.id))
  }

  const getFileName = (finalReportPath?: string) => {
    if (!finalReportPath) return '-'
    return finalReportPath.split('/').pop()
  }

  const labelId = `enhanced-table-checkbox-${item.id}`

  const checked = isSelected(item)

  return (
    <TableRow hover onClick={() => onClick(item)}>
      <TableCell padding='checkbox'>
        <Checkbox checked={checked} inputProps={{'aria-labelledby': labelId}} />
      </TableCell>
      <TableCell align='center'>{item.id}</TableCell>
      <TableCell align='center'>
        <ClientName
          firstName={item.firstName}
          lastName={item.lastName}
          birth={item.birth}
          gender={item.gender}
        />
      </TableCell>
      <TableCell align='center'>
        {getFileName(item.finalReportPath ?? '')}
      </TableCell>
      <TableCell align='center'>
        {isoStringToDateAndTimeString(String(item.measureDate))}
      </TableCell>
      <TableCell align='center'>{item.normdbVersion ?? '-'}</TableCell>
      <TableCell align='center'>
        {t(statusToI18nString(item.progress ?? '-'))}
      </TableCell>
      <TableCell align='center'>{getTextHRVPremium(item.reportType)}</TableCell>
      <TableCell align='center'>
        <TableButton
          onClick={handleResultClick}
          disabled={item.progress !== 'SUCCESS'}
        >
          {t('IRead')}
        </TableButton>
      </TableCell>
    </TableRow>
  )
}

function PageTable() {
  const {t} = useTranslation()
  const classes = useStyles()

  const {loading, query, paging, pagingInfo, items, onSearch} = useHrvSummary()
  const [selected, setSelected] = useState<SearchedIndividualSummary[]>([])
  const {onOpen: onFailureModalOpen} = useFailureModal()
  const selectedId = useMemo(() => selected.map((item) => item.id), [selected])

  const setPageIndex = (page: number) =>
    onSearch({
      ...query,
      paging: {
        page,
        size: paging.size,
      },
    })

  const onSizeChanged = (
    event: React.ChangeEvent<{name?: string; value: unknown}>,
  ) => {
    const size = parseInt(event.target.value as string, 10)
    onSearch({
      ...query,
      paging: {
        page: 0,
        size,
      },
    })
  }

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelected(items.filter(isUpdatable))
      return
    }
    setSelected([])
  }

  const handleRowClick = (item: SearchedIndividualSummary) => {
    if (!isUpdatable(item)) {
      onFailureModalOpen(t('IAnalysisUnselectable_notUpdatable'))
      return
    }

    const selectedIndex = selectedId.indexOf(item.id)
    let newSelected: SearchedIndividualSummary[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, item)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      )
    }

    setSelected(newSelected)
  }

  const isRowSelected = (item: SearchedIndividualSummary) =>
    selectedId.indexOf(item.id) !== -1

  const successItems = items.filter((item) => item.progress === 'SUCCESS')
  const emptyRows = items === null ? 5 : 5 - items.length

  return (
    <TableContainer className={classes.tableContainer}>
      <TableToolbar
        selectedItems={selected}
        refreshSelect={() => setSelected([])}
      />
      <Table className={classes.table}>
        <TableHead>
          <TableRow className={classes.tableHeader}>
            <TableCell align='center' padding='none'>
              <Checkbox
                indeterminate={
                  selected.length > 0 && selected.length < successItems.length
                }
                checked={
                  successItems.length > 0 &&
                  selected.length === successItems.length
                }
                onChange={handleSelectAllClick}
                inputProps={{'aria-label': 'select all desserts'}}
              />
            </TableCell>
            <TableCell align='center' padding='none'>
              {t('IAnalysisNo')}
            </TableCell>
            <TableCell align='center' padding='none'>
              {t('IPatientName')}
            </TableCell>

            <TableCell align='center' padding='none'>
              {t('IFileName')}
            </TableCell>
            <TableCell align='center' padding='none'>
              {t('IMeasureDate')}
            </TableCell>
            <TableCell align='center' padding='none'>
              {t('IVersion')}
            </TableCell>
            <TableCell align='center' padding='none'>
              {t('IProgress')}
            </TableCell>
            <TableCell align='center'>{t('IPremium')}</TableCell>
            <TableCell align='center' padding='none'>
              {t('IAnalysisReport')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item) => (
            <RenderTableRow
              key={item.id}
              item={item}
              onClick={handleRowClick}
              isSelected={isRowSelected}
            />
          ))}
          {emptyRows > 0 && (
            <TableRow
              style={{height: 43 * emptyRows, backgroundColor: '#F9F9FB'}}
            >
              <TableCell colSpan={12} />
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Pagination
        totalPageCount={pagingInfo.totalPages}
        currentPageIndex={query.paging.page ?? 0}
        itemCountPerPage={query.paging.size ?? 10}
        setCurrentPageIndex={setPageIndex}
        loading={loading}
        onItemCountPerPageChanged={onSizeChanged}
      />
    </TableContainer>
  )
}

export default PageTable
