import React, {useEffect, useMemo, useState} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {IconButton, Radio, RadioGroup} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Divider from '@material-ui/core/Divider'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {SchemaOf} from 'yup'
import {isOrganizationHealth} from 'Authority'
import {useBoldInputStyle} from 'components/common/useBoldInputStyle'
import useStyles from 'components/Dialog/User/Style'
import {TabPanel, Tabs} from 'components/Tab'
import {Gender, GenderSelectItems} from 'constants/CommonConstant'
import * as storage from 'api/storageApi'
import {
  CreateVisitNumberPatientRequest,
  doubleCheckPatientChartNoApi,
  fetchPatientApi,
  PatientAddForm,
  PatientVisitNumber,
} from 'api/patientApi'
import useAuth from 'features/auth/useAuth'
import useNoticeConfirm from 'features/modal/useNoticeConfirm'
import useOrgUserList from 'features/org/useOrgUserList'
import {useWhiteListCreateDialog} from 'features/patient/usePatientWhiteListDialog'
import useProductList from 'features/patient/useProductList'
import {isNotDefined} from 'helpers/commonHelper'
import {dateToDashString} from 'helpers/dateHelper'
import useFailureModal from 'features/modal/useFailureModal'
import SelectBoldDoctor from '../BoldSelect/SelectBoldDoctor'
import SelectBoldYear from '../BoldSelect/SelectBoldYear'
import CheckUpItems from '../PatientAddDialog/fields/checkupItems'

export function calculateAge(birthDate: Date) {
  const today = new Date()
  const age = today.getFullYear() - birthDate.getFullYear()
  const month = today.getMonth() - birthDate.getMonth()
  if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
    return age - 1
  }
  return age
}

const PatientRegistrationWhiteListDialog = () => {
  const {onOpen: onNoticeDialogOpen} = useNoticeConfirm()
  const organizationType = storage.getOrganizationType()
  const uid = storage.getUserUid()
  const isHealthOrg = isOrganizationHealth(organizationType)
  const {t, i18n} = useTranslation()
  const classes = useStyles()
  const inputClasses = useBoldInputStyle()
  const {user: currentUser} = useAuth()
  const {userList: users} = useOrgUserList()
  const {onOpen: onFailureModalOpen} = useFailureModal()
  const {
    open: openRegistration,
    onClose,
    listProducts,
    onCreate,
    onCreateVisitNumber,
    onGetProducts,
  } = useWhiteListCreateDialog()
  const {idOver20Product} = useProductList()
  const [customerType, setCustomerType] = React.useState('')
  const [key, setKey] = React.useState(0)
  const [birth, setBirth] = React.useState<Date>()
  const [tabIndex, setTabIndex] = React.useState<number>(0)
  const [docNo] = useState('')
  const AddSchema: SchemaOf<PatientAddForm> = yup
    .object()
    .shape({
      birth: yup.date().typeError(t('IDateInvalid')),
      chartNo: yup.string().required(t('IChartNoRequired')),
      countryCode: yup.string().default('KR'),
      docNo: yup
        .string()
        .required(t('IDoctorRequired'))
        .test('not-zero', t('IDoctorRequired'), (value) => value !== '0'),
      gender: yup.string().required(t('IGenderRequired')),
      hand: yup.string().default('UNKNOWN'), // TODO: Set default data but not empty or null
      firstName: yup
        .string()
        .required(t('INameRequired'))
        .max(45, t('IMaxName', {max: 45})),
      lastName: yup
        .string()
        .required(t('INameRequired'))
        .max(20, t('IMaxName', {max: 20})),
      phone: yup.string().default('9999999999'),
      terms: yup.string().default(null).nullable(),
      healthProductId: isHealthOrg
        ? yup.number().nullable().required(t('IMessageRequiredProduct'))
        : yup.number().nullable().notRequired(),
      visitNumber: yup.string().required(t('IRegistrationNumberRequired')),
    })
    .defined()

  const {
    control,
    handleSubmit,
    formState: {errors},
    reset,
    setValue,
    setError,
    watch,
    clearErrors,
  } = useForm<PatientAddForm>({
    // @ts-ignore
    resolver: yupResolver(AddSchema),
  })
  const visitNumber = watch('visitNumber')

  const isAdult = useMemo(() => {
    if (!birth || !birth.getMonth) {
      return false
    }
    const Age = calculateAge(birth)
    return Number.isNaN(Age) ? false : Age >= 20
  }, [birth])

  const handleTabChanged = (
    event: React.ChangeEvent<Record<string, unknown>>,
    newValue: number,
  ) => {
    setTabIndex(newValue)
  }

  const handleBirthData = (data: Date) => {
    setBirth(data)
  }

  const handleCleanClose = () => {
    setCustomerType('')
    reset()
    onClose()
  }

  const disableElement = (disable: boolean) => {
    const elementBirth = document.getElementById(
      'WhiteListClientRegistrationBirth',
    )
    const elementGender = document.getElementById(
      'WhiteListClientRegistrationGender',
    )
    if (elementBirth) {
      elementBirth.style.pointerEvents = disable ? 'none' : 'auto'
      elementBirth.style.opacity = disable ? '0.7' : '1'
    }
    if (elementGender) {
      elementGender.style.pointerEvents = disable ? 'none' : 'auto'
      elementGender.style.opacity = disable ? '0.7' : '1'
    }
  }

  const handlePasteChartNo = async () => {
    try {
      const text = await navigator.clipboard.readText()
      setValue('chartNo', text)
      clearErrors('chartNo')
    } catch (err) {
      console.error('Failed to read clipboard contents: ', err)
    }
  }

  const callAPICreateNewClient = async (
    data: PatientAddForm,
    productId: number | null,
  ) => {
    disableElement(false)
    await onCreate({
      ...data,
      terms: null,
      birth: dateToDashString(birth ?? new Date()),
      healthProductId: productId,
      email: 'defaultValue@gmail.com',
      phone: '9999999999',
      hand: 'UNKNOWN',
      countryCode: 'KR',
    })
  }

  const setDefaultDocNo = () => {
    if (users) {
      const me: OrgSimpleUser | undefined = users.find(
        (item: OrgSimpleUser) => item.uid === uid,
      )
      if (me) {
        setValue('docNo', me.id?.toString())
      } else {
        setValue('docNo', '0')
      }
    }
  }

  const callAPICreateVisitNumber = async (
    response: PatientVisitNumber,
    data: PatientAddForm,
  ) => {
    const checkDocNoExited = Number(watch('docNo'))
    const productIdCreateVisitNumber =
      listProducts.find((item) => item.productId === watch('healthProductId'))
        ?.id || null
    await onCreateVisitNumber({
      birth: response.birth,
      gender: response.gender,
      healthProductId: productIdCreateVisitNumber,
      firstName: data.firstName,
      lastName: data.lastName,
      uuid: response.uuid,
      visitNumber: data.visitNumber ?? '',
      docNo: checkDocNoExited || String(users[0]?.id),
    } as CreateVisitNumberPatientRequest)
  }

  const onSubmit = async (data: PatientAddForm) => {
    setCustomerType('')
    try {
      const chartNo = watch('chartNo')
      const productId =
        listProducts.find((item) => item.productId === data.healthProductId)
          ?.id || null
      if (!chartNo) {
        return
      }
      const response = await doubleCheckPatientChartNoApi(chartNo)
      if (response.data) {
        setValue('docNo', '0')
        const checkDocNoExited = Number(watch('docNo'))
        setCustomerType('IExistingCustomer')
        disableElement(true)
        const responseDetailPatient = await fetchPatientApi({
          uuid: response.data.uuid,
        })
        const productIdVisitNumber =
          listProducts.find(
            (item) => item.id === responseDetailPatient.data.healthProductId,
          )?.productId || null
        setValue('birth', new Date(responseDetailPatient.data.birth))
        setBirth(new Date(responseDetailPatient.data.birth))
        setValue('gender', responseDetailPatient.data.gender)
        setValue('firstName', responseDetailPatient.data.firstName)
        setValue('lastName', responseDetailPatient.data.lastName)
        setValue(
          'docNo',
          checkDocNoExited
            ? checkDocNoExited.toString()
            : responseDetailPatient?.data?.primaryId?.toString(),
        )
        const checkHealthProductId = watch('healthProductId')
        if (!checkHealthProductId)
          setValue('healthProductId', productIdVisitNumber)
      }
      if (!isAdult && idOver20Product.includes(data.healthProductId || 0)) {
        setError('healthProductId', {
          type: 'manual',
          message: t('IErrorMessageAIDementiaPrediction'),
        })
        setValue('healthProductId', null)
        return
      }
      if (!visitNumber) return
      const {docNo} = data
      if (isNotDefined(docNo)) {
        return
      }
      if (response.data) {
        await callAPICreateVisitNumber(response.data, data)
        handleCleanClose()
      }
      if (!response.data) {
        await callAPICreateNewClient(data, productId)
        handleCleanClose()
      }
    } catch (err) {
      onFailureModalOpen(err)
    }
  }

  const handleDoubleCheckChartNo = async () => {
    setCustomerType('')
    const chartNo = watch('chartNo')
    if (!chartNo) return
    try {
      const response = await doubleCheckPatientChartNoApi(chartNo)
      if (response.data) {
        setValue('docNo', '0')
        const checkDocNoExited = Number(watch('docNo'))
        setCustomerType('IExistingCustomer')
        const responseDetailPatient = await fetchPatientApi({
          uuid: response.data.uuid,
        })
        const productIdVisitNumber =
          listProducts.find(
            (item) => item.id === responseDetailPatient.data.healthProductId,
          )?.productId || null
        setValue('birth', new Date(responseDetailPatient.data.birth))
        setBirth(new Date(responseDetailPatient.data.birth))
        setKey(key + 1)
        setValue('gender', responseDetailPatient.data.gender)
        setValue('firstName', responseDetailPatient.data.firstName)
        setValue('lastName', responseDetailPatient.data.lastName)
        setValue(
          'docNo',
          checkDocNoExited
            ? checkDocNoExited?.toString()
            : responseDetailPatient?.data?.primaryId?.toString(),
        )
        const checkHealthProductId = watch('healthProductId')
        if (!checkHealthProductId)
          setValue('healthProductId', productIdVisitNumber)
        disableElement(true)
      } else {
        setCustomerType('INewCustomer')
        setValue('firstName', '')
        setValue('lastName', '')
        disableElement(false)
        setValue('birth', new Date())
        setBirth(new Date())
        setDefaultDocNo()
        setKey(key + 1)
        setValue('gender', 'M')
        setValue('healthProductId', null)
      }
    } catch (error) {
      onFailureModalOpen(error)
    }
  }

  useEffect(() => {
    return () => {
      reset()
    }
  }, [openRegistration])

  useEffect(() => {
    setDefaultDocNo()
  }, [users])

  useEffect(() => {
    if (currentUser?.orgId) {
      onGetProducts(currentUser?.orgId?.toString())
    }
  }, [currentUser])

  return (
    <Dialog
      open={openRegistration}
      onClose={onClose}
      className={classes.container}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.root}>
          {/* 상단 타이틀 컨테이너  */}
          <div className={classes.longTitleContainer}>
            <div className={classes.containerTitle}>
              <div className={classes.closeButtonWrap}>
                <IconButton
                  color='secondary'
                  aria-label='favorite'
                  className={classes.closeButton}
                  onClick={() => {
                    onNoticeDialogOpen({
                      title: '',
                      message: t('ICancleConfirm'),
                    }).then((result) => {
                      if (result.payload) {
                        handleCleanClose()
                      }
                    })
                  }}
                >
                  <CloseIcon className={classes.closeIcon} />
                </IconButton>
              </div>
              <Typography variant='h6' className={classes.title}>
                {t('ICustomerCreate')}
              </Typography>
            </div>
          </div>
          <Divider className={classes.titleDivider} />
          <DialogContent className={classes.contentWrap}>
            <Tabs
              value={tabIndex}
              indicatorColor='primary'
              textColor='primary'
              onChange={handleTabChanged}
              aria-label='disabled tabs example'
            >
              <Tab label={t('IBaseInfo')} />
            </Tabs>
            <TabPanel value={tabIndex} index={0}>
              <div className={classes.infoContainer}>
                <Typography className={inputClasses.inputLabel}>
                  {t('IChartNo')}
                </Typography>
                <Typography className={classes.smallPadding} />
                <div style={{display: 'flex', flexDirection: 'row'}}>
                  <Controller
                    name='chartNo'
                    control={control}
                    render={({field}) => (
                      <input
                        className={[
                          `${inputClasses.input}`,
                          `${errors.chartNo ? classes.error : ''}`,
                        ].join(' ')}
                        placeholder={t('IChartNo')}
                        {...field}
                        value={field.value ?? ''}
                      />
                    )}
                  />
                  <Typography className={classes.padding} />
                  <Button
                    variant='contained'
                    color='primary'
                    className={classes.okButtonColor}
                    onClick={handlePasteChartNo}
                  >
                    {t('IPaste')}
                  </Button>
                  <Typography className={classes.padding} />
                  <Button
                    variant='contained'
                    color='secondary'
                    className={classes.okButtonColor}
                    onClick={handleDoubleCheckChartNo}
                  >
                    {t('IDoubleCheck')}
                  </Button>
                </div>
                <span className={classes.errorText}>
                  {errors.chartNo && t(errors.chartNo.message ?? '')}
                </span>
                <span className={classes.typeMemberText}>
                  {t(customerType ?? '')}
                </span>
              </div>

              <div className={classes.infoContainer}>
                <Typography className={inputClasses.inputLabel}>
                  {t('IRegistrationNumber')}
                </Typography>
                <Typography className={classes.smallPadding} />

                <div>
                  <Controller
                    name='visitNumber'
                    control={control}
                    render={({field}) => (
                      <input
                        className={[
                          `${inputClasses.input}`,
                          `${errors.visitNumber ? classes.error : ''}`,
                        ].join(' ')}
                        style={{width: '100%'}}
                        {...field}
                      />
                    )}
                  />
                </div>

                <span className={classes.errorText}>
                  {errors.visitNumber && t(errors.visitNumber.message ?? '')}
                </span>
              </div>

              <div className={classes.infoContainer}>
                <Typography className={inputClasses.inputLabel}>
                  {t('IName')}
                </Typography>
                <Typography className={classes.smallPadding} />
                {(i18n.language === 'ko' || i18n.language === 'ja') && (
                  <div className={classes.clientName}>
                    <div className={classes.clientNameElm}>
                      <Controller
                        name='lastName'
                        control={control}
                        render={({field}) => (
                          <input
                            className={[
                              `${inputClasses.input}`,
                              `${errors.lastName ? classes.error : ''}`,
                            ].join(' ')}
                            placeholder={t('ILastName')}
                            {...field}
                          />
                        )}
                      />
                      <Controller
                        name='firstName'
                        control={control}
                        render={({field}) => (
                          <input
                            className={[
                              `${inputClasses.input}`,
                              `${errors.firstName ? classes.error : ''}`,
                            ].join(' ')}
                            placeholder={t('IFirstName')}
                            {...field}
                          />
                        )}
                      />
                    </div>
                    <div className={classes.clientNameElm}>
                      <span className={classes.errorName}>
                        {errors.lastName && t(errors.lastName.message ?? '')}
                      </span>
                      <span className={classes.errorName}>
                        {errors.firstName && t(errors.firstName.message ?? '')}
                      </span>
                    </div>
                  </div>
                )}
                {i18n.language !== 'ko' && i18n.language !== 'ja' && (
                  <div className={classes.clientName}>
                    <div className={classes.clientNameElm}>
                      <Controller
                        name='firstName'
                        control={control}
                        render={({field}) => (
                          <input
                            className={[
                              `${inputClasses.input}`,
                              `${errors.firstName ? classes.error : ''}`,
                            ].join(' ')}
                            placeholder={t('IFirstName')}
                            {...field}
                          />
                        )}
                      />
                      <Controller
                        name='lastName'
                        control={control}
                        render={({field}) => (
                          <input
                            className={[
                              `${inputClasses.input}`,
                              `${errors.lastName ? classes.error : ''}`,
                            ].join(' ')}
                            placeholder={t('ILastName')}
                            {...field}
                          />
                        )}
                      />
                    </div>
                    <div className={classes.clientNameElm}>
                      <div className={classes.errorName}>
                        {errors.firstName && t(errors.firstName.message ?? '')}
                      </div>
                      <div className={classes.errorName}>
                        {errors.lastName && t(errors.lastName.message ?? '')}
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div
                className={classes.infoContainer}
                id='WhiteListClientRegistrationBirth'
              >
                <Typography className={inputClasses.inputLabel}>
                  {t('IBirthday')}
                </Typography>
                <Typography className={classes.smallPadding} />
                <SelectBoldYear
                  key={key}
                  onDateChange={(e) => handleBirthData(e)}
                  oldDate={birth ?? new Date()}
                />
              </div>

              <div
                className={classes.infoContainer}
                id='WhiteListClientRegistrationGender'
              >
                <Typography className={inputClasses.inputLabel}>
                  {t('IGender')}
                </Typography>
                <Typography className={classes.smallPadding} />

                <Controller
                  name='gender'
                  control={control}
                  defaultValue={Gender.MALE}
                  render={({field}) => (
                    <RadioGroup
                      row
                      className={classes.radioContainer}
                      {...field}
                    >
                      {GenderSelectItems.map((gender) => (
                        <FormControlLabel
                          control={<Radio color='primary' />}
                          label={
                            <p style={{marginRight: '30px'}}>
                              {t(gender.label)}
                            </p>
                          }
                          value={gender.value}
                        />
                      ))}
                    </RadioGroup>
                  )}
                />
              </div>

              <div className={classes.infoContainer}>
                <Typography className={inputClasses.inputLabel}>
                  {t('ICareDoctor')}
                </Typography>
                <Typography className={classes.smallPadding} />

                <Controller
                  name='docNo'
                  control={control}
                  defaultValue={docNo}
                  render={({field}) => (
                    <SelectBoldDoctor error={!!errors.docNo} {...field} />
                  )}
                />
                <span className={classes.errorText}>
                  {errors.docNo && t(errors.docNo.message ?? '')}
                </span>
              </div>

              <CheckUpItems
                control={control}
                products={listProducts}
                errors={errors}
              />
            </TabPanel>
            <Typography className={classes.smallPadding} />
          </DialogContent>
          {/* 하단 버튼 컨테이너  */}
          <Typography className={classes.smallPadding} />
          <div className={classes.buttonContainerBgColor}>
            <div className={classes.buttonContainer}>
              <Button
                variant='contained'
                color='default'
                disableElevation
                onClick={() => {
                  onNoticeDialogOpen({
                    title: '',
                    message: t('ICancleConfirm'),
                  }).then((result) => {
                    if (result.payload) {
                      handleCleanClose()
                    }
                  })
                }}
                className={classes.okButton}
              >
                {t('ICancel')}
              </Button>
              <Typography className={classes.smallPadding} />
              <Button
                variant='contained'
                color='primary'
                disableElevation
                className={classes.okButtonColor}
                type='submit'
              >
                {t('IOk')}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </Dialog>
  )
}

export default PatientRegistrationWhiteListDialog
