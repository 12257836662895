import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {
  MontageChannelLabels,
  MontageFilterType,
} from 'lib/EdfGraph/MontageFilter'
import {TypeScreenGraph, TypeGraph} from 'api/analysisApi'

const StyledHorizontalImageGraph = styled.div<{yAxisImgSize: number}>`
  position: relative;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;

  & .Left {
    position: sticky;
    width: auto;
    height: 572px;
    z-index: 1;
    top: 0;
    left: 0;

    & img {
      max-width: ${(props) => props.yAxisImgSize}px;
      height: 100%;
    }
  }
  & .Montage {
    display: flex;
    flex-direction: column;
    padding-top: 33px;
    background: #fff;
    height: 572px;
    font-size: 14px;
  }
  & .Raw {
    width: 47px;
    font-weight: 700;
  }
  & .Component {
    width: 45px;
    text-align: right;
    font-weight: 700;
  }
  & .Cleaned {
    width: 48px;
    font-weight: 700;
  }
  & .MontageItem {
    height: 25.4px;
    font-weight: 400;
    text-align: right;
    padding-right: 3px;
  }
  & .Right {
    position: absolute;
    height: 572px;
    top: 0;
    left: 0;

    & img {
      width: auto;
      height: 100%;
      vertical-align: middle;
    }
  }
`

export interface HorizontalImageGraphProps {
  xAxisImg: string
  yAxisImgSize?: number
  screen: TypeScreenGraph
  typeGraph: TypeGraph
}

export function HorizontalImageGraph({
  xAxisImg,
  yAxisImgSize = 72,
  screen,
  typeGraph,
}: HorizontalImageGraphProps) {
  const [listMontage, setListMontage] = useState(MontageChannelLabels[0])

  const renderClassName = () => {
    if (typeGraph === 'raw' && screen === 'norm') {
      return 'Cleaned'
    }
    if (typeGraph === 'raw') {
      return 'Raw'
    }
    if (typeGraph === 'component') {
      return 'Component'
    }

    return 'Cleaned'
  }
  useEffect(() => {
    if (screen === 'individual' && typeGraph === 'cleaned') {
      setListMontage([...MontageChannelLabels[MontageFilterType.CAR]])
    }
    if (screen === 'individual' && typeGraph === 'raw') {
      setListMontage([...MontageChannelLabels[MontageFilterType.NONE]])
    }
    // Raw Data는 Ear Reference 를 사용하면 되고요
    if (screen === 'norm' && typeGraph === 'raw') {
      setListMontage([...MontageChannelLabels[MontageFilterType.NONE]])
    }
    // Cleaned data/ Cleaened data with bad epoch 는 Longitudinal 사용하면 됩니다.
    if (screen === 'norm' && typeGraph === 'cleaned') {
      setListMontage([...MontageChannelLabels[MontageFilterType.CAR]])
    }
    if (typeGraph === 'component') {
      setListMontage([...MontageChannelLabels[MontageFilterType.Component]])
    }
  }, [screen, typeGraph])

  return (
    <StyledHorizontalImageGraph yAxisImgSize={yAxisImgSize}>
      <div className='Left'>
        <div className={`${renderClassName()} Montage`}>
          {listMontage.map((item) => (
            <span className='MontageItem' key={item.label}>
              {item.label}
            </span>
          ))}
        </div>
      </div>
      <div className='Right'>
        <img src={xAxisImg} alt='Graph Content' />
      </div>
    </StyledHorizontalImageGraph>
  )
}
