import {useHistory, useLocation} from 'react-router-dom'
import {useAppDispatch, useAppSelector} from 'hooks'
import {
  fetchSaleAppNotice,
  fetchUserNotice,
  selectError,
  selectList,
  selectLoading,
  selectPaging,
  selectPagingInfo,
  selectQuery,
} from 'features/sales/saleAppNoticeSlice'

export default function useSaleAppNotices() {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const location = useLocation()

  const loading = useAppSelector(selectLoading)
  const error = useAppSelector(selectError)
  const items = useAppSelector(selectList)
  const query = useAppSelector(selectQuery)
  const paging = useAppSelector(selectPaging)
  const pagingInfo = useAppSelector(selectPagingInfo)

  const onSearch = (payload: PagingRequest) => {
    try {
      const params = new URLSearchParams(
        Object.entries(payload).map(([key, value]) => [
          key,
          typeof value === 'object' ? JSON.stringify(value) : value,
        ]),
      )
      history.replace({pathname: location.pathname, search: params.toString()})
    } catch (error) {
      console.error('Update URLSearchParams failed', error)
    }
    return dispatch(fetchSaleAppNotice(payload))
  }

  const onSearchUser = (payload: PagingRequest) => {
    try {
      const params = new URLSearchParams(
        Object.entries(payload).map(([key, value]) => [
          key,
          typeof value === 'object' ? JSON.stringify(value) : value,
        ]),
      )
      history.replace({pathname: location.pathname, search: params.toString()})
    } catch (error) {
      console.error('Update URLSearchParams failed', error)
    }
    return dispatch(fetchUserNotice(payload))
  }

  const onRefresh = (param?: PagingRequest) =>
    dispatch(
      fetchSaleAppNotice({
        ...(param ?? query),
      }),
    )

  return {
    loading,
    error,
    items,
    query,
    paging,
    pagingInfo,
    onSearch,
    onSearchUser,
    onRefresh,
  }
}
