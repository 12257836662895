import {MontageFilterType} from 'lib/EdfGraph/MontageFilter'
import Colors from 'theme/Colors'
import styled from 'styled-components'

export interface CanvasContainerProps {
  CANVAS_CONTAINER_WIDTH: number
}

export const CanvasContainer = styled.div<CanvasContainerProps>`
  width: ${(props) => `${props.CANVAS_CONTAINER_WIDTH}px`};
  display: flex;
`
export const SecondsParentContainer = styled.div<CanvasContainerProps>`
  width: ${(props) => `${props.CANVAS_CONTAINER_WIDTH}px`};
  display: flex;
`
export const CanvasParentContainer = styled.div<CanvasContainerProps>`
  width: ${(props) => `${props.CANVAS_CONTAINER_WIDTH}px`};
  overflow-x: scroll;
  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`
export const SecondsContainer = styled.div<{ContainerWidth: number}>`
  width: ${(props) => `${props.ContainerWidth}px`};
`
export const ChannelContainer = styled.div<{ContainerHeight: number}>`
  height: ${(props) => `${props.ContainerHeight}px`};
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 10px 0 10px 8px;
`
export const SubContainer = styled.div`
  display: flex;
  gap: 2px;
`
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`
export const ChannelSpan = styled.span`
  margin: 0 4px;
  font-size: 12px;
  text-align: right;
  width: 58px;
`

export const TooltipTopContainer = styled.div`
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  opacity: 1;
  padding-left: 104px;
  padding-right: 24px;
  padding-bottom: 15px;
  background: #ffffff77;
  overscroll-behavior: none;
`
export const TooltipCenterContainer = styled.div`
  width: 100px;
  height: 100px;
  top: 50%;
  left: 50%;
  margin: -50px 0 0 -50px;
  display: flex;
  opacity: 1;
`

export const TooltipBottomContainer = styled.div`
  display: flex;
  position: relative;
  opacity: 1;
  background: #ffffff77;
  left: 0;
  right: 0;
  bottom: 0;
  padding-left: 76px;
  padding-right: 0;
  color: white;
  height: 60px;
`

export const TooltipLegend = styled.div`
  display: flex;
  > * {
    flex-direction: column;
    justify-content: center;
    display: flex;
  }
`

export const TooltipScale = styled.div`
  margin: 0 0 0 auto;
  display: flex;
  > * {
    flex-direction: column;
    justify-content: center;
    display: flex;
  }
`

export const StyledContentTitle = styled.p`
  font-size: 13px;
  color: #606060;
  font-weight: 700;
  margin-right: 8px;
  margin-left: 8px;
`
export interface DropDownStructure {
  optionTitle: string
  optionValue: number
}

export const StyledRangeWrap = styled.div`
  display: flex;
  width: 100%;
`

export const StyledControlsWrap = styled.div`
  display: flex;
  width: 100%;
  margin-top: 16px;
  justify-content: space-between;

  & input[type='range'] {
    -webkit-appearance: none;
    height: 7px;
    background: grey;
    border-radius: 5px;
    background-image: linear-gradient(#d9dce0, #d9dce0);
    background-repeat: no-repeat;
  }

  & input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background: ${Colors.common.primary};
    cursor: pointer;
    box-shadow: 0 0 2px 0 #555;
  }
`
export const height = '36px'
export const StyledControlsInput = styled.input`
  display: flex;
  width: 100%;
  appearance: none;
  margin: 0;
  height: ${height};
  cursor: pointer;
  background-color: #f2f2f2;
`

export const StyledPlayButton = styled.button`
  border: none;
  opacity: 0.5;
  position: absolute;
  left: 36px;
  top: 4px;
`
export const StyledPlayButtonImage = styled.img`
  width: 30px !important;
  height: 30px !important;
`

export const heightOptions: DropDownStructure[] = [
  {optionTitle: '20uV', optionValue: 20},
  {optionTitle: '30uV', optionValue: 30},
  {optionTitle: '50uV', optionValue: 50},
  {optionTitle: '70uV', optionValue: 70},
  {optionTitle: '100uV', optionValue: 100},
  {optionTitle: '150uV', optionValue: 150},
  {optionTitle: '200uV', optionValue: 200},
  {optionTitle: '300uV', optionValue: 300},
  {optionTitle: '500uV', optionValue: 500},
  {optionTitle: '700uV', optionValue: 700},
]

export const widthOptions: DropDownStructure[] = [
  {optionTitle: 'X 0.5', optionValue: 0.5},
  {optionTitle: 'X 0.75', optionValue: 0.75},
  {optionTitle: 'X 1.0', optionValue: 1},
  {optionTitle: 'X 1.25', optionValue: 1.25},
  {optionTitle: 'X 1.5', optionValue: 1.5},
  {optionTitle: 'X 2.0', optionValue: 2.0},
]

export const montageOptions: DropDownStructure[] = [
  {optionTitle: 'Common Average', optionValue: MontageFilterType.CAR},
  {optionTitle: 'Longitudinal', optionValue: MontageFilterType.BANANA},
  {optionTitle: 'Transverse', optionValue: MontageFilterType.TRANSVERSE},
]

export type EegType = 'RAW' | 'CLEANED'

export const montageForType = (edfType: EegType) => {
  const arr = montageOptions
  if (edfType === 'RAW') {
    return [
      {optionTitle: 'Ear Reference', optionValue: MontageFilterType.NONE},
      ...arr,
    ]
  }

  return arr
}
