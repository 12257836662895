import {createStyles, makeStyles} from '@material-ui/core/styles'

const usePinDialogStyles = makeStyles(
  createStyles({
    root: {
      '& .MuiDialog-paper': {
        minWidth: 530,
        maxWidth: 530,
      },
      '& .MuiDialogTitle-root': {padding: 0},
      '& .MuiDialogContent-root': {padding: 0},
      '& .MuiDialogActions-root': {padding: 10},
      '& .field': {
        marginBottom: 8,
        '&:last-child': {
          marginBottom: 0,
        },
      },
      '& .MuiInputBase-input': {
        background: 'white',
      },
    },
    longContainer: {
      display: 'flex',
      alignItems: 'center',
      padding: 20,
      justifyContent: 'center',
      flexWrap: 'wrap',
      flexDirection: 'row',
      maxWidth: '500px',

      '& h5': {
        fontWeight: 'bolder',
      },
      '& h6': {
        fontSize: 15,
        fontWeight: 400,
        textAlign: 'left',
        flexWrap: 'wrap',
        color: '#868e96',
      },
      '& TextField': {
        width: 140,
      },
    },
    noticeText: {
      fontSize: 10,
      color: '#868e96',
      textAlign: 'center',
      marginTop: 20,
      whiteSpace: 'pre',
    },
    scroll: {
      display: 'flex',
      marginLeft: 10,
      flexGrow: 1,
      overflow: 'scroll',
      maxHeight: '450px',
      minHeight: '100px',
    },
    longTitleContainer: {
      display: 'flex',
      alignItems: 'center',
      flex: '1 1 0',
      justifyContent: 'space-between',
      color: 'primary',
      background: 'white',
      '& button': {
        margin: 3,
      },
    },
    okButtonColor: {
      color: 'white',
    },
    hidden: {
      visibility: 'hidden',
    },
    iconTitleWrap: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: 5,

      '& h6': {
        fontSize: 17,
        fontWeight: 400,
        marginLeft: 20,
      },
    },
    textField: {
      backgroundColor: 'white',
    },
    icon: {
      color: 'rgba(0, 0, 0, 0.5)',
    },
    actionButtonWrap: {
      background: '#f4f4f4',
    },
    pinNumberTextField: {
      width: 170,
      marginTop: 14,
      '& .MuiInputBase-input': {
        background: 'white',
        color: '#868e96',
      },
      '& label.Mui-focused': {
        color: 'red',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#e9ecef',
        },
      },
    },
    pinDepartmentTextField: {
      width: 250,
      '& .MuiInputBase-input': {
        background: 'white',
        color: '#868e96',
      },
      '& label.Mui-focused': {
        color: 'red',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#e9ecef',
        },
      },
      '& .MuiFormHelperText-contained': {
        margin: 0,
      },
    },
    pinNumberContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-end',
      // justifyItems: '',
      background: 'white',
      margin: '15px 30px',
      flex: 1,
      '& h6': {
        fontSize: 15,
        fontWeight: 400,
        textAlign: 'left',
        flexWrap: 'wrap',
        color: '#868e96',
      },
    },
    pinNumberWrap: {
      padding: 10,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-end',
      flex: 1,
      width: '100%',
    },
    labelText: {
      marginTop: 4,
      paddingRight: 10,
    },
    paymentContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      flex: 1,
      padding: '5px 20px',
      '& .title': {
        fontSize: 13,
        fontWeight: 400,
        textAlign: 'left',
        flexWrap: 'wrap',
        color: '#5a6268',
      },
      '& .content': {
        fontSize: 14,
        fontWeight: 600,
        textAlign: 'left',
        flexWrap: 'wrap',
        color: '#5a6268',
      },
    },
    createDepartmentContainer: {
      display: 'flex',
      width: 350,
      alignItems: 'start',
      justifyContent: 'center',
      gap: 20,
      padding: '20px 0',
    },
    titleCreateDepartment: {
      display: 'flex',
      alignItems: 'center',
      paddingTop: 8,
    },
  }),
)

export default usePinDialogStyles
