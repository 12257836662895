/* eslint-disable import/prefer-default-export */
export const getFileType = (file: File): 'image' | 'web' | 'file' => {
  const mimeType = file.type
  if (mimeType.startsWith('image/')) {
    return 'image'
  }
  if (mimeType === 'text/html' || mimeType === 'application/xhtml+xml') {
    return 'web'
  }
  return 'file'
}
