import React, {useEffect, useMemo} from 'react'
import {useHistory} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'
import {useAppDispatch} from 'hooks'
import useNoticeConfirm from 'features/modal/useNoticeConfirm'
import NoticeConfirmDialog from 'components/Dialog/NoticeConfirmDialog'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from 'components/Table/TableCell'
import {Colors, Spacing} from 'components/common/useBaseStyle'
import Pagination from 'components/molcules/Pagination'
import useSaleAppNotices from 'features/sales/useSaleAppNotice'
import {deleteSaleAppNotice} from 'features/sales/saleAppNoticeSlice'
import {makeDateAndTimeFormat} from 'helpers/dateHelper'
import {SaleAppNotice} from 'api/salesNoticeApi'
import {getSalesAppNoticeDetail} from 'constants/RouteConstant'
import {TYPE_STATUS_OPTIONS} from 'pages/SalesPages/SalesAppNoticePage/constants'
import useStyles from 'components/Table/useTableStyles'

const DeleteButton = styled.button`
  width: ${Spacing.notice.noticeDeleteButtonWidth}px;
  height: ${Spacing.notice.noticeDeleteButtonHeight}px;
  color: ${Colors.notice.noticeDeleteButtonColor};
  font-size: ${Spacing.notice.noticeDeleteButtonFontSize}px;
  font-weight: bold;
  border: none;
  border-radius: ${Spacing.notice.noticeDeleteButtonBorderRadius}px;
  border: solid 1px ${Colors.pagination.border};
  background-color: ${Colors.deleteButtonBackgroundColor};
`
interface RenderTableRowProps {
  item: SaleAppNotice
  onClick: (item: SaleAppNotice) => void
  onDelete: (index: number) => void
}
function RenderTableRow({item, onClick, onDelete}: RenderTableRowProps) {
  const {t, i18n} = useTranslation()
  const handleClick = () => onClick(item)

  const getTitle = useMemo(() => {
    if (!item || !item.translations) return '-'
    return (
      item.translations.find((tran) => tran.lang === i18n.language)?.title ?? ''
    )
  }, [item, i18n.language])

  const getStatuses = useMemo(() => {
    return t(
      TYPE_STATUS_OPTIONS.find(
        (status) => status.value === item?.attribute?.status,
      )?.label ?? '',
    )
  }, [item, i18n.language])

  const getPeriodTime = useMemo(() => {
    if (!item || !item.attribute) return '-'
    if (item.attribute.periodStartTime && item.attribute.periodEndTime) {
      const startAt = new Date(item.attribute.periodStartTime)
      const endAt = new Date(item.attribute.periodEndTime)
      return `${makeDateAndTimeFormat(startAt)} ~ ${makeDateAndTimeFormat(
        endAt,
      )}`
    }
    return '-'
  }, [item])

  const getTypeNotices = useMemo(() => {
    switch (item.type) {
      case 'maintenance':
        return t('IMaintenanceNoticeType')
      case 'general':
        return t('IGeneralNoticeType')
      default:
        return '-'
    }
  }, [item, i18n.language])

  return (
    <TableRow hover>
      <TableCell align='center'>{item.noticeId}</TableCell>
      <TableCell align='center'>{getTypeNotices}</TableCell>
      <TableCell
        align='center'
        onClick={handleClick}
        style={{
          cursor: 'pointer',
          maxWidth: 400,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {getTitle}
      </TableCell>
      <TableCell align='center'>{getPeriodTime}</TableCell>
      <TableCell align='center'>{getStatuses ?? '-'}</TableCell>
      <TableCell align='center'>
        {item.createdAt ? makeDateAndTimeFormat(new Date(item.createdAt)) : '-'}
      </TableCell>
      <TableCell align='center'>
        <DeleteButton
          style={{
            paddingLeft: `${Spacing.marginCustom}px`,
            paddingRight: `${Spacing.marginCustom}px`,
          }}
          onClick={() => onDelete(item.noticeId)}
        >
          {t('IDelete')}
        </DeleteButton>
      </TableCell>
    </TableRow>
  )
}

export default function TableNotice() {
  const {t} = useTranslation()
  const history = useHistory()
  const classes = useStyles()
  const {items, onSearch, pagingInfo, paging, loading, query} =
    useSaleAppNotices()

  const dispatch = useAppDispatch()
  const {onOpen: onNoticeDialogOpen} = useNoticeConfirm()
  React.useState<SaleAppNotice[]>()
  const noticeTableHead = [
    'No',
    'IDivision',
    'INotices',
    'IPeriod',
    'IStatus',
    'ICreateDate',
    'IDelete',
  ]
  const header = useMemo(() => noticeTableHead, [])
  const emptyRows = items === null ? 5 : 5 - items.length

  const handleDelete = (index: number) => {
    onNoticeDialogOpen({
      title: t('INoticeTitle'),
      message: t('IDeleteConfirmMessage'),
    }).then((result) => {
      if (result.payload) dispatch(deleteSaleAppNotice(index))
    })
  }

  const handleClickRow = (item: SaleAppNotice) => {
    history.push(getSalesAppNoticeDetail(item.noticeId))
  }

  const setPageIndex = (page: number) => {
    onSearch({
      paging: {
        page,
        size: paging.size,
      },
    })
  }

  const onSizeChange = (
    event: React.ChangeEvent<{name?: string; value: unknown}>,
  ) => {
    const size = parseInt(event.target.value as string, 10)
    onSearch({
      paging: {
        page: 0,
        size,
      },
    })
  }

  useEffect(() => {
    onSearch({paging})
  }, [])

  return (
    <>
      <NoticeConfirmDialog />
      <TableContainer className={classes.tableContainer}>
        <Table className={classes.table} size='small'>
          <TableHead>
            <TableRow className={classes.tableHeader}>
              {header.map((value) => (
                <TableCell align='center'>{t(`${value}`)}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {items &&
              items.map((item) => (
                <RenderTableRow
                  key={item.noticeId}
                  item={item}
                  onClick={handleClickRow}
                  onDelete={handleDelete}
                />
              ))}
            {emptyRows > 0 && (
              <TableRow
                style={{height: 43 * emptyRows, backgroundColor: '#F9F9FB'}}
              >
                <TableCell colSpan={7} />
              </TableRow>
            )}
          </TableBody>
        </Table>
        <Pagination
          totalPageCount={pagingInfo.totalPages}
          currentPageIndex={query.paging.page ?? 0}
          itemCountPerPage={query.paging.size ?? 10}
          setCurrentPageIndex={setPageIndex}
          loading={loading}
          onItemCountPerPageChanged={onSizeChange}
        />
      </TableContainer>
    </>
  )
}
