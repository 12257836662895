import {useHistory, useLocation} from 'react-router-dom'
import {useAppDispatch, useAppSelector} from 'hooks'
import {TherapyAddRequest, TherapyEditRequest} from 'api/therapyApi'
import {
  openReadDialog,
  selectReadDialog,
  closeReadDialog,
  createTherapy,
  selectError,
  selectQuery,
  selectLoading,
  selectUpdateDialog,
  openUpdateDialog,
  selectCreateDialog,
  openCreateDialog,
  closeCreateDialog,
  closeUpdateDialog,
  updateTherapy,
  openRecentReadDialog,
  closeRecentReadDialog,
  selectRecentReadDialog,
  moveRecentReadDialogIndexPrev,
  moveRecentReadDialogIndexNext,
  moveRecentReadDialogIndex,
} from 'features/therapy/therapySlice'
import useTherapy from './useTherapy'
import {MultiTherapyHistory} from '../../../@types'

// CYM : 테라피 TherapyReadDialog
export function useReadDialog() {
  const dispatch = useAppDispatch()

  const {reservation, open, uuid} = useAppSelector(selectReadDialog)
  const loading = useAppSelector(selectLoading)
  const error = useAppSelector(selectError)
  const query = useAppSelector(selectQuery)

  const onOpen = (uuid: string) => dispatch(openCreateDialog(uuid))
  const onClose = () => dispatch(closeReadDialog())

  const onPrev = () => dispatch(moveRecentReadDialogIndexPrev())
  const onNext = () => dispatch(moveRecentReadDialogIndexNext())

  const onFetch = (uid: string, uuid: string) => dispatch(closeReadDialog())

  return {
    open,
    onFetch,
    onOpen,
    onClose,
    onPrev,
    onNext,
    query,
    error,
    loading,
    reservation,
    uuid,
  }
}

// CYM : 테라피 TherapyEditDialog
export function useUpdateDialog() {
  const dispatch = useAppDispatch()

  const {open, uuid, reservation} = useAppSelector(selectUpdateDialog)

  const onOpen = (uuid: string) => dispatch(openUpdateDialog(uuid))
  const onClose = () => dispatch(closeUpdateDialog())
  const onUpdate = (payload: TherapyEditRequest) =>
    dispatch(updateTherapy(payload))

  return {
    open,
    uuid,
    reservation,
    onOpen,
    onClose,
    onUpdate,
  }
}

// CYM : 테라피 TherapyAddDialog
export function useCreateDialog() {
  const dispatch = useAppDispatch()

  const {reservation, open, uuid} = useAppSelector(selectCreateDialog)

  const onOpen = (uuid: string) => dispatch(openCreateDialog(uuid))
  const onClose = () => dispatch(closeCreateDialog())
  const onCreate = (payload: TherapyAddRequest) =>
    dispatch(createTherapy(payload))

  return {
    reservation,
    open,
    uuid,
    onOpen,
    onClose,
    onCreate,
  }
}

// CYM : 테라피 TherapyRecentReadDialog
export function useRecentReadDialog() {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const location = useLocation()

  const {recentList, open, selectedIdx} = useAppSelector(selectRecentReadDialog)

  const onOpen = (recentList: MultiTherapyHistory) =>
    dispatch(openRecentReadDialog(recentList))
  const onClose = () => {
    dispatch(closeRecentReadDialog())
    history.replace({pathname: location.pathname})
  }

  const onPrev = () => dispatch(moveRecentReadDialogIndexPrev())
  const onNext = () => dispatch(moveRecentReadDialogIndexNext())
  const onIndex = (idx: number) => dispatch(moveRecentReadDialogIndex(idx))

  return {
    recentList,
    selectedIdx,
    open,
    onOpen,
    onClose,
    onPrev,
    onNext,
    onIndex,
  }
}
