import React, {useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory, useParams} from 'react-router-dom'
import {
  Button,
  MenuItem,
  OutlinedInput,
  Select,
  makeStyles,
} from '@material-ui/core'
import {Locale} from 'components/atoms/TranslationSelect'
import Header from 'pages/HealthCenter/Report/Elements/Header'
import HealthCenterMajorIndicators from 'pages/HealthCenter/Report/HealthCenterMajorIndicators'
import Logo from 'pages/HealthCenter/Report/Elements/logo.svg'
import IconDownload from 'pages/HealthCenter/Report/Elements/IconDownload.svg'
import HealthCenterReportStyle from 'pages/HealthCenter/Report/Style'
import HealthCenterHeartRateAnalysisPage from 'pages/HealthCenter/Report/HealthCenterHeartRateAnalysis'
import BrainFunctionBasic from 'pages/HealthCenter/Report/BrainFunctionBasic'
import BrainFunctionalConnectivity from 'pages/HealthCenter/Report/BrainFunctionalConnectivity'
import AIDementiaPrediction from 'pages/HealthCenter/Report/AIDementiaPrediction'
import {
  HealthReport,
  getDataHealthReportApi,
  getHealthProductsApi,
} from 'api/healthCareApi'
import RouteConstant from 'constants/RouteConstant'
import {GenderValue, toGenderString} from 'constants/CommonConstant'
import {LIST_HEALTH_PRODUCT_TAB} from 'constants/AnalysisConstant'
import BrainFunctionMappingPage from 'pages/HealthCenter/Report/BrainFunctionMapping'
import WrapPrintPage from 'pages/HealthCenter/Report/Elements/WrapPrintPage'

const useStyles = makeStyles(HealthCenterReportStyle)

const HealthCenterReport = () => {
  const {t, i18n} = useTranslation()
  const classes = useStyles()
  const [currentLocale, setCurrentLocale] = useState<Locale>('ko')
  const [tabActive, setTabActive] = useState(0)
  const [listTab, setListTab] = useState<number[]>([])
  const [loadingDownload, setLoadingDownload] = useState(false)
  const history = useHistory()
  const {id} = useParams<{id?: string}>()
  const initDataHealthReport = {
    agingContourImage: '',
    agingLobeScore: {
      Frontal_LH: 0,
      Frontal_RH: 0,
      Occipital_LH: 0,
      Occipital_RH: 0,
      Parietal_LH: 0,
      Parietal_RH: 0,
      Temporal_LH: 0,
      Temporal_RH: 0,
    },
    agingRoiScore: {},
    agingThreeDimensionImage: '',
    agingTotalScore: 0,
    analysisType: [0],
    brainConnectivityBetweenLobesImage: '',
    brainConnectivityBetweenLobesScore: null,
    brainConnectivityByLobeImage: '',
    brainConnectivityByLobeScore: null,
    brainConnectivityTotalImage: '',
    brainConnectivityTotalScore: 0,
    choice: 'N',
    createdAt: new Date().toISOString(),
    ecId: 0,
    eoId: 0,
    eoMeasuredTime: 0,
    ecMeasuredTime: 0,
    hrvMeasuredTime: 0,
    fiveFeaturesRadarImage: '',
    fiveFeaturesScore: {
      sadness: 0,
      tension: 0,
      stress: 0,
      dominant_frequency: {
        score: 0,
        value: 0,
      },
      inattention: {score: 0, value: 0},
    },
    hcId: 0,
    hrvAutoNervousSystemBalance: {parasympathetic: 0, sympathetic: 0},
    hrvCircularImage: '',
    hrvContourImage: '',
    hrvHistogramImage: '',
    hrvId: null,
    hrvTableImage: '',
    layoutType: 'C1',
    mciProbability: 0,
    mciResultImage: '',
    measureId: 0,
    patientBirth: '',
    patientFirstName: '',
    patientGender: '',
    patientHand: '',
    patientLastName: '',
    summaryType: 'S',
    hrvContourLevel: 1,
    hrvCircularLevel: 1,
    hrvHistogramLevel: 1,
    finalReportPath: null,
    treatmentAt: new Date().toISOString().split('T')[0],
  }
  const [dataHealthReport, setDataHealthReport] =
    useState<HealthReport>(initDataHealthReport)

  const TAB = [
    {index: 0, name: t('I5MajorIndicator')},
    {index: 1, name: t('IBrainFunctionBasic')},
    {index: 2, name: t('IBrainFunctionAdvance')},
    {index: 3, name: t('IBrainConnectivity')},
    {index: 4, name: t('IAIDementiaPrediction')},
    {index: 5, name: t('IHeartRate')},
  ]

  const textHeader = useMemo(() => {
    if (
      dataHealthReport.eoMeasuredTime === null &&
      dataHealthReport.ecMeasuredTime === null
    ) {
      return ''
    }

    return dataHealthReport.eoMeasuredTime === null &&
      dataHealthReport.ecMeasuredTime
      ? t('IContentHeadHealthReportV2', {
          ecMeasuredTime: parseFloat(
            (dataHealthReport.ecMeasuredTime / 60 ?? 0).toFixed(1),
          ),
        })
      : t('IContentHeadHealthReport', {
          ecMeasuredTime: parseFloat(
            (dataHealthReport.ecMeasuredTime / 60 ?? 0).toFixed(1),
          ),
          eoMeasuredTime: parseFloat(
            (dataHealthReport.eoMeasuredTime / 60 ?? 0).toFixed(1),
          ),
        })
  }, [dataHealthReport.eoMeasuredTime, dataHealthReport.ecMeasuredTime])

  const DATA_TITLE = [
    [t('I5MajorIndicator'), textHeader],
    [t('IBrainFunctionBasic'), textHeader],
    [t('IBrainFunctionAdvance'), textHeader],
    [t('IBrainConnectivity'), textHeader],
    [t('IAIDementiaPrediction'), textHeader],
    [t('IHeartRate'), ''],
  ]

  const LANG = [
    {
      label: t('IKoranLang'),
      value: 'ko',
    },
    {
      label: t('IEnglishLang'),
      value: 'en',
    },
    {
      label: t('IJapanesesLang'),
      value: 'ja',
    },
  ]

  const handleChangeLang = async (value: Locale) => {
    setCurrentLocale(value)
    await i18n.changeLanguage(value)
  }

  const getDataHealthReport = async () => {
    if (Number(id)) {
      try {
        const responseHealthReport = await getDataHealthReportApi(Number(id))
        const listProducts = await getHealthProductsApi()
        const typeProduct = listProducts.list.find(
          (product) => product.id === responseHealthReport.data.analysisType[0],
        )?.type
        if (typeProduct) {
          const listTab =
            LIST_HEALTH_PRODUCT_TAB.find(
              (product) => typeProduct === product.type,
            )?.tabs || []
          setListTab(listTab)
          if (listTab.length) setTabActive(listTab[0])
        }

        setDataHealthReport(responseHealthReport.data)
      } catch (error) {
        if (error.err.code === '404')
          history.push(RouteConstant.ERROR_NOT_FOUND.path)
      }
    }
  }

  const handleClickDownLoad = () => {
    setLoadingDownload(true)
    const pathPDF = dataHealthReport.finalReportPath
    if (pathPDF) {
      // Create a link element
      const link = document.createElement('a')
      const filename = pathPDF.split('/').pop()
      // Set the href attribute to the file URL
      link.href = `${pathPDF}?t=${Date.now()}`
      // Specify the download attribute with the desired file name
      link.download = filename || 'report.pdf'
      // Append the link to the document
      document.body.appendChild(link)
      // Trigger a click event on the link
      link.click()
      // Remove the link from the document
      document.body.removeChild(link)
    }
    setLoadingDownload(false)
  }

  useEffect(() => {
    setCurrentLocale(i18n.language as Locale)
  }, [i18n.language])

  useEffect(() => {
    getDataHealthReport()
  }, [])

  return (
    <div className={classes.root}>
      <div className={classes.topRoot}>
        <div className={`${classes.topNav} no-print`}>
          <div className={classes.topNavLeft}>
            <div className={classes.logo}>
              <img src={Logo} alt='Logo' />
            </div>
            <div className={classes.tabs}>
              {TAB.filter((item) => listTab.includes(item.index)).map((tab) => (
                <div
                  className={`${classes.tab} ${
                    tabActive === tab.index && classes.tabActive
                  }`}
                  onClick={() => setTabActive(tab.index)}
                  aria-hidden='true'
                  key={tab.index}
                >
                  {t(tab.name)}
                </div>
              ))}
            </div>
          </div>
          <div className={classes.topNavRight}>
            <div>
              <Select
                value={currentLocale}
                onChange={(e) => handleChangeLang(e.target.value as Locale)}
                className={classes.searchSelect}
                input={<OutlinedInput name='age' id='outlined-age-simple' />}
              >
                {LANG.map((item) => (
                  <MenuItem value={item.value} key={item.value}>
                    {t(item.label)}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <Button disabled={loadingDownload} onClick={handleClickDownLoad}>
              <img src={IconDownload} alt='IconDownload' />
            </Button>
          </div>
        </div>
        <div id='health-center' className='no-print'>
          <Header
            title={DATA_TITLE[tabActive][0]}
            content={tabActive === 5 ? '' : textHeader}
            name={t('IGetFullName', {
              firstName: dataHealthReport.patientFirstName ?? '',
              lastName: dataHealthReport.patientLastName ?? '',
            })}
            sex={t(
              toGenderString(dataHealthReport.patientGender as GenderValue),
            )}
            birthday={dataHealthReport.patientBirth}
            measurementDate={dataHealthReport.treatmentAt}
          />
          {tabActive === 0 &&
            (dataHealthReport.fiveFeaturesScore !== null ? (
              <HealthCenterMajorIndicators
                fiveFeaturesRadarImage={
                  dataHealthReport.fiveFeaturesRadarImage ?? ''
                }
                fiveFeaturesScore={dataHealthReport.fiveFeaturesScore}
              />
            ) : (
              <div className={classes.hrvError}>
                {t('IFiveBrainFunctionNull')}
              </div>
            ))}

          {tabActive === 1 &&
            (dataHealthReport.agingLobeScore !== null ? (
              <BrainFunctionBasic
                agingThreeDimensionImage={
                  dataHealthReport.agingThreeDimensionImage
                }
                agingContourImage={dataHealthReport.agingContourImage}
                agingLobeScore={dataHealthReport.agingLobeScore}
              />
            ) : (
              <div className={classes.hrvError}>
                {t('IBrainFunctionBasicMappingNull')}
              </div>
            ))}

          {tabActive === 2 &&
            (dataHealthReport.agingRoiScore !== null ? (
              <BrainFunctionMappingPage
                agingLobeScore={dataHealthReport.agingLobeScore}
                agingRoiScore={dataHealthReport.agingRoiScore}
              />
            ) : (
              <div className={classes.hrvError}>
                {t('IHealthReportTabNull', {tab: t('IBrainFunctionAdvance')})}
              </div>
            ))}

          {tabActive === 3 &&
            (dataHealthReport.brainConnectivityByLobeScore !== null ? (
              <BrainFunctionalConnectivity
                partOneImg={dataHealthReport.brainConnectivityTotalImage}
                partTwoImg={dataHealthReport.brainConnectivityBetweenLobesImage}
                partThreeImg={dataHealthReport.brainConnectivityByLobeImage}
                brainConnectivityTotalScore={
                  dataHealthReport.brainConnectivityTotalScore
                }
                brainConnectivityBetweenLobesScore={
                  dataHealthReport.brainConnectivityBetweenLobesScore
                }
                brainConnectivityByLobeScore={
                  dataHealthReport.brainConnectivityByLobeScore
                }
              />
            ) : (
              <div className={classes.hrvError}>
                {t('IHealthReportTabNull', {tab: t('IBrainConnectivity')})}
              </div>
            ))}

          {tabActive === 4 &&
            (dataHealthReport.mciProbability !== null ? (
              <AIDementiaPrediction
                mciResultImage={dataHealthReport.mciResultImage}
                mciProbability={dataHealthReport.mciProbability}
                name={t('IGetFullName', {
                  firstName: dataHealthReport.patientFirstName ?? '',
                  lastName: dataHealthReport.patientLastName ?? '',
                })}
              />
            ) : (
              <div className={classes.hrvError}>
                {t('IHealthReportTabNull', {tab: t('IAIDementiaPrediction')})}
              </div>
            ))}

          {tabActive === 5 &&
            (dataHealthReport.hrvId !== null &&
            dataHealthReport.hrvCircularImage !== null &&
            dataHealthReport.hrvTableImage !== null ? (
              <HealthCenterHeartRateAnalysisPage
                hrvTableImage={dataHealthReport.hrvTableImage}
                hrvCircularImage={dataHealthReport.hrvCircularImage}
                hrvAutoNervousSystemBalance={
                  dataHealthReport.hrvAutoNervousSystemBalance
                }
                hrvContourImage={dataHealthReport.hrvContourImage}
                hrvHistogramImage={dataHealthReport.hrvHistogramImage}
                hrvCircularLevel={dataHealthReport.hrvCircularLevel}
                hrvContourLevel={dataHealthReport.hrvContourLevel}
                hrvHistogramLevel={dataHealthReport.hrvHistogramLevel}
              />
            ) : (
              <div className={classes.hrvError}>{t('IHrvErrorText')}</div>
            ))}
        </div>
        <WrapPrintPage
          dataHealthReport={dataHealthReport}
          listTab={listTab}
          DATA_TITLE={DATA_TITLE}
        />
      </div>
    </div>
  )
}
export default HealthCenterReport
