import {useHistory} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import NoticeConfirmDialog from 'components/Dialog/NoticeConfirmDialog'
import React, {useEffect, useMemo} from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from 'components/Table/TableCell'
import {SaleAppNotice} from 'api/salesNoticeApi'
import {getNoticeDetailPath} from 'constants/RouteConstant'
import Pagination from 'components/molcules/Pagination'
import useStyles from 'components/Table/useTableStyles'
import useSaleAppNotices from 'features/sales/useSaleAppNotice'
import {makeDateAndTimeFormat} from 'helpers/dateHelper'
import {TYPE_STATUS_OPTIONS} from 'pages/SalesPages/SalesAppNoticePage/constants'

interface RenderTableRowProps {
  item: SaleAppNotice
  onClick: (item: SaleAppNotice) => void
}
function RenderTableRow({item, onClick}: RenderTableRowProps) {
  const {t, i18n} = useTranslation()
  const lang = i18n.language

  const getStatuses = useMemo(() => {
    return t(
      TYPE_STATUS_OPTIONS.find(
        (status) => status.value === item?.attribute?.status,
      )?.label ?? '-',
    )
  }, [item, lang])

  const getTitle = useMemo(() => {
    if (!item || !item.translations) return '-'
    return item.translations.find((tran) => tran.lang === lang)?.title ?? ''
  }, [item, lang])

  const getTypeNotices = useMemo(() => {
    switch (item.type) {
      case 'maintenance':
        return t('IMaintenanceNoticeType')
      case 'general':
        return t('IGeneralNoticeType')
      default:
        return '-'
    }
  }, [item, lang])

  const getPeriodTime = useMemo(() => {
    if (!item || !item.attribute) return '-'
    if (item.attribute.periodStartTime && item.attribute.periodEndTime) {
      const startAt = new Date(item.attribute.periodStartTime)
      const endAt = new Date(item.attribute.periodEndTime)
      return `${makeDateAndTimeFormat(startAt)} ~ ${makeDateAndTimeFormat(
        endAt,
      )}`
    }
    return '-'
  }, [item])

  const handleClick = () => onClick(item)

  return (
    <TableRow hover>
      <TableCell align='center'>{item.noticeId}</TableCell>
      <TableCell align='center'>{getTypeNotices}</TableCell>
      <TableCell
        align='center'
        onClick={handleClick}
        style={{
          cursor: 'pointer',
          maxWidth: 400,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {getTitle}
      </TableCell>
      <TableCell align='center'>{getPeriodTime}</TableCell>
      <TableCell align='center'>{getStatuses ?? '-'}</TableCell>
      <TableCell align='center'>
        {item.createdAt ? makeDateAndTimeFormat(new Date(item.createdAt)) : '-'}
      </TableCell>
    </TableRow>
  )
}

export default function TableNotice() {
  const {t} = useTranslation()
  const history = useHistory()
  const classes = useStyles()
  const {items, onSearchUser, pagingInfo, paging, loading, query} =
    useSaleAppNotices()

  React.useState<SaleAppNotice[]>()
  const noticeTableHead = [
    'No',
    'IDivision',
    'INotices',
    'IPeriod',
    'IStatus',
    'ICreateDate',
  ]
  const header = useMemo(() => noticeTableHead, [])
  const emptyRows = items === null ? 5 : 5 - items.length

  const handleClickRow = (item: SaleAppNotice) => {
    history.push(getNoticeDetailPath(item.noticeId))
  }

  const setPageIndex = (page: number) => {
    onSearchUser({
      paging: {
        page,
        size: paging.size,
      },
    })
  }

  const onSizeChange = (
    event: React.ChangeEvent<{name?: string; value: unknown}>,
  ) => {
    const size = parseInt(event.target.value as string, 10)
    onSearchUser({
      paging: {
        page: 0,
        size,
      },
    })
  }

  useEffect(() => {
    onSearchUser({paging})
  }, [])

  return (
    <>
      <NoticeConfirmDialog />
      <TableContainer className={classes.tableContainer}>
        <Table className={classes.table} size='small'>
          <TableHead>
            <TableRow className={classes.tableHeader}>
              {header.map((value) => (
                <TableCell align='center'>{t(`${value}`)}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {items &&
              items.map((item) => (
                <RenderTableRow
                  key={item.noticeId}
                  item={item}
                  onClick={handleClickRow}
                />
              ))}
            {emptyRows > 0 && (
              <TableRow
                style={{height: 43 * emptyRows, backgroundColor: '#F9F9FB'}}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
        <Pagination
          totalPageCount={pagingInfo.totalPages}
          currentPageIndex={query.paging.page ?? 0}
          itemCountPerPage={query.paging.size ?? 10}
          setCurrentPageIndex={setPageIndex}
          loading={loading}
          onItemCountPerPageChanged={onSizeChange}
        />
      </TableContainer>
    </>
  )
}
