import request, {fileRequest, newFileRequest, newNormalRequest} from 'api/index'
import {
  EmergencyDeleteAction,
  EmergencyEditAction,
} from 'features/sales/salesNoticeSlice'

export interface EmergencyNotifyRequest {
  isNotProceed: boolean
  begin?: string | null
  end?: string | null
  contents: string
  KO_attachments?: File | null
  EN_attachments?: File | null
}

export interface EmergencyNotifyContents {
  languageCode: string
  title: string
  content: string
  attachments?: File | null
  deleteImage?: boolean
}

export interface EmergencyNotifyItems {
  id: number
  order: number
  isNotProceed: boolean
  authorName: string
  begin?: string
  end?: string
  status?: string
  contents: EmergencyNotifyContents[]
}

export interface EmergencyNotifyResponse {
  items: EmergencyNotifyItems[]
  page: number
  size: number
  total: number
}

export interface EmergencyNotifyContentsFile {
  languageCode: string
  title: string
  content: string
  attachments?: string | null
  deleteImage?: boolean
}
export interface EmergencyNotifyItemsFile {
  id: number
  order: number
  isNotProceed: boolean
  authorName: string
  begin?: string
  end?: string
  contents: EmergencyNotifyContentsFile[]
}

export interface EmergencyNotifyResponseFileString {
  items: EmergencyNotifyItemsFile[]
  page: number
  size: number
  total: number
}

export interface EmergencyNotifyListRequest {
  page: number
  size: number
}

export interface EmergencyNotifyEditRequest {
  data: EmergencyNotifyRequest
  query: EmergencyEditAction
}

export interface SaleAppNoticeAttributes {
  periodEndTime: number
  periodStartTime: number
  status: 'proceed' | 'end' | 'wait'
  type: 'd' | 'zd'
}

export interface SaleAppNoticeLink {
  link: string
  name: string
  order: number
  type: 'file' | 'image' | 'web'
}

export interface SaleAppNoticeCommonContent {
  content: string
  lang: string
  title: string
}

export interface SaleAppNoticeDetailContent extends SaleAppNoticeCommonContent {
  noticeId: number
  translationId: number
}
export interface SaleAppNotice {
  attribute: SaleAppNoticeAttributes
  createdAt: number
  display: boolean
  links: SaleAppNoticeLink[]
  noticeId: number
  translations: SaleAppNoticeDetailContent[]
  type: 'general' | 'maintenance'
  updatedAt: number
  writerId: number
}

export interface SaleAppNoticeCreateRequest {
  attribute: SaleAppNoticeAttributes | null
  display: boolean
  links: SaleAppNoticeLink[] | null
  type: 'general' | 'maintenance'
  translations: SaleAppNoticeCommonContent[]
}

export const getSalesAppNoticeListApi = (query: PagingRequest) =>
  request<PageResponse<SaleAppNotice>>({
    method: 'get',
    path: '/admin/api/v1/notice',
    params: query.paging,
  })

export const getUserNoticeListApi = (query: PagingRequest) =>
  request<PageResponse<SaleAppNotice>>({
    method: 'get',
    path: '/api/v1/notice',
    params: query.paging,
  })

export const uploadFileSaleAppNoticeApi = (data: File) =>
  fileRequest<ModelResponse<string>>({
    method: 'post',
    path: `/admin/api/v1/notice/file`,
    data: {file: data},
  })

export const postSalesAppNoticeApi = (data: SaleAppNoticeCreateRequest) =>
  request<PageResponse<SaleAppNotice>>({
    method: 'post',
    path: '/admin/api/v1/notice',
    data,
  })

export const updateSalesAppNoticeApi = ({
  data,
  noticeId,
}: {
  data: SaleAppNoticeCreateRequest
  noticeId: number
}) =>
  request<PageResponse<SaleAppNotice>>({
    method: 'put',
    path: `/admin/api/v1/notice/${noticeId}`,
    data,
    params: {noticeId},
  })

export const getSalesAppNoticeDetailApi = (noticeId: number) =>
  request<ModelResponse<SaleAppNotice>>({
    method: 'get',
    path: `/admin/api/v1/notice/${noticeId}`,
    params: {noticeId},
  })

export const getUserNoticeDetailApi = (noticeId: number) =>
  request<ModelResponse<SaleAppNotice>>({
    method: 'get',
    path: `/api/v1/notice/${noticeId}`,
    params: {noticeId},
  })

export const deleteSalesAppNoticeApi = (noticeId: number) => {
  request<CommonResponse>({
    method: 'delete',
    path: `/admin/api/v1/notice/${noticeId}`,
  })
}
export const getLatestNoticeDetailApi = () =>
  request<ModelResponse<SaleAppNotice>>({
    method: 'get',
    path: `/api/v1/notice/latest/maintenance`,
  })

export const getSalesEmergencyApi = (query: EmergencyNotifyListRequest) =>
  newNormalRequest<EmergencyNotifyResponse>({
    method: 'get',
    path: '/emergency-notify',
    params: query,
  })

export const updateSalesEmergencyApi = (data: EmergencyNotifyRequest) => {
  return newFileRequest<any>({
    method: 'post',
    path: '/emergency-notify',
    data,
  })
}

export const deleteSalesEmergencyApi = (data: EmergencyDeleteAction) => {
  newNormalRequest<any>({
    method: 'delete',
    path: 'emergency-notify',
    data,
  })
}

export const editSalesEmergencyApi = (request: EmergencyNotifyEditRequest) => {
  newFileRequest<any>({
    method: 'post',
    path: `emergency-notify/${request.query.notifyId}/`,
    data: request.data,
  })
}
