import React from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import ActionButton from 'components/atoms/Buttons/ActionButton'
import CardWithTitle from 'components/atoms/CardWithTitle'
import TableNotice from 'components/Table/TableNotice'
import RouteConstant from 'constants/RouteConstant'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    navContainer: {
      marginBottom: 15,
      display: 'flex',
      flexDirection: 'row',
      gap: 5,
      flexWrap: 'wrap',
    },
    tableWrap: {
      backgroundColor: '#fff',
      borderRadius: 5,
      marginTop: 10,
      overflow: 'Hidden',
    },
    textField: {
      flexGrow: 1,
    },
    padding: {
      padding: theme.spacing(1),
    },
    smallPadding: {
      padding: theme.spacing(0.5),
    },
    dateContainer: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      flex: 1,
      '& h6': {
        margin: '0 10px',
      },
    },
    hover: {
      '&:hover': {
        backgroundColor: '#F8F8F8 !important',
      },
    },
    clientTitle: {
      fontWeight: 600,
      color: '#35495D',
      marginTop: 3,
    },

    pointRoot: {
      marginBottom: 30,
    },
    pointTitle: {
      width: '100%',
      fontSize: '12px',
      display: 'inline-flex',
    },
    pointCont: {
      width: '100%',
      display: 'inline-flex',
      fontWeight: 600,
      color: '#3EBBBD',
    },
    pointContainer: {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: 30,
      alignItems: 'center',
    },
    pointWrap: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    topContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginBottom: 14,
      marginTop: 10,
      gap: 7,
    },
  }),
)

export default function SalesAppNoticePage() {
  const history = useHistory()
  const {t} = useTranslation()
  const classes = useStyles()

  const handleNoticeAddClick = () => {
    history.push(RouteConstant.SALES_APP_NOTICE_CREATE.path)
  }

  return (
    <div>
      <CardWithTitle title={t('IAppNotice')}>
        <Grid className={classes.navContainer}>
          <Grid item xs={12}>
            <div className={classes.topContainer}>
              <ActionButton
                startIcon={<AddIcon />}
                variant='contained'
                size='medium'
                color='primary'
                disableElevation
                onClick={handleNoticeAddClick}
              >
                {t('IWriteNotice')}
              </ActionButton>
            </div>
            <Grid item xs={12}>
              <TableNotice />
            </Grid>
          </Grid>
        </Grid>
      </CardWithTitle>
    </div>
  )
}
