import {
  reject,
  close,
  open,
  selectNotification,
  fetchLatestNotice,
} from 'features/modal/notificationSlice'
import {useAppDispatch, useAppSelector} from 'hooks'

export default function useNoticeConfirm() {
  const dispatch = useAppDispatch()

  const onFetch = () => dispatch(fetchLatestNotice())

  const onFetchUnwrap = () => dispatch(fetchLatestNotice()).unwrap()

  const onOpen = async () => dispatch(open())

  const onClose = () => dispatch(close())

  const onReject = () => dispatch(reject())

  const {isOpened, data} = useAppSelector(selectNotification)

  return {
    open: isOpened,
    data,
    onOpen,
    onReject,
    onFetch,
    onClose,
    onFetchUnwrap,
  }
}
