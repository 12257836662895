import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {Button, Checkbox, Typography} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import * as storage from 'api/storageApi'
import useNotificationModal from 'features/modal/useNotificationModal'
import {makeDateAndTimeFormat} from 'helpers/dateHelper'
import NotificationStyle from 'components/molcules/SalesPages/Notification/Styles'

const useStyles = makeStyles(NotificationStyle)

interface NotificationRead {
  onClose?: () => void
}

export interface HtmlNoticesType {
  label: string
  type?: string
  value: string | string[]
  link?: string
}

export const HtmlNoticesElement = ({
  label,
  type,
  value,
  link,
}: HtmlNoticesType) => {
  const {t} = useTranslation()
  const classes = useStyles()

  if (!value || !value.length) {
    return null
  }

  if (!type)
    return (
      <div className={classes.noticeContentElement}>
        <Typography variant='h2' className={classes.noticeContentTitle}>
          {t(label)} :
        </Typography>
        <Typography variant='h6' className={classes.noticeContentNotice}>
          {value}
        </Typography>
      </div>
    )

  if (type === 'link')
    return (
      <div className={classes.noticeContentElement}>
        <Typography variant='h2' className={classes.noticeContentTitle}>
          {t(label)} :
        </Typography>
        <Typography variant='h6' className={classes.noticeContentNotice}>
          <a
            className={classes.link}
            href={link}
            target='_blank'
            rel='noopener noreferrer'
          >
            {value}
          </a>
        </Typography>
      </div>
    )

  if (type === 'date' && value.length)
    return (
      <div className={classes.noticeContentElement}>
        <Typography variant='h2' className={classes.noticeContentTitle}>
          {t(label)} :
        </Typography>
        <div>
          <Typography variant='h6' className={classes.noticeContentNotice}>
            {value[0]}
          </Typography>
          <Typography variant='h6' className={classes.noticeContentNotice}>
            {value[1]}
          </Typography>
        </div>
      </div>
    )

  return null
}

export default function NoticeReadPage(props: NotificationRead) {
  const {t, i18n} = useTranslation()
  const {data, onClose: onCloseModal} = useNotificationModal()
  const classes = useStyles()
  const lang = i18n.language
  const {onClose} = props
  const [checked, setChecked] = React.useState(
    !!storage.getOffShowNotification(),
  )

  const getTitle = useMemo(() => {
    if (!data || !data.translations) return ''
    return data.translations.find((item) => item.lang === lang)?.title ?? ''
  }, [data, lang])

  const getContents = useMemo(() => {
    if (!data || !data.translations) return ''
    return data.translations.find((item) => item.lang === lang)?.content ?? ''
  }, [data, lang])

  const getTimeStart = useMemo(
    () =>
      data && data.attribute?.periodStartTime
        ? makeDateAndTimeFormat(new Date(data.attribute.periodStartTime))
        : '',
    [data, lang],
  )

  const getTimeEnd = useMemo(
    () =>
      data && data.attribute?.periodEndTime
        ? makeDateAndTimeFormat(new Date(data.attribute.periodEndTime))
        : '',
    [data, lang],
  )

  const noticeContent: HtmlNoticesType[] = useMemo(
    () => [
      {label: 'ITitle', value: getTitle},
      {label: 'IContents', value: getContents},
      {
        label: 'IInspectionTime',
        value: [`Start: ${getTimeStart}`, `End: ${getTimeEnd}`],
        type: 'date',
      },
      {
        label: 'IAttachFile',
        value: data?.links[0]?.name,
        type: 'link',
        link: data?.links[0]?.link,
      } as HtmlNoticesType,
    ],
    [data, lang],
  )

  const handleChangeShowNotice = () => {
    setChecked(!checked)
  }

  return (
    <div className={classes.notificationContainer}>
      <p className={classes.noticeTitle}>{t('INoticeTitle')}</p>
      <hr className={classes.hr} />
      <div className={classes.noticeContainer}>
        <div className={classes.scrollableContent}>
          {noticeContent.map((item, index) => (
            <HtmlNoticesElement key={index} {...item} />
          ))}
        </div>
      </div>
      <div className={classes.alertButtonContainerNotSee}>
        {!onClose && !storage.getOffShowNotification() && (
          <div className={classes.isOffNotice}>
            <Checkbox
              checked={checked}
              onChange={handleChangeShowNotice}
              name='IOffNotification'
            />
            <span className={classes.noticeContentNotice}>
              {t('IOffNotification')}
            </span>
          </div>
        )}
        <Button
          variant='contained'
          color='primary'
          disableElevation
          onClick={() => {
            if (checked) storage.setOffShowNotification()
            else storage.removeOffShowNotification()
            return onClose ? onClose() : onCloseModal()
          }}
        >
          {t('IOk')}
        </Button>
      </div>
    </div>
  )
}
