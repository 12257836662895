import React from 'react'
import {Dialog} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import Card from 'components/atoms/Card'
import useNotificationModal from 'features/modal/useNotificationModal'
import NotificationDetail from 'components/molcules/SalesPages/Notification/NotificationDetail'
import {styles} from 'components/Dialog/SuccessDialog'

const useStyles = makeStyles(styles)

const SalesNoticeDetailDialog = () => {
  const classes = useStyles()
  const {open} = useNotificationModal()

  return (
    <Dialog open={open} className={classes.root} disableEscapeKeyDown>
      <Card className={classes.notificationRoot}>
        <NotificationDetail />
      </Card>
    </Dialog>
  )
}

export default SalesNoticeDetailDialog
