import React, {useEffect, useMemo, useRef, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {Controller, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import * as yup from 'yup'
import {SchemaOf} from 'yup'
import {useAppDispatch} from 'hooks'
import {yupResolver} from '@hookform/resolvers/yup'
import {makeStyles} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import {Button, FormControlLabel, Radio, RadioGroup} from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import GridContainer from 'components/Grid/GridContainer'
import DatePicker from 'components/Calender/DatePicker'
import ReorderIcon from '@material-ui/icons/Reorder'
import ActionButton from 'components/atoms/Buttons/ActionButton'
import {SalesAppGeneralNotice} from 'components/molcules/SalesPages/SalesAppNoticePage/CreateGeneralNotice'
import useNoticeConfirm from 'features/modal/useNoticeConfirm'
import {
  SaleAppNotice,
  SaleAppNoticeCreateRequest,
  SaleAppNoticeLink,
  uploadFileSaleAppNoticeApi,
} from 'api/salesNoticeApi'
import {
  createSaleAppNotice,
  deleteSaleAppNotice,
  updateSaleAppNotice,
} from 'features/sales/saleAppNoticeSlice'
import {
  combineDateAndTime,
  dateToDashString,
  toParsedDate,
} from 'helpers/dateHelper'
import RouteConstant from 'constants/RouteConstant'
import {useBoldInputStyle} from 'components/common/useBoldInputStyle'
import {styles} from 'components/molcules/SalesPages/SalesAppNoticePage/SaleAppNoticePageStyle'
import {getFileType} from 'helpers/fileHelper'
import {
  TYPE_NOTICE_OPTIONS,
  TYPE_STATUS_OPTIONS,
} from 'pages/SalesPages/SalesAppNoticePage/constants'

const useStyles = makeStyles(styles)

interface SalesAppMaintenanceNotice extends SalesAppGeneralNotice {
  type: 'zd' | 'd'
  dateStart: string
  timeStartHour: string
  timeStartMin: string
  dateEnd: string
  timeEndHour: string
  timeEndMin: string
  file: string
  status: 'proceed' | 'end' | 'wait'
}

interface CreateMaintenanceNoticeProps {
  initialData?: SaleAppNotice
  noticeId: number
}

export default function CreateMaintenanceNotice({
  initialData,
  noticeId,
}: CreateMaintenanceNoticeProps) {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const history = useHistory()
  const classes = useStyles()
  const inputClasses = useBoldInputStyle()
  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const [fileName, setFileName] = useState<string[] | null>(null)
  const [files, setFiles] = useState<FileList | null>(null)
  const [isChangeFile, setIsChangeFile] = useState<boolean>(true)
  const [disabled, setDisabled] = useState<boolean>(false)
  const {onOpen: onNoticeDialogOpen} = useNoticeConfirm()

  const SaleAppNoticeCreateSchema: SchemaOf<SalesAppMaintenanceNotice> = yup
    .object()
    .shape({
      type: yup.string().required(),
      koTitle: yup
        .string()
        .required(t('IRequiredTitle'))
        .max(150, t('IRequiredMaxlength', {max: 150})),
      koContent: yup
        .string()
        .required(t('IRequiredContent'))
        .max(3000, t('IRequiredMaxlength', {max: 3000})),
      enTitle: yup
        .string()
        .required(t('IRequiredTitle'))
        .max(150, t('IRequiredMaxlength', {max: 150})),
      enContent: yup
        .string()
        .required(t('IRequiredContent'))
        .max(3000, t('IRequiredMaxlength', {max: 3000})),
      dateStart: yup.date().required(),
      timeStartHour: yup.number().required().max(23),
      timeStartMin: yup.number().required().max(59),
      dateEnd: yup.date().required(),
      timeEndHour: yup.number().required().max(23),
      timeEndMin: yup.number().required().max(59),
    })
    .defined()
  const {
    control,
    handleSubmit,
    formState: {errors},
    setValue,
    watch,
  } = useForm<SalesAppMaintenanceNotice>({
    resolver: yupResolver(SaleAppNoticeCreateSchema),
  })
  const values = watch([
    'dateStart',
    'timeStartHour',
    'timeStartMin',
    'dateEnd',
    'timeEndHour',
    'timeEndMin',
  ])

  const errorDate = useMemo(() => {
    const invalidStartDate =
      errors.dateStart || errors.timeStartHour || errors.timeStartMin
    const invalidEndDate =
      errors.dateEnd || errors.timeEndHour || errors.timeEndMin

    const startTime = combineDateAndTime(
      new Date(values[0]),
      parseInt(values[1], 10),
      parseInt(values[2], 10),
    ).getTime()

    const endTime = combineDateAndTime(
      new Date(values[3]),
      parseInt(values[4], 10),
      parseInt(values[5], 10),
    ).getTime()

    if (invalidStartDate) {
      return t('IValidatePeriod')
    }
    if (invalidEndDate) {
      return t('IValidatePeriod')
    }
    if (startTime > endTime) {
      return t('IValidatePeriod')
    }
    return ''
  }, [values])

  const getFile = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {files} = event.target
    if (files && files.length > 0) {
      setFileName(Array.from(files).map((fileName) => fileName.name))
      setFiles(files)
      setIsChangeFile(true)
    }
  }

  const uploadFilesSequentially = async (
    fileList: FileList,
  ): Promise<SaleAppNoticeLink[]> => {
    const filesArray: File[] = Array.from(fileList)
    return Promise.all(
      filesArray.map((file: File, index: number) =>
        uploadFileSaleAppNoticeApi(file).then(
          (resFile) =>
            ({
              link: resFile?.data ?? '',
              name: file.name,
              order: index,
              type: getFileType(file),
            } as SaleAppNoticeLink),
        ),
      ),
    )
  }

  const onSubmit = async (data: SalesAppMaintenanceNotice) => {
    let links: SaleAppNoticeLink[] = []
    if (files && isChangeFile) {
      links = await uploadFilesSequentially(files)
    }
    const payload: SaleAppNoticeCreateRequest = {
      type: 'maintenance',
      display: false,
      attribute: {
        periodStartTime: combineDateAndTime(
          new Date(data.dateStart),
          parseInt(data.timeStartHour, 10),
          parseInt(data.timeStartMin, 10),
        ).getTime(),
        periodEndTime: combineDateAndTime(
          new Date(data.dateEnd),
          parseInt(data.timeEndHour, 10),
          parseInt(data.timeEndMin, 10),
        ).getTime(),
        status: data.status,
        type: data.type,
      },
      links: !isChangeFile ? initialData?.links ?? [] : links,
      translations: [
        {
          content: data.koContent,
          lang: 'ko',
          title: data.koTitle,
        },
        {
          content: data.enContent,
          lang: 'en',
          title: data.enTitle,
        },
      ],
    }
    if (noticeId) {
      const res = await dispatch(
        updateSaleAppNotice({data: payload, noticeId}),
      ).unwrap()
      if (res.success) history.push(RouteConstant.SALES_APP_NOTICE.path)
    } else {
      const res = await dispatch(createSaleAppNotice(payload)).unwrap()
      if (res.success) history.push(RouteConstant.SALES_APP_NOTICE.path)
    }
  }

  const handleDelete = () => {
    onNoticeDialogOpen({
      title: t('INoticeTitle'),
      message: t('IDeleteConfirmMessage'),
    }).then(async (result) => {
      if (result.payload) await dispatch(deleteSaleAppNotice(noticeId))
      history.push(RouteConstant.SALES_APP_NOTICE.path)
    })
  }

  useEffect(() => {
    if (initialData) {
      const {attribute, translations, links} = initialData
      const startTime = toParsedDate(new Date(attribute.periodStartTime))
      const endTime = toParsedDate(new Date(attribute.periodEndTime))

      setValue('type', attribute.type)
      setValue('status', attribute.status)
      setValue('koTitle', translations[0].title)
      setValue('koContent', translations[0].content)
      setValue('enTitle', translations[1].title)
      setValue('enContent', translations[1].content)
      setValue('dateStart', new Date(attribute.periodStartTime).toDateString())
      setValue('timeStartHour', startTime.hours.toString())
      setValue('timeStartMin', startTime.minutes.toString())
      setValue('dateEnd', new Date(attribute.periodEndTime).toDateString())
      setValue('timeEndHour', endTime.hours.toString())
      setValue('timeEndMin', endTime.minutes.toString())
      setFileName(links.map((item) => item.name))
      if (links.length) {
        setIsChangeFile(false)
      }
      setDisabled(true)
    }
  }, [initialData])

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.contentsTab}>
          <GridContainer
            item
            className={`${classes.gridContainer} ${classes.marginTop10}`}
          >
            <Grid item xs={2} className={classes.titleItem}>
              {t('IDivision')} :
            </Grid>
            <Grid item lg={8} xs={9}>
              <Controller
                name='type'
                control={control}
                defaultValue='zd'
                render={({field}) => (
                  <RadioGroup
                    row
                    className={`${classes.radioContainer} subContent`}
                    {...field}
                  >
                    {TYPE_NOTICE_OPTIONS.map((type) => (
                      <FormControlLabel
                        control={<Radio color='primary' />}
                        disabled={disabled}
                        label={
                          <p className={classes.marginRight30}>
                            {t(type.label)}
                          </p>
                        }
                        value={type.value}
                      />
                    ))}
                  </RadioGroup>
                )}
              />
            </Grid>
          </GridContainer>

          <GridContainer
            item
            className={`${classes.gridContainer} ${classes.marginTop10}`}
          >
            <Grid item xs={2} className={classes.titleItem}>
              {t('ITitleKo')} :
            </Grid>
            <Grid item lg={8} xs={9}>
              <Controller
                name='koTitle'
                control={control}
                render={({field}) => (
                  <input
                    className={inputClasses.input}
                    disabled={disabled}
                    {...field}
                    style={{width: '100%'}}
                  />
                )}
              />
            </Grid>
          </GridContainer>

          <GridContainer item className={classes.gridContainer}>
            <Grid item xs={2} />
            <Grid item lg={8} xs={9}>
              <p className={classes.errorText}>
                {errors.koTitle && errors.koTitle?.message}
              </p>
            </Grid>
          </GridContainer>
          <GridContainer
            item
            className={`${classes.gridContainer} ${classes.marginTop10}`}
          >
            <Grid item xs={2} className={classes.titleItem}>
              {t('IContentKo')} :
            </Grid>
            <Grid item lg={8} xs={9}>
              <Controller
                name='koContent'
                control={control}
                render={({field}) => (
                  <textarea
                    disabled={disabled}
                    {...field}
                    rows={10}
                    className={inputClasses.textarea}
                  />
                )}
              />
            </Grid>
          </GridContainer>
          <GridContainer item className={classes.gridContainer}>
            <Grid item xs={2} />
            <Grid item lg={8} xs={9}>
              <p className={classes.errorText}>
                {errors.koContent && errors.koContent?.message}
              </p>
            </Grid>
          </GridContainer>

          <GridContainer
            item
            className={`${classes.gridContainer} ${classes.marginTop10}`}
          >
            <Grid item xs={2} className={classes.titleItem}>
              {t('ITitleEn')} :
            </Grid>
            <Grid item lg={8} xs={9}>
              <Controller
                name='enTitle'
                control={control}
                render={({field}) => (
                  <input
                    disabled={disabled}
                    className={inputClasses.input}
                    {...field}
                    style={{width: '100%'}}
                  />
                )}
              />
            </Grid>
          </GridContainer>
          <GridContainer item className={classes.gridContainer}>
            <Grid item xs={2} />
            <Grid item lg={8} xs={9}>
              <p className={classes.errorText}>
                {errors.enTitle && errors.enTitle?.message}
              </p>
            </Grid>
          </GridContainer>

          <GridContainer
            item
            className={`${classes.gridContainer} ${classes.marginTop10}`}
          >
            <Grid item xs={2} className={classes.titleItem}>
              {t('IContentEn')} :
            </Grid>
            <Grid item lg={8} xs={9}>
              <Controller
                name='enContent'
                control={control}
                render={({field}) => (
                  <textarea
                    disabled={disabled}
                    {...field}
                    rows={10}
                    className={inputClasses.textarea}
                  />
                )}
              />
            </Grid>
          </GridContainer>
          <GridContainer item className={classes.gridContainer}>
            <Grid item xs={2} />
            <Grid item lg={8} xs={9}>
              <p className={classes.errorText}>
                {errors.enContent && errors.enContent?.message}
              </p>
            </Grid>
          </GridContainer>

          <GridContainer
            item
            className={`${classes.gridContainer} ${classes.marginTop10}`}
          >
            <Grid item xs={2} className={classes.titleItem}>
              {t('IPeriod')} :
            </Grid>
            <GridContainer xs={9} className={classes.pickDateContainer}>
              <Grid
                item
                xl={4}
                lg={5}
                md={10}
                xs={12}
                className={classes.pickDate}
              >
                <Controller
                  name='dateStart'
                  control={control}
                  render={({field}) => (
                    <DatePicker
                      disabled={disabled}
                      onChangeValue={(e) => {
                        setValue('dateStart', dateToDashString(e ?? new Date()))
                      }}
                      dateValue={new Date(field.value ?? '')}
                      minDate={new Date()}
                    />
                  )}
                />
                <Controller
                  name='timeStartHour'
                  control={control}
                  defaultValue='0'
                  render={({field}) => (
                    <input
                      disabled={disabled}
                      className={classes.inputTime}
                      type='number'
                      min={0}
                      max={23}
                      {...field}
                      onInput={(e) => {
                        if (Number(e.currentTarget.value) > 23) {
                          e.currentTarget.value = ''
                        }
                      }}
                    />
                  )}
                />
                :
                <Controller
                  name='timeStartMin'
                  control={control}
                  defaultValue='0'
                  render={({field}) => (
                    <input
                      disabled={disabled}
                      className={classes.inputTime}
                      type='number'
                      min={0}
                      max={59}
                      {...field}
                      onInput={(e) => {
                        if (Number(e.currentTarget.value) > 59) {
                          e.currentTarget.value = ''
                        }
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                xl={4}
                lg={5}
                md={10}
                xs={12}
                className={classes.pickDate}
              >
                <Controller
                  name='dateEnd'
                  control={control}
                  render={({field}) => (
                    <DatePicker
                      disabled={disabled}
                      onChangeValue={(e) =>
                        setValue('dateEnd', dateToDashString(e ?? new Date()))
                      }
                      dateValue={new Date(field.value ?? '')}
                      minDate={new Date(values[0])}
                    />
                  )}
                />
                <Controller
                  name='timeEndHour'
                  control={control}
                  defaultValue='0'
                  render={({field}) => (
                    <input
                      disabled={disabled}
                      className={classes.inputTime}
                      type='number'
                      min={0}
                      max={23}
                      {...field}
                      onInput={(e) => {
                        if (Number(e.currentTarget.value) > 23) {
                          e.currentTarget.value = ''
                        }
                      }}
                    />
                  )}
                />
                <span>:</span>
                <Controller
                  name='timeEndMin'
                  control={control}
                  defaultValue='0'
                  render={({field}) => (
                    <input
                      disabled={disabled}
                      className={classes.inputTime}
                      type='number'
                      min={0}
                      max={59}
                      {...field}
                      onInput={(e) => {
                        if (Number(e.currentTarget.value) > 59) {
                          e.currentTarget.value = ''
                        }
                      }}
                    />
                  )}
                />
              </Grid>
            </GridContainer>
          </GridContainer>

          <GridContainer item className={classes.gridContainer}>
            <Grid item xs={2} />
            <Grid item lg={8} xs={9}>
              <p className={classes.errorText}>{errorDate}</p>
            </Grid>
          </GridContainer>

          <GridContainer
            item
            className={`${classes.gridContainer} ${classes.marginTop10}`}
          >
            <Grid item xs={2} className={classes.titleItem}>
              {t('IAppNoticeAttachedFileButton')} :
            </Grid>
            <Grid item lg={8} xs={9}>
              <Button
                disabled={disabled}
                variant='contained'
                disableElevation
                color='default'
                size='small'
                className={classes.minWidth120}
                onClick={getFile}
              >
                {t('IAttachFile')}
              </Button>
              {fileName && <span className={classes.fileName}>{fileName}</span>}
              <input
                disabled={disabled}
                type='file'
                ref={fileInputRef}
                onChange={handleFileChange}
                style={{display: 'none'}}
                accept='.png, .jpg, .jpeg, .pdf'
              />
            </Grid>
          </GridContainer>
          <GridContainer
            item
            className={`${classes.gridContainer} ${classes.marginTop10}`}
          >
            <Grid item xs={2} className={classes.titleItem}>
              {t('IStatus')} :
            </Grid>
            <Grid item lg={8} xs={9}>
              <Controller
                name='status'
                control={control}
                defaultValue='wait'
                render={({field}) => (
                  <RadioGroup
                    row
                    className={`${classes.radioContainer} subContent`}
                    {...field}
                  >
                    {TYPE_STATUS_OPTIONS.map((type) => (
                      <FormControlLabel
                        control={<Radio color='primary' />}
                        disabled={disabled}
                        label={
                          <p className={classes.marginRight30}>
                            {t(type.label)}
                          </p>
                        }
                        value={type.value}
                      />
                    ))}
                  </RadioGroup>
                )}
              />
            </Grid>
          </GridContainer>
        </div>

        <div className={classes.botContainer}>
          <Grid item xs={9}>
            <div className={classes.buttonContainer}>
              <ActionButton
                startIcon={<ReorderIcon />}
                color='secondary'
                onClick={() =>
                  history.push(RouteConstant.SALES_APP_NOTICE.path)
                }
              >
                {t('IReadList')}
              </ActionButton>
              {!disabled ? (
                <div className={classes.buttonWrap}>
                  <ActionButton
                    color='default'
                    className={classes.backButton}
                    onClick={() =>
                      history.push(RouteConstant.SALES_APP_NOTICE.path)
                    }
                  >
                    {t('ICancel')}
                  </ActionButton>
                  <Typography className={classes.padding} />
                  <ActionButton
                    color='primary'
                    className={classes.backButton}
                    type='submit'
                  >
                    {t('IOk')}
                  </ActionButton>
                </div>
              ) : (
                <div className={classes.buttonWrap}>
                  <Typography className={classes.padding} />
                  <ActionButton
                    color='primary'
                    className={classes.backButton}
                    onClick={() => setDisabled(false)}
                  >
                    {t('IEdit')}
                  </ActionButton>
                  <Typography className={classes.padding} />
                  <ActionButton
                    color='default'
                    className={classes.backButton}
                    onClick={handleDelete}
                  >
                    {t('IDelete')}
                  </ActionButton>
                </div>
              )}
            </div>
          </Grid>
        </div>
      </form>
    </div>
  )
}
