import Bookmark from '@material-ui/icons/Bookmark'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import Domain from '@material-ui/icons/Domain'
import PhoneIcon from '@material-ui/icons/Phone'
import CreditCardIcon from '@material-ui/icons/CreditCard'
import SettingsIcon from '@material-ui/icons/Settings'
import styled from 'styled-components'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {Colors} from 'components/common/useBaseStyle'

const FooterWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  overflow: hidden;
  padding: 15px 20px;
  font-size: 12px;
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 0px;
  height: 8vh;
  min-height: 8vh;
  color: ${Colors.text.title};
  background: none repeat scroll 0 0 white;
  border-top: 1px solid #e7eaec;
  z-index: 3;
  @media (max-width: 1199px) {
    flex-direction: column;
    height: 10vh;
  }
  @media (max-width: 768px) {
    position: unset;
    margin: 0 -15px;
    height: 10vh;
  }
`

const RightContent = styled.div`
  min-width: 163px;
  margin: 5px @media (max-width: 1199px) {
    position: unset;
    margin: 0 0 10px;
    & a:first-child {
      padding: 0 10px 0 0;
    }
  }
  & a {
    background-color: transparent;
    color: ${Colors.text.title};
    position: relative;
    padding: 0 10px;
    cursor: pointer;
    user-select: none;
    z-index: 3;
  }
  & a:first-child {
    padding: 0 10px 0 0;
  }
  & a:first-child::after {
    content: '';
    position: absolute;
    right: 0px;
    top: 0px;
    width: 1px;
    height: 100%;
    background-color: #dbdbdb;
  }
  & a:first-child::after {
    content: '';
    position: absolute;
    right: 0px;
    top: 0px;
    width: 1px;
    height: 100%;
    background-color: #dbdbdb;
  }
`
const LeftContent = styled.div`
  width: 100%;
`

const LeftContentListWrapper = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`

const LeftContentList = styled.li`
  color: ${Colors.text.title};
  display: inline-block;
  margin-right: 10px;
  vertical-align: middle;
  & .MuiSvgIcon-root {
    font-size: 17px;
    vertical-align: bottom;
    padding-right: 5px;
  }
  & .Copyright {
    display: block;
    margin: 5px 0 0;
    text-align: left;
  }
`

const Copyright = styled.li`
  color: ${Colors.text.title};
  margin: 12px 0 0;
  display: block;
  font-size: 12px;
  line-height: 1.36px;
  text-align: left;
  vertical-align: middle;
`

export default function LoginPageFooter() {
  const {t} = useTranslation()

  return (
    <FooterWrapper>
      <RightContent>
        {/* <a href='#terms'>{t('ITerms')}</a>
        <a href='#privacy'>{t('IPrivacyPolicy')}</a> */}
      </RightContent>
      <LeftContent>
        <LeftContentListWrapper>
          <LeftContentList>
            <Bookmark />
            {t('IAppCompanyNameInc')}
          </LeftContentList>
          <LeftContentList>
            <Domain />
            {t('IAppCompanyAddress')}
          </LeftContentList>
          <LeftContentList>
            <CreditCardIcon />
            {t('IAppCompanyBusinessNo')}
          </LeftContentList>
          <LeftContentList>
            <AccountCircleIcon />
            {t('IAppCompanyCeoName')}
          </LeftContentList>

          <LeftContentList>
            <PhoneIcon />
            Contact: +82-2-747-7422 / isyncbrain@imedisyn.com
          </LeftContentList>
          <LeftContentList>
            <SettingsIcon />
            Customer Service: +82-1533-4080 / cs@imedisync.com
          </LeftContentList>
          <Copyright>{t('ICopyright')}</Copyright>
        </LeftContentListWrapper>
      </LeftContent>
    </FooterWrapper>
  )
}
