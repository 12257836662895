import {createStyles} from '@material-ui/core/styles'

const NotificationStyle = () =>
  createStyles({
    root: {
      // @ts-ignore
      zIndex: '1400 !important',
    },
    notificationContainer: {
      overflowY: 'auto',
      height: '100%',
      width: '100%',
    },
    noticeTitle: {
      fontSize: 20,
      fontWeight: 'bold',
      color: '#5a6268',
      padding: '20px 50px 10px',
    },
    noticeContainer: {
      background: 'white',
      borderRadius: 5,
      maxHeight: 350,
      height: '100%',
      overflow: 'auto',
      padding: '0px 20px 20px 5px',
      width: '100%',
      '& h2': {
        fontWeight: 'bold',
        color: '#5a6268',
        fontSize: 15,
        margin: 5,
      },

      '& h6': {
        fontSize: 15,
        fontWeight: 'normal',
        textAlign: 'start',
        margin: '5px 0',
        color: '#717376',
      },
    },

    alertButtonContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '13px 20px',
      justifyContent: 'center',
      background: '#f4f4f4',
      '& button': {
        width: '20%',
        color: 'white',
      },
      flexWrap: 'nowrap',
      gap: 10,
    },
    alertButtonContainerNotSee: {
      display: 'flex',
      justifyContent: 'center',
      margin: '10px 20px 20px',
      position: 'relative',
    },
    isOffNotice: {
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      left: 0,
      '& span': {
        color: 'black',
        fontSize: 14,
      },
    },
    scrollableContent: {
      paddingRight: 10,
      width: '100%',
    },
    noticeContentNotice: {
      whiteSpace: 'pre-line',
      lineHeight: 1.2,
    },
    noticeContentTitle: {
      width: 80,
      minWidth: 80,
      fontWeight: 'bold',
      textAlign: 'right',
    },
    hr: {
      margin: '0 40px 10px 40px',
    },
    link: {
      color: '#1890ff',
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    noticeContentElement: {
      display: 'flex',
      gap: 10,
    },
  })

export default NotificationStyle
